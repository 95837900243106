import classNames from "classnames/bind";

import styles from "./SwiperCard.module.scss";
import { RichTextDisplay } from "../RichText";

const cx = classNames.bind(styles);

export interface Attribute {
  customText?: string;
  imageUrl?: string;
  name: string;
}

interface SwiperCardProps {
  children?: React.ReactNode;
  className?: string;
  customText?: string | null;
  imageUrl?: string;
  isFlipped?: boolean;
  name: string;
  showImageAndText?: boolean;
}

export const SwiperCard = ({
  name,
  customText,
  isFlipped = false,
  showImageAndText,
  imageUrl,
  className,
  children,
}: SwiperCardProps) => {
  const hasImage = Boolean(imageUrl);
  const attributeText = customText || name;

  const renderContent = () => {
    const imageSection = <img className={styles.image} src={imageUrl} alt={name} />;
    const textSection = <RichTextDisplay value={attributeText} className={styles.text} />;

    return (
      <>
        {(!hasImage || showImageAndText) && !isFlipped && textSection}
        {hasImage && imageSection}
      </>
    );
  };

  return (
    <div className={cx("card", className)}>
      {renderContent()}
      {children}
    </div>
  );
};
