import { HTMLAttributes, useEffect, useRef } from "react";
import useResizeObserver from "use-resize-observer";
import classNames from "classnames/bind";

import styles from "./TextTruncate.module.scss";

const cx = classNames.bind(styles);

export interface TextTruncateProps extends Omit<HTMLAttributes<HTMLSpanElement>, "children"> {
  text: string;
}

export const TextTruncate = ({ text: originalText, className, ...props }: TextTruncateProps) => {
  const ref = useRef<HTMLSpanElement>(null);
  const { ref: resizeRef, height: textHeight } = useResizeObserver<HTMLSpanElement>();

  useEffect(() => {
    if (ref.current) {
      const el = ref.current;
      const textEl = el.childNodes[0] as HTMLSpanElement;

      if (el.offsetHeight < textEl.offsetHeight) {
        // Remove most of the text before we iterate over it
        const lineHeight = parseInt(window.getComputedStyle(el).lineHeight, 10);
        const lines = Math.ceil(el.offsetHeight / lineHeight);
        const textLines = Math.ceil(textEl.offsetHeight / lineHeight);
        const percentage = Math.min((lines + 1) / textLines, 1);
        textEl.innerText = textEl.innerText?.slice(0, Math.floor(textEl.innerText.length * percentage));

        // Remove characters until the text fits
        while (el.offsetHeight < textEl.offsetHeight) {
          textEl.innerText = textEl.innerText?.slice(0, -3);
        }
        textEl.innerText = textEl.innerText?.slice(0, -3) + "...";
      }
    }
  }, [ref.current, textHeight, originalText]);

  return (
    <span ref={ref} className={cx("text", className)} {...props}>
      <span ref={resizeRef} className={styles.innerText}>
        {originalText}
      </span>
    </span>
  );
};
