import common from "./common.json";
import account from "./account.json";
import user from "./user.json";
import project from "./project.json";
import subscription from "./subscription.json";
import projects from "./projects.json";
import navigation from "./navigation.json";
import report from "./report.json";
import rankifier from "./rankifier.json";
import quickrank from "./quickrank.json";
import folders from "./folders.json";
import company from "./company.json";
import profile from "./profile.json";
import wordopolis from "./wordopolis.json";
import library from "./library.json";
import leaderboard from "./leaderboard.json";
import trivia from "./trivia.json";
import wordle from "./wordle.json";

export default {
  common,
  account,
  user,
  project,
  subscription,
  projects,
  navigation,
  report,
  rankifier,
  quickrank,
  folders,
  company,
  profile,
  wordopolis,
  library,
  leaderboard,
  trivia,
  wordle,
};
