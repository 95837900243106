import classNames from "classnames/bind";
import { FC, HTMLAttributes, PropsWithChildren } from "react";

import { CheckIcon } from "../../assets/icons";

import styles from "./CheckmarkInput.module.scss";

const cx = classNames.bind(styles);

export interface CheckmarkInputProps extends HTMLAttributes<HTMLInputElement> {
  id: string;
  value?: string | boolean;
  label?: string;
  className?: string;
  customColor?: string;
  name?: string;
  unchangable?: boolean;
  disabled?: boolean;
  checked?: boolean;
  type?: "radio" | "checkbox";
}

export const CheckmarkInput: FC<CheckmarkInputProps> = ({
  id,
  name,
  value,
  label,
  unchangable = false,
  disabled = false,
  customColor,
  className,
  type = "radio",
  ...props
}) => {
  const isActive = value && unchangable;

  return (
    <div className={cx("checkmarkInput", className, { disabled, unchangable })}>
      {!unchangable && (
        <input
          id={id}
          disabled={unchangable || disabled}
          className={styles.input}
          type={type}
          name={name}
          value={typeof value === "string" ? value : undefined}
          {...props}
        />
      )}
      <span
        style={isActive ? { backgroundColor: customColor, borderColor: customColor } : undefined}
        className={cx("checkmark", { disabled, isActive })}
      >
        <CheckIcon className={styles.checkmarkIcon} />
      </span>
      {label && (
        <label className={styles.label} htmlFor={id}>
          {label}
        </label>
      )}
    </div>
  );
};
