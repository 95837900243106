import classNames from "classnames/bind";

import { StarIcon } from "../../../../assets/icons";

import styles from "../Table.module.scss";

const cx = classNames.bind(styles);

export interface LeaderboardRow {
  rank: number;
  nickname: string;
  timeInMs: number;
  points: number;
  id?: string;
}

export interface RowProps {
  record: LeaderboardRow;
  className?: string;
  showTimer: boolean;
  themeColor?: string;
  isActive: boolean;
}

const placeIcon = [
  <StarIcon key={0} className={cx("star", "first")} />,
  <StarIcon key={1} className={cx("star", "second")} />,
  <StarIcon key={2} className={cx("star", "third")} />,
];

export const Row = ({ record, themeColor, isActive, showTimer, className }: RowProps) => {
  const formatTime = (time: number) => {
    // time is in ms
    // Return time in MM:SS:MS format
    const minutes = Math.floor(time / 60000);
    const seconds = Math.floor((time - minutes * 60000) / 1000);
    const milliseconds = time - minutes * 60000 - seconds * 1000;

    const minutesString = minutes.toString().padStart(2, "0");
    const secondsString = seconds.toString().padStart(2, "0");
    const millisecondsString = milliseconds.toString().slice(0, 2).padStart(2, "0");

    return `${minutesString}:${secondsString}:${millisecondsString}`;
  };

  return (
    <div style={{ borderColor: isActive ? themeColor : undefined }} className={cx("row", className)}>
      <div className={styles.rank}>{record.rank}</div>
      <div className={styles.username}>
        <span style={{ backgroundColor: themeColor }} className={styles.avatar}>
          {record.nickname[0]}
        </span>
        {record.nickname}
        {placeIcon[record.rank - 1]}
      </div>
      <div className={cx("time", { hideTime: !showTimer })}>{formatTime(record.timeInMs)}</div>
      <div className={styles.points}>{record.points}</div>
    </div>
  );
};
