import { CSSProperties, HTMLProps, ReactElement, ReactNode, useMemo } from "react";
import classNames from "classnames/bind";

import { IconWithCustomColor } from "../IconWithCustomColor";

import styles from "./Button.module.scss";

const cx = classNames.bind(styles);

export interface ButtonProps extends Omit<HTMLProps<HTMLButtonElement>, "size"> {
  children: ReactNode;
  type?: "button" | "submit";
  color?: "orange" | "red" | "black" | "purple";
  customColor?: string;
  size?: "small" | "medium" | "large";
  kind?: "primary" | "outline" | "minimal" | "text";
  icon?: ReactElement;
  iconSide?: "left" | "right";
}
export const Button = ({
  children,
  color = "orange",
  type = "button",
  size = "medium",
  kind = "primary",
  iconSide = "right",
  customColor,
  icon,
  className,
  style,
  ...restProps
}: ButtonProps) => {
  const buttonClassName = cx("wrapper", className, color, size, kind, { iconLeft: icon && iconSide === "left" });

  const {
    buttonStyle,
    iconStyle,
  }: {
    buttonStyle?: CSSProperties;
    iconStyle?: CSSProperties;
  } = useMemo(() => {
    if (kind === "primary") {
      return {
        buttonStyle: {
          background: customColor,
          ...style,
        },
      };
    }
    if (kind === "outline") {
      return {
        buttonStyle: {
          borderColor: customColor,
          color: customColor,
          ...style,
        },
        iconStyle: {
          fill: customColor,
        },
      };
    }
    if (kind === "text") {
      return {
        buttonStyle: {
          color: customColor,
          ...style,
        },
        iconStyle: {
          fill: customColor,
        },
      };
    }

    return {};
  }, [kind, color, customColor]);

  return (
    <button type={type} style={buttonStyle} {...restProps} className={buttonClassName}>
      <span>{children}</span>{" "}
      {icon && <IconWithCustomColor icon={icon} className={styles.icon} innerFill={iconStyle?.fill} />}
    </button>
  );
};
