import { CSSProperties, PropsWithChildren } from "react";
import classNames from "classnames/bind";

import styles from "./Paragraph.module.scss";

const cx = classNames.bind(styles);

export type ParagraphProps = PropsWithChildren<{
  className?: string;
  weight?: "font-regular" | "font-bold";
  color?: "primary" | "secondary" | "background" | "neutrals-600";
  size?: "regular" | "large" | "small" | "xs";
  style?: CSSProperties;
}>;

export const Paragraph = ({
  children,
  className,
  weight = "font-regular",
  color = "primary",
  size = "regular",
  style,
}: ParagraphProps) => {
  const paragraphClassName = cx("paragraph", weight, color, size, className);

  return (
    <div className={paragraphClassName} style={style}>
      {children}
    </div>
  );
};
