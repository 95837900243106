import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import TextStyle from "@tiptap/extension-text-style";
import { BubbleMenu, EditorContent, useEditor } from "@tiptap/react";
import { Color } from "@tiptap/extension-color";
import Document from "@tiptap/extension-document";
import { type ReactElement, useEffect, useState } from "react";
import FontSize from "tiptap-extension-font-size";
import Bold from "@tiptap/extension-bold";
import Italic from "@tiptap/extension-italic";
import Placeholder from "@tiptap/extension-placeholder";
import Superscript from "@tiptap/extension-superscript";
import classNames from "classnames/bind";
import { TrashCanIcon } from "../../assets/icons";
import { IconWithCustomColor } from "../IconWithCustomColor";

import styles from "./RichText.module.scss";

type RichTextProps = {
  value?: string;
  onChange: (content: string) => void;
  onBlur?: () => void;
  icon?: ReactElement;
  placeholder?: string;
  className?: string; // compatibility with old api/not used
  size?: string; // compatibility with old api/not used
  error?: boolean; // compatibility with old api/not used
  id?: string;
};

const cx = classNames.bind(styles);

const parseFontSize = (fontSize?: string) => {
  if (!fontSize) return 16;

  return parseInt(fontSize.replace("px", ""));
};

export const RichText = ({ value, onChange, icon, onBlur = () => {}, id, placeholder }: RichTextProps) => {
  const [hasValue, setHasValue] = useState(!!value);

  // workaround for flickering editor when value is empty and is updated later on from the outside
  useEffect(() => {
    setHasValue(!!value);
  }, [value, id]);

  const editor = useEditor(
    {
      extensions: [
        Document,
        Paragraph,
        Text,
        TextStyle,
        Color,
        FontSize,
        Bold,
        Italic,
        Superscript,
        Placeholder.configure({
          placeholder,
        }),
      ],
      content: value,
      onUpdate: ({ editor }) => {
        onChange(editor.getHTML());
      },
      onBlur,
    },
    [hasValue],
  );

  if (!editor) {
    return null;
  }

  const handleFocus = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    editor.commands.focus();
  };

  const onColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    editor.chain().focus().setColor(event.target.value).run();
  };

  const increaseFontSize = () => {
    const current = parseFontSize(editor.getAttributes("textStyle").fontSize);
    editor
      .chain()
      .focus()
      .setFontSize(`${current + 1}px`)
      .run();
  };

  const decreaseFontSize = () => {
    const current = parseFontSize(editor.getAttributes("textStyle").fontSize);
    const newValue = current - 1 < 1 ? 1 : current - 1;

    editor.chain().focus().setFontSize(`${newValue}px`).run();
  };

  const reset = () => {
    editor.chain().focus().clearNodes().unsetAllMarks().run();
  };

  const trapFocus = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  return (
    <div style={{ width: "100%" }}>
      <BubbleMenu
        editor={editor}
        tippyOptions={{
          duration: 100,
          placement: "top-start",
        }}
      >
        <div className={styles.bubbleMenu}>
          <input
            onMouseDown={trapFocus}
            className={cx("menuButton")}
            type="color"
            onInput={onColorChange}
            value={editor.getAttributes("textStyle").color}
          />

          <button
            onMouseDown={trapFocus}
            onClick={() => editor.chain().focus().toggleBold().run()}
            className={cx("menuButton", "bold")}
          >
            B
          </button>

          <button
            onMouseDown={trapFocus}
            onClick={() => editor.chain().focus().toggleItalic().run()}
            className={cx("menuButton", "italic")}
          >
            I
          </button>

          <button onMouseDown={trapFocus} className={cx("menuButton", "bold")} onClick={decreaseFontSize}>
            -
          </button>
          <input
            onMouseDown={trapFocus}
            type="text"
            className={cx("menuButton", "sizePicker")}
            disabled
            value={parseFontSize(editor.getAttributes("textStyle").fontSize)}
          />
          <button onMouseDown={trapFocus} className={cx("menuButton", "bold")} onClick={increaseFontSize}>
            +
          </button>

          <button
            onMouseDown={trapFocus}
            onClick={() => editor.chain().focus().toggleSuperscript().run()}
            className={cx("menuButton", "bold")}
          >
            x<span className={styles.superscript}>2</span>
          </button>

          <button onMouseDown={trapFocus} className={cx("menuButton", "reset")} onClick={reset}>
            <TrashCanIcon />
          </button>
        </div>
      </BubbleMenu>

      <div className={cx("input")} onClick={handleFocus}>
        {icon && (
          <div className={styles.iconWrapper}>
            <IconWithCustomColor icon={icon} innerFill={undefined} className={styles.icon} />
          </div>
        )}
        <EditorContent className={styles.editor} editor={editor} />
      </div>
    </div>
  );
};

export const RichTextDisplay = ({
  value,
  plain,
  className,
}: {
  value: string;
  plain?: boolean;
  className?: string;
}) => {
  const editor = useEditor(
    {
      extensions: [Document, Paragraph, Text, TextStyle, Color, FontSize, Bold, Italic, Superscript],
      content: value,
      editable: false,
    },
    [value],
  );

  const combinedClassnames = cx(className, styles.readOnlyEditor);

  if (!editor) {
    return null;
  }

  if (plain) {
    return <span>{editor.getText()}</span>;
  }

  return <EditorContent className={combinedClassnames} editor={editor} />;
};
