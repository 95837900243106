import classNames from "classnames/bind";
import { Toaster } from "react-hot-toast";
import toast, { type Toast } from "react-hot-toast";

import { CircleCheckIcon, CloseIcon, InfoCircleIcon, WarningIcon } from "../../assets/icons";
import { IconButton } from "../IconButton";
import { Paragraph } from "../Paragraph";

import styles from "./Notification.module.scss";

const cx = classNames.bind(styles);

export type NotificationType = "success" | "danger" | "info";

type OmittedToastProps = "type" | "message" | "icon" | "iconType";
type ToastProps = Omit<Toast, OmittedToastProps>;

type NotificationProps = ToastProps & {
  type: NotificationType;
  title: string;
  description: string;
};

export const NotificationTarget = Toaster;

export const Notification = ({ type, title, description, id, ariaProps }: NotificationProps) => {
  const dismiss = () => {
    toast.dismiss(id);
  };

  const renderIcon = () => {
    const iconClassName = cx("icon", type);
    if (type === "success") {
      return <CircleCheckIcon className={iconClassName} />;
    }

    if (type === "danger") {
      return <WarningIcon className={iconClassName} />;
    }

    return <InfoCircleIcon className={iconClassName} />;
  };

  const wrapperClassName = cx("box", type);
  const progressClassName = cx("progress", type);

  return (
    <div className={wrapperClassName}>
      <div className={styles.contentWrapper} {...ariaProps}>
        <div className={styles.iconWrapper}>{renderIcon()}</div>
        <div className={styles.content}>
          <Paragraph size="large" weight="font-bold">
            {title}
          </Paragraph>
          <Paragraph>{description}</Paragraph>
        </div>

        <IconButton size="small" icon={<CloseIcon />} onClick={dismiss} />
      </div>

      <div className={styles.progressBar}>
        <span className={progressClassName}></span>
      </div>
    </div>
  );
};
