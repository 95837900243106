import { createContext, PropsWithChildren, useState, ReactNode, useEffect } from "react";
import { DECISECOND } from "../constants";

export type TimeContextType = {
  uiTime: number;
  totalTime: number;
  getCurrentTime: () => number;
};

export interface TimeContextProps extends PropsWithChildren {
  isPaused: boolean;
  time: number;
  onTimeEnd: () => void;
  children: ReactNode;
}

export const TimeContext = createContext<TimeContextType | undefined>(undefined);

export const TimeProvider = ({ children, time, onTimeEnd, isPaused }: TimeContextProps) => {
  const [pauseTimestamp, setPauseTimestamp] = useState(0);
  const [startTime, setStartTime] = useState(Date.now());
  const [internalTime, setInternalTime] = useState(time);
  const [uiTime, setUiTime] = useState(time);

  useEffect(() => {
    const id = setInterval(() => {
      setInternalTime((timeLeft: number) => (timeLeft > 0 ? timeLeft - DECISECOND : 0));
    }, DECISECOND);

    return () => clearInterval(id);
  }, []);

  useEffect(() => {
    if (!isPaused) {
      setUiTime(internalTime);
    }
  }, [internalTime]);

  useEffect(() => {
    if (isPaused) {
      setPauseTimestamp(Date.now());
    } else if (pauseTimestamp !== 0) {
      setInternalTime(uiTime);
      const diff = Date.now() - pauseTimestamp;
      setStartTime(startTime + diff);
    }
  }, [isPaused]);

  useEffect(() => {
    if (uiTime === 0) {
      onTimeEnd();
    }
  }, [uiTime]);

  const getCurrentTime = () => {
    return Date.now() - startTime;
  };

  return (
    <TimeContext.Provider
      value={{
        uiTime,
        getCurrentTime,
        totalTime: time,
      }}
    >
      {children}
    </TimeContext.Provider>
  );
};
