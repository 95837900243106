import classNames from "classnames/bind";

import { Paragraph } from "../../Paragraph";

import styles from "./RatingItem.module.scss";

const cx = classNames.bind(styles);

export interface Rating {
  id: string;
  value: number;
  image: string;
  label: string;
}

export interface RatingItemProps {
  rating: Rating;
  isActive: boolean;
  onClick?: (value: number) => void;
}

export const RatingItem = ({ rating, isActive, onClick }: RatingItemProps) => {
  return (
    <div
      className={cx("rating", { isActive })}
      onClick={() => onClick?.(rating.value)}
      role="button"
      tabIndex={0}
      onKeyDown={() => onClick?.(rating.value)}
    >
      <div className={styles.imageWrapper}>
        <img src={rating.image} alt={rating.label} />
      </div>
      <Paragraph className={styles.label} color="secondary">
        {rating.label}
      </Paragraph>
    </div>
  );
};
