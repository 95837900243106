import { createContext, ReactNode, useCallback, useContext, useMemo } from "react";
import { useApolloClient } from "@apollo/client";

type Context = {
  signIn: (token: string) => void;
  signOut: () => void;
};
type UserContextProviderProps = {
  children: ReactNode;
};

export const UserContext = createContext<Context>({
  // eslint-disable-next-line
  signIn: () => {},
  // eslint-disable-next-line
  signOut: () => {},
});

const UserContextProvider = ({ children }: UserContextProviderProps): JSX.Element => {
  const client = useApolloClient();

  const signOut = useCallback(() => {
    localStorage.removeItem("token");
    client.resetStore();
    // TODO: call revoke token here
  }, []);

  const signIn = useCallback((token: string) => {
    localStorage.setItem("token", token);
  }, []);

  const contextValue = useMemo(
    () => ({
      signIn,
      signOut,
    }),
    [signIn, signOut],
  );

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};

export const useUserContext = () => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error("useUserContext was used outside of its Provider");
  }

  return context;
};

export default UserContextProvider;
