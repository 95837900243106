import { TimerVariant } from "./components/shared/Timer";

// SWIPER
export const CARD_COMMIT_X = 150;
export const CARD_EXIT_TRANSFORM_DURATION = 0.3;
export const CARD_EXIT_DURATION = CARD_EXIT_TRANSFORM_DURATION + 0.7;
export const CARD_EXIT_ROTATE_RANGE = 16;
export const CARD_EXIT_SCALE = 0.5;
export const CARD_EXIT_SIZE = 0.3;
export const CARD_EXIT_TRANSFORM_X = 250;
export const CARD_OVERLAY_OPACITY = 0.8;
export const CARDS_TO_RENDER = 3;
export const LEFT_SWIPE_SHADOW = "linear-gradient(135deg, rgba(248, 177, 135, 0.56) 0%, rgba(193, 79, 11, 0.56) 100%);";
export const RIGHT_SWIPE_SHADOW = "inset -100px -9px 88px -89px rgb(60, 179, 113)";
export const ROTATE_RANGE = 8;

// RANKIFIER

export const RANKIFIER_ROOT_ID = "rankifier-game";
export const RANKIFIER_CARD_ROTATION_DURATION = 0.4;
export const RANKIFIER_ANIMATION_SEPARATION = 0.6;

// QUICKRANK

export const QUICKRANK_ROOT_ID = "quickrank-game";
export const QUICKRANK_RATING_DESTINATION_ID = "destination-rating";

export const QUICKRANK_SELECTED_CARD_DURATION = 0.3;
export const QUICKRANK_SELECTED_CARD_SCALE = 2;
export const QUICKRANK_SELECTED_CARD_Y = -200;

export const QUICKRANK_REJECTED_CARD_DURATION = 0.3;
export const QUICKRANK_REJECTED_CARD_SCALE = 0.5;

// COMMON FOR CARDS

export const CARD_ANIMATION_SEPARATION = 0.3;

export const CARD_REVERT_DURATION = 0.5;
export const CARD_REVERT_SEPARATION = 0.1;

export const CARD_TO_DECK_DURATION = 0.3;
export const CARD_TO_DECK_SEPARATION = 0.05;
export const CARD_TO_HAND_DURATION = 0.5;
export const CARD_TO_HAND_SEPARATION = 0.1;

// WORDOPOLIS

export const WORDOPOLIS_STAGE_SIZE = 800;
export const WORDOPOLIS_CELL_STROKE = 2;

// GAME RESULTS VIEW

export const GAME_RESULTS_ANIMATION_DURATION = 4;

// TIME

export const DECISECOND = 100;
export const SECOND = 1000;
export const MINUTE = 60 * SECOND;

// TIMER

export const TIMER_CIRCLE_PERCENTAGE = {
  [TimerVariant.DEFAULT]: 0.63,
  [TimerVariant.CIRCLE]: 1,
};
export const TIMER_CIRCLE_ROTATION = {
  [TimerVariant.DEFAULT]: -114,
  [TimerVariant.CIRCLE]: 0,
};
export const TIMER_ANIMATION: KeyframeAnimationOptions = {
  duration: DECISECOND,
  easing: "linear",
  fill: "forwards",
};
export const TIMER_CIRCLE_ATTRIBUTE = {
  cx: 40,
  cy: 40,
  r: 35,
};

// COLORS

export const COLOR_NEUTRALS_WHITE = "#FCFCFC";
export const COLOR_NEUTRALS_300 = "#E8E8E8";
export const COLOR_NEUTRALS_FONT_SECONDARY = "#4E4F4B";

export const COLOR_BRAND_ORANGE_BASE = "#F27124";

export const COLOR_BRAND_TURQUISE_BASE = "#00DCD9";
export const COLOR_BRAND_TURQUISE_900 = "#007574";

export const COLOR_BRAND_ROSE_700 = "#FF1A38";

export const COLOR_BRAND_BLUE_900 = "#1FA7FF";

export const COLOR_CORRECT_GREEN_LIGHT = "#D7FFBE";
export const COLOR_CORRECT_GREEN = "#0EB100";
export const COLOR_WRONG_RED_LIGHT = "#FFBEBE";
export const COLOR_WRONG_RED = "#B92201";
