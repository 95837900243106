import classNames from "classnames/bind";
import { useMemo } from "react";

import { Button } from "../Button";
import { Header } from "../Header";
import { Paragraph } from "../Paragraph";
import { StarIcon } from "../../assets/icons";

import styles from "./ResultsPage.module.scss";

const cx = classNames.bind(styles);

export enum Gradients {
  Neutrals = "linear-gradient(315deg, #9C9D9A 0%, #CFCFCE 100%)",
  Blue = "linear-gradient(315deg, #6BC5FF 0%, #9ED9FF 100%)",
  Green = "linear-gradient(315deg, #959E3D 0%, #B3BC52 100%)",
  Orange = "linear-gradient(315deg, #F1630E 0%, #F4823E 100%)",
  Red = "linear-gradient(315deg, #FF334F 0%, #FF667B 100%)",
}

export interface ResultsPageProps {
  result: number;
  onConfirm?: () => void;
  config: {
    scoreTag_0_20: string;
    scoreTag_21_40: string;
    scoreTag_41_60: string;
    scoreTag_61_80: string;
    scoreTag_81_100: string;
    scoreTitle_0_20: string;
    scoreTitle_21_40: string;
    scoreTitle_41_60: string;
    scoreTitle_61_80: string;
    scoreTitle_81_100: string;
  };
  themeColor: string;
  cardClassName?: string;
}

export const ResultsPage = ({ result, onConfirm, themeColor, config, cardClassName }: ResultsPageProps) => {
  const background = useMemo(() => {
    if (result <= 20) {
      return Gradients.Neutrals;
    }
    if (result <= 40 && result > 20) {
      return Gradients.Blue;
    }
    if (result <= 60 && result > 40) {
      return Gradients.Green;
    }

    if (result <= 80 && result > 60) {
      return Gradients.Orange;
    }

    return Gradients.Red;
  }, [result]);

  const scoreTitle = useMemo(() => {
    if (result <= 20) {
      return config.scoreTitle_0_20;
    }
    if (result <= 40 && result > 20) {
      return config.scoreTitle_21_40;
    }
    if (result <= 60 && result > 40) {
      return config.scoreTitle_41_60;
    }
    if (result <= 80 && result > 60) {
      return config.scoreTitle_61_80;
    }
    return config.scoreTitle_81_100;
  }, [result, config]);

  const scoreDescription = useMemo(() => {
    if (result <= 20) {
      return config.scoreTag_0_20;
    }
    if (result <= 40 && result > 20) {
      return config.scoreTag_21_40;
    }
    if (result <= 60 && result > 40) {
      return config.scoreTag_41_60;
    }
    if (result <= 80 && result > 60) {
      return config.scoreTag_61_80;
    }
    return config.scoreTag_81_100;
  }, [result, config]);

  return (
    <div className={styles.container}>
      <Header variant="h6" className={styles.header}>
        Your matching status
      </Header>

      <div>
        <div className={cx("resultCard", cardClassName)}>
          <div className={styles.overlay} style={{ height: `${result}%`, background }}></div>
          <StarIcon className={styles.star} />
          <Paragraph size="large" weight="font-bold" className={styles.result}>
            {result}%
          </Paragraph>
        </div>
      </div>
      <div className={styles.results}>
        <Header variant="h5" className={styles.resultsHeader}>
          {scoreTitle}
        </Header>
        <Paragraph className={styles.resultsDescription} size="small">
          {scoreDescription}
        </Paragraph>
      </div>

      <Button style={{ background: themeColor }} className={styles.continueButton} onClick={onConfirm}>
        Continue
      </Button>
    </div>
  );
};
