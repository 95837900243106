import { forwardRef, HTMLProps, LegacyRef, ReactElement, useState } from "react";
import classNames from "classnames/bind";

import { EyeIcon, EyeOffIcon } from "../../assets/icons";
import { TextInputIcon } from "./TextInputIcon";

import styles from "./TextInput.module.scss";

const cx = classNames.bind(styles);

export type TextInputProps = Omit<HTMLProps<HTMLInputElement>, "size"> & {
  error?: string | boolean;
  size?: "small" | "medium" | "large";
  customColor?: string;
  success?: boolean;
  icon?: ReactElement;
};

const TextInput = forwardRef((props: TextInputProps, ref: LegacyRef<HTMLInputElement> | undefined) => {
  const {
    customColor,
    value,
    type,
    size = "medium",
    onChange,
    onBlur,
    className,
    success,
    disabled,
    error,
    icon,
    ...restProps
  } = props;
  const [isVisible, setIsVisible] = useState(false);
  const isPassword = type === "password";
  const isFilled = value !== undefined && value !== null && !Object.is(value, NaN);

  const toggleVisibility = () => setIsVisible(!isVisible);

  const wrapperClassName = cx(
    "wrapper",
    size,
    className,
    { password: isPassword },
    !disabled && { error, filled: isFilled, success },
  );

  return (
    <div className={wrapperClassName}>
      {icon && <TextInputIcon customColor={isFilled ? customColor : undefined} icon={icon} type={type} />}
      <input
        {...restProps}
        ref={ref}
        value={isFilled ? value : ""}
        type={isPassword && isVisible ? "text" : type}
        disabled={disabled}
        onChange={onChange}
        className={styles.input}
        onFocus={({ target }) => {
          if (customColor) {
            target.style.borderColor = customColor;
          }
        }}
        onBlur={(event) => {
          event.target.style.borderColor = "";
          onBlur?.(event);
        }}
      />
      {isPassword && (
        <TextInputIcon
          disabled={disabled}
          icon={isVisible ? <EyeOffIcon /> : <EyeIcon />}
          side="right"
          onClick={toggleVisibility}
        />
      )}
    </div>
  );
});
TextInput.displayName = "TextInput";

export { TextInput };
