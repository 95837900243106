import { ReactElement } from "react";
import classNames from "classnames/bind";

import { IconWithCustomColor } from "../IconWithCustomColor";

import styles from "./TextInput.module.scss";

const cx = classNames.bind(styles);

type LeftIconProps = React.ComponentProps<"svg"> & {
  side?: "left" | "right";
  disabled?: boolean;
  customColor?: string;
  icon: ReactElement;
};

export const TextInputIcon = ({ disabled, customColor, side = "left", icon, ...restProps }: LeftIconProps) => {
  restProps.className = cx("icon", side, { disabled });

  return (
    <div className={cx("iconWrapper", side)}>
      <IconWithCustomColor icon={icon} innerFill={customColor} {...restProps} />
    </div>
  );
};
