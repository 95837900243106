import classNames from "classnames/bind";

import { ArrowRightIcon } from "../../assets/icons";
import { Button } from "../Button";
import { Header } from "../Header";
import { Paragraph } from "../Paragraph";
import { Table } from "./Table";
import { LeaderboardRow } from "./Table/Row";

import styles from "./Leaderboard.module.scss";

const cx = classNames.bind(styles);

export interface LeaderboardConfiguration {
  header: string;
  headerColor: string;
  id: string;
  nicknameLabel: string;
  nicknameQuestion: string;
  nicknameQuestionColor: string;
  numberOfPlayers: number;
  showTimer: boolean;
  subheader: string;
  subheaderColor: string;
  tableNicknameLabel: string;
  tableScoreLabel: string;
  tableTimeLabel: string;
}

export interface LeaderboardProps {
  configuration: LeaderboardConfiguration;
  themeColor?: string;
  results: LeaderboardRow[];
  currentRow?: LeaderboardRow | null;
  onShare?: () => void;
  onContinue?: () => void;
  className?: string;
}

export const Leaderboard = ({
  configuration,
  themeColor,
  results,
  currentRow,
  // onShare,
  onContinue,
  className,
}: LeaderboardProps) => {
  const { header, headerColor, subheader, subheaderColor, numberOfPlayers } = configuration;

  return (
    <div className={cx("leaderboardWindow", className)}>
      <div className={styles.heading}>
        <div className={styles.infoWrapper}>
          <div className={styles.headerLine}>
            <Header style={{ color: headerColor }} className={styles.header} variant="h6">
              {header}
            </Header>
            <Paragraph className={styles.headerScore} size="xs" color="neutrals-600">
              {numberOfPlayers < results.length ? numberOfPlayers : results.length}/{numberOfPlayers}
            </Paragraph>
          </div>
          <Paragraph style={{ color: subheaderColor }} className={styles.headingInfo} size="small" color="secondary">
            {subheader}
          </Paragraph>
        </div>
        {/* TODO: make it behind the flag */}
        {/* <ResponsiveButton
          className={styles.shareButton}
          kind="outline"
          customColor={themeColor}
          icon={<SendIcon />}
          onClick={onShare}
          iconSide="left"
        >
          Share Score
        </ResponsiveButton> */}
      </div>
      <Table
        themeColor={themeColor}
        rows={results}
        currentRow={currentRow ?? undefined}
        configuration={configuration}
      />
      {onContinue ? (
        <div className={styles.buttonWrapper}>
          <Button
            className={styles.continueButton}
            customColor={themeColor}
            kind="primary"
            onClick={onContinue}
            icon={<ArrowRightIcon />}
            iconSide="left"
          >
            Continue
          </Button>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};
