import classNames from "classnames";
import {
  BrokenHeart,
  CheckIcon,
  CloseIcon,
  HappyFace,
  HeartThin,
  Minus,
  PlusThin,
  SadFace,
  ThumbDown,
  ThumbUp,
} from "../../assets/icons";
import { IconSet as IconSetEnum } from "../../../../apps/builder/src/gql/graphql";

import styles from "./IconSet.module.scss";

const cx = classNames.bind(styles);

type IconComponents = {
  PositiveIcon: React.ElementType;
  NegativeIcon: React.ElementType;
};

type IconSetMap = Record<IconSetEnum, IconComponents>;

const iconSets: IconSetMap = {
  [IconSetEnum.Default]: {
    PositiveIcon: CheckIcon,
    NegativeIcon: CloseIcon,
  },
  [IconSetEnum.ThumbsUpAndDown]: {
    PositiveIcon: ThumbUp,
    NegativeIcon: ThumbDown,
  },
  [IconSetEnum.PlusAndMinus]: {
    PositiveIcon: PlusThin,
    NegativeIcon: Minus,
  },
  [IconSetEnum.Faces]: {
    PositiveIcon: HappyFace,
    NegativeIcon: SadFace,
  },
  [IconSetEnum.Hearts]: {
    PositiveIcon: HeartThin,
    NegativeIcon: BrokenHeart,
  },
};

interface Props extends React.ComponentPropsWithoutRef<"div"> {
  iconSetKey?: IconSetEnum;
  active?: boolean;
}

export function NegativeIcon({ iconSetKey = IconSetEnum.Default, ...props }: Props) {
  const IconComponent = iconSets[iconSetKey]["NegativeIcon"];
  return <IconComponent {...props} />;
}

export function PositiveIcon({ iconSetKey = IconSetEnum.Default, ...props }: Props) {
  const IconComponent = iconSets[iconSetKey]["PositiveIcon"];
  return <IconComponent {...props} />;
}

export function IconSet({ iconSetKey = IconSetEnum.Default, active = false, ...props }: Props) {
  const { PositiveIcon, NegativeIcon } = iconSets[iconSetKey];
  return (
    <div className={cx(styles.iconSet, { [styles.active]: active })} {...props}>
      <div className={styles.iconContainer}>
        <PositiveIcon className={styles.icon} />
      </div>
      <span className={styles.iconSeparator}></span>
      <div className={styles.iconContainer}>
        <NegativeIcon className={styles.icon} />
      </div>
    </div>
  );
}
