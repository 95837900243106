/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: string;
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  UUID: string;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AggregatedTriviaQuestionScore = {
  __typename?: "AggregatedTriviaQuestionScore";
  count: Scalars["Int"];
  option: TriviaAnswerOption;
};

export type Attribute = {
  __typename?: "Attribute";
  createdAt: Scalars["DateTime"];
  customRatingQuestion?: Maybe<CustomRatingQuestion>;
  customText?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  image?: Maybe<LibraryImage>;
  isCorrect: Scalars["Boolean"];
  name: Scalars["String"];
  points: Scalars["Int"];
  showImageAndText: Scalars["Boolean"];
  slug: Scalars["Int"];
  updatedAt: Scalars["DateTime"];
};

export type AttributeCreateInput = {
  customText?: InputMaybe<Scalars["String"]>;
  imageId?: InputMaybe<Scalars["String"]>;
  isCorrect?: InputMaybe<Scalars["Boolean"]>;
  name: Scalars["String"];
};

export type AttributeResult = {
  __typename?: "AttributeResult";
  attribute: Attribute;
  totalScore: Scalars["Int"];
};

export type AttributeScore = {
  __typename?: "AttributeScore";
  attribute: Attribute;
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  score: Scalars["Int"];
  updatedAt: Scalars["DateTime"];
};

export type AttributeScoreInput = {
  attributeId: Scalars["UUID"];
  score: Scalars["Int"];
};

export type AttributeUpdateInput = {
  /** Set to null to remove the custom rating question */
  customRatingQuestion?: InputMaybe<CustomRatingQuestionUpdateInput>;
  customText?: InputMaybe<Scalars["String"]>;
  imageId?: InputMaybe<Scalars["String"]>;
  isCorrect?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  points?: InputMaybe<Scalars["Int"]>;
  showImageAndText?: InputMaybe<Scalars["Boolean"]>;
};

export type CheckoutSession = {
  __typename?: "CheckoutSession";
  id: Scalars["String"];
};

export type ConfigurationUnion =
  | QuickRankConfiguration
  | RankifierConfiguration
  | SwiperConfiguration
  | TriviaConfiguration
  | WordleConfiguration
  | WordopolisConfiguration;

export type CreateDirectoryInput = {
  name: Scalars["String"];
  parentDirectoryId?: InputMaybe<Scalars["UUID"]>;
};

export type CreateLibraryImagesInput = {
  files: Array<Scalars["Upload"]>;
  kind: LibraryImageKind;
};

export type CreateProjectInput = {
  directoryId?: InputMaybe<Scalars["UUID"]>;
  hideDatagameBranding?: InputMaybe<Scalars["Boolean"]>;
  hideSummaryPage?: InputMaybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  showLeaderboard?: InputMaybe<Scalars["Boolean"]>;
  status?: InputMaybe<ProjectStatus>;
};

export type CreateVideoInput = {
  file: Scalars["Upload"];
};

export type CustomRatingQuestion = {
  __typename?: "CustomRatingQuestion";
  leftLabel: Scalars["String"];
  question: Scalars["String"];
  rightLabel: Scalars["String"];
  scaleSize: Scalars["Int"];
};

export type CustomRatingQuestionUpdateInput = {
  leftLabel?: InputMaybe<Scalars["String"]>;
  question?: InputMaybe<Scalars["String"]>;
  rightLabel?: InputMaybe<Scalars["String"]>;
  scaleSize?: InputMaybe<Scalars["Int"]>;
};

export type Directory = {
  __typename?: "Directory";
  createdAt: Scalars["DateTime"];
  default: Scalars["Boolean"];
  id: Scalars["UUID"];
  name: Scalars["String"];
  parentDirectory?: Maybe<Directory>;
  parentDirectoryId?: Maybe<Scalars["UUID"]>;
  updatedAt: Scalars["DateTime"];
};

export type FeedbackPage = {
  __typename?: "FeedbackPage";
  backgroundColor: Scalars["String"];
  backgroundImage?: Maybe<LibraryImage>;
  confirmation: Scalars["String"];
  confirmationColor: Scalars["String"];
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  instructionLine: Scalars["String"];
  instructionLineColor: Scalars["String"];
  submitButton: Scalars["String"];
  submitButtonColor: Scalars["String"];
  textBoxPrompt: Scalars["String"];
  textBoxPromptColor: Scalars["String"];
  title: Scalars["String"];
  titleColor: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export type FeedbackPageUpdateInput = {
  backgroundColor?: InputMaybe<Scalars["String"]>;
  backgroundImageId?: InputMaybe<Scalars["UUID"]>;
  confirmation?: InputMaybe<Scalars["String"]>;
  confirmationColor?: InputMaybe<Scalars["String"]>;
  instructionLine?: InputMaybe<Scalars["String"]>;
  instructionLineColor?: InputMaybe<Scalars["String"]>;
  submitButton?: InputMaybe<Scalars["String"]>;
  submitButtonColor?: InputMaybe<Scalars["String"]>;
  textBoxPrompt?: InputMaybe<Scalars["String"]>;
  textBoxPromptColor?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  titleColor?: InputMaybe<Scalars["String"]>;
};

export type Game = {
  __typename?: "Game";
  configuration: ConfigurationUnion;
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  kind: GameKind;
  name: Scalars["String"];
  responseCount: Scalars["Int"];
  responses: Array<Response>;
  updatedAt: Scalars["DateTime"];
};

export type GameCreateInput = {
  kind: GameKind;
  name: Scalars["String"];
  projectId: Scalars["UUID"];
};

/** Type of a game */
export enum GameKind {
  QuickRank = "QUICK_RANK",
  Rankifier = "RANKIFIER",
  Swiper = "SWIPER",
  Trivia = "TRIVIA",
  Wordle = "WORDLE",
  Wordopolis = "WORDOPOLIS",
}

export type GameResultsConfiguration = {
  __typename?: "GameResultsConfiguration";
  backgroundColor: Scalars["String"];
  backgroundImage?: Maybe<LibraryImage>;
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  scoreTag_0_20: Scalars["String"];
  scoreTag_21_40: Scalars["String"];
  scoreTag_41_60: Scalars["String"];
  scoreTag_61_80: Scalars["String"];
  scoreTag_81_100: Scalars["String"];
  scoreTitle_0_20: Scalars["String"];
  scoreTitle_21_40: Scalars["String"];
  scoreTitle_41_60: Scalars["String"];
  scoreTitle_61_80: Scalars["String"];
  scoreTitle_81_100: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export type GameResultsConfigurationUpdateInput = {
  backgroundColor?: InputMaybe<Scalars["String"]>;
  backgroundImageId?: InputMaybe<Scalars["UUID"]>;
  scoreTag_0_20?: InputMaybe<Scalars["String"]>;
  scoreTag_21_40?: InputMaybe<Scalars["String"]>;
  scoreTag_41_60?: InputMaybe<Scalars["String"]>;
  scoreTag_61_80?: InputMaybe<Scalars["String"]>;
  scoreTag_81_100?: InputMaybe<Scalars["String"]>;
  scoreTitle_0_20?: InputMaybe<Scalars["String"]>;
  scoreTitle_21_40?: InputMaybe<Scalars["String"]>;
  scoreTitle_41_60?: InputMaybe<Scalars["String"]>;
  scoreTitle_61_80?: InputMaybe<Scalars["String"]>;
  scoreTitle_81_100?: InputMaybe<Scalars["String"]>;
};

export type GameUpdateInput = {
  name: Scalars["String"];
};

export type GameVisuals = {
  __typename?: "GameVisuals";
  absentLetter: Scalars["String"];
  backgroundColor: Scalars["String"];
  backgroundImage?: Maybe<LibraryImage>;
  backgroundNegativeColor: Scalars["String"];
  backgroundPositiveColor: Scalars["String"];
  cardBackImage?: Maybe<LibraryImage>;
  cardFrontImage?: Maybe<LibraryImage>;
  cardStringColor: Scalars["String"];
  correctLetter: Scalars["String"];
  createdAt: Scalars["DateTime"];
  defaultLetter: Scalars["String"];
  iconSet: IconSet;
  id: Scalars["UUID"];
  negativeColor: Scalars["String"];
  positiveColor: Scalars["String"];
  presentLetter: Scalars["String"];
  questionStringColor: Scalars["String"];
  showIcons: Scalars["Boolean"];
  themeColor: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export type GameVisualsUpdateInput = {
  absentLetter?: InputMaybe<Scalars["String"]>;
  backgroundColor?: InputMaybe<Scalars["String"]>;
  backgroundImageId?: InputMaybe<Scalars["String"]>;
  backgroundNegativeColor?: InputMaybe<Scalars["String"]>;
  backgroundPositiveColor?: InputMaybe<Scalars["String"]>;
  cardBackImageId?: InputMaybe<Scalars["String"]>;
  cardFrontImageId?: InputMaybe<Scalars["String"]>;
  cardStringColor?: InputMaybe<Scalars["String"]>;
  correctLetter?: InputMaybe<Scalars["String"]>;
  defaultLetter?: InputMaybe<Scalars["String"]>;
  iconSet?: InputMaybe<IconSet>;
  negativeColor?: InputMaybe<Scalars["String"]>;
  positiveColor?: InputMaybe<Scalars["String"]>;
  presentLetter?: InputMaybe<Scalars["String"]>;
  questionStringColor?: InputMaybe<Scalars["String"]>;
  showIcons?: InputMaybe<Scalars["Boolean"]>;
  themeColor?: InputMaybe<Scalars["String"]>;
};

/** The set of icons to use for a game */
export enum IconSet {
  Default = "DEFAULT",
  Faces = "FACES",
  Hearts = "HEARTS",
  PlusAndMinus = "PLUS_AND_MINUS",
  ThumbsUpAndDown = "THUMBS_UP_AND_DOWN",
}

export type LeaderboardConfiguration = {
  __typename?: "LeaderboardConfiguration";
  backgroundColor: Scalars["String"];
  backgroundImage?: Maybe<LibraryImage>;
  createdAt: Scalars["DateTime"];
  header: Scalars["String"];
  headerColor: Scalars["String"];
  id: Scalars["UUID"];
  nicknameLabel: Scalars["String"];
  nicknameQuestion: Scalars["String"];
  nicknameQuestionColor: Scalars["String"];
  numberOfPlayers: Scalars["Int"];
  showTimer: Scalars["Boolean"];
  subheader: Scalars["String"];
  subheaderColor: Scalars["String"];
  tableNicknameLabel: Scalars["String"];
  tableScoreLabel: Scalars["String"];
  tableTimeLabel: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export type LeaderboardConfigurationUpdateInput = {
  backgroundColor?: InputMaybe<Scalars["String"]>;
  backgroundImageId?: InputMaybe<Scalars["UUID"]>;
  header?: InputMaybe<Scalars["String"]>;
  headerColor?: InputMaybe<Scalars["String"]>;
  nicknameLabel?: InputMaybe<Scalars["String"]>;
  nicknameQuestion?: InputMaybe<Scalars["String"]>;
  nicknameQuestionColor?: InputMaybe<Scalars["String"]>;
  numberOfPlayers?: InputMaybe<Scalars["Int"]>;
  showTimer?: InputMaybe<Scalars["Boolean"]>;
  subheader?: InputMaybe<Scalars["String"]>;
  subheaderColor?: InputMaybe<Scalars["String"]>;
  tableNicknameLabel?: InputMaybe<Scalars["String"]>;
  tableScoreLabel?: InputMaybe<Scalars["String"]>;
  tableTimeLabel?: InputMaybe<Scalars["String"]>;
};

export type LeaderboardRow = {
  __typename?: "LeaderboardRow";
  id: Scalars["UUID"];
  nickname: Scalars["String"];
  points: Scalars["Int"];
  rank: Scalars["Int"];
  timeInMs: Scalars["Int"];
};

export type LibraryImage = {
  __typename?: "LibraryImage";
  createdAt: Scalars["DateTime"];
  extension: Scalars["String"];
  id: Scalars["UUID"];
  isGlobal: Scalars["Boolean"];
  kind: LibraryImageKind;
  name: Scalars["String"];
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars["UUID"]>;
  updatedAt: Scalars["DateTime"];
  url: Scalars["String"];
};

export enum LibraryImageKind {
  Background = "BACKGROUND",
  CardBack = "CARD_BACK",
  CardFront = "CARD_FRONT",
  GameBoard = "GAME_BOARD",
  Logo = "LOGO",
  Poster = "POSTER",
  TriviaImage = "TRIVIA_IMAGE",
}

export type Mutation = {
  __typename?: "Mutation";
  attributeCreate: Attribute;
  attributeDelete: Attribute;
  attributeUpdate: Attribute;
  directoryCreate: Directory;
  directoryDelete: Directory;
  directoryUpdate: Directory;
  feedbackPageUpdate: FeedbackPage;
  gameAddRating: Game;
  gameCreate: Game;
  gameDelete: Game;
  gameResultsUpdate: GameResultsConfiguration;
  gameUpdate: Game;
  gameVisualsUpdate: GameVisuals;
  leaderboardConfigurationUpdate: LeaderboardConfiguration;
  libraryImageCreate: Array<LibraryImage>;
  libraryImageDelete: LibraryImage;
  projectCopy: Project;
  projectCreate: Project;
  projectDelete: Project;
  projectDeleteData: Project;
  projectUpdate: Project;
  quickRankConfigurationUpdate: QuickRankConfiguration;
  rankifierConfigurationUpdate: RankifierConfiguration;
  responseCreate: Response;
  signIn: SignInResponse;
  signInWithCode: SignInResponse;
  signUp: User;
  stripeCheckoutCreate: CheckoutSession;
  stripePortalSessionCreate: PortalSession;
  swiperConfigurationUpdate: SwiperConfiguration;
  triviaAnswerOptionCreate: TriviaAnswerOption;
  triviaAnswerOptionDelete: TriviaQuestion;
  triviaAnswerOptionUpdate: TriviaAnswerOption;
  triviaConfigurationUpdate: TriviaConfiguration;
  triviaQuestionCreate: TriviaQuestion;
  triviaQuestionDelete: TriviaQuestion;
  triviaQuestionUpdate: TriviaQuestion;
  userDelete: User;
  userDeleteCurrent: User;
  userGrantAdmin: User;
  userInvite: User;
  userRequestPasswordReset: Scalars["Boolean"];
  userResendInvitation: User;
  userRevokeAdmin: User;
  userUpdate: User;
  userUpdatePassword: User;
  videoCreate: Video;
  videoDelete: Video;
  welcomePageUpdate: WelcomePage;
  wordleConfigurationUpdate: WordleConfiguration;
  wordopolisConfigurationUpdate: WordopolisConfiguration;
};

export type MutationAttributeCreateArgs = {
  data: AttributeCreateInput;
  gameId: Scalars["String"];
};

export type MutationAttributeDeleteArgs = {
  attributeId: Scalars["String"];
};

export type MutationAttributeUpdateArgs = {
  attributeId: Scalars["String"];
  data: AttributeUpdateInput;
};

export type MutationDirectoryCreateArgs = {
  data: CreateDirectoryInput;
};

export type MutationDirectoryDeleteArgs = {
  directoryId: Scalars["String"];
};

export type MutationDirectoryUpdateArgs = {
  data: UpdateDirectoryInput;
  directoryId: Scalars["String"];
};

export type MutationFeedbackPageUpdateArgs = {
  data: FeedbackPageUpdateInput;
  feedbackPageId: Scalars["String"];
};

export type MutationGameAddRatingArgs = {
  gameId: Scalars["String"];
  rating: Scalars["Int"];
};

export type MutationGameCreateArgs = {
  data: GameCreateInput;
};

export type MutationGameDeleteArgs = {
  gameId: Scalars["String"];
};

export type MutationGameResultsUpdateArgs = {
  data: GameResultsConfigurationUpdateInput;
  gameResultsId: Scalars["String"];
};

export type MutationGameUpdateArgs = {
  data: GameUpdateInput;
  gameId: Scalars["String"];
};

export type MutationGameVisualsUpdateArgs = {
  data: GameVisualsUpdateInput;
  gameVisualsId: Scalars["String"];
};

export type MutationLeaderboardConfigurationUpdateArgs = {
  data: LeaderboardConfigurationUpdateInput;
  leaderboardConfigurationId: Scalars["String"];
};

export type MutationLibraryImageCreateArgs = {
  input: CreateLibraryImagesInput;
};

export type MutationLibraryImageDeleteArgs = {
  libraryImageId: Scalars["UUID"];
};

export type MutationProjectCopyArgs = {
  directoryId?: InputMaybe<Scalars["String"]>;
  projectId: Scalars["String"];
};

export type MutationProjectCreateArgs = {
  data: CreateProjectInput;
};

export type MutationProjectDeleteArgs = {
  projectId: Scalars["String"];
};

export type MutationProjectDeleteDataArgs = {
  projectId: Scalars["String"];
};

export type MutationProjectUpdateArgs = {
  data: ProjectUpdateInput;
  projectId: Scalars["String"];
};

export type MutationQuickRankConfigurationUpdateArgs = {
  data: QuickRankConfigurationUpdateInput;
  quickRankConfigurationId: Scalars["String"];
};

export type MutationRankifierConfigurationUpdateArgs = {
  data: RankifierConfigurationUpdateInput;
  rankifierConfigurationId: Scalars["String"];
};

export type MutationResponseCreateArgs = {
  data: ResponseCreateInput;
};

export type MutationSignInArgs = {
  email: Scalars["String"];
  password: Scalars["String"];
};

export type MutationSignInWithCodeArgs = {
  code: Scalars["String"];
};

export type MutationSignUpArgs = {
  data: SignUpInput;
};

export type MutationStripeCheckoutCreateArgs = {
  priceId: Scalars["String"];
};

export type MutationSwiperConfigurationUpdateArgs = {
  data: SwiperConfigurationUpdateInput;
  swiperConfigurationId: Scalars["String"];
};

export type MutationTriviaAnswerOptionCreateArgs = {
  data: TriviaAnswerOptionCreateInput;
  triviaQuestionId: Scalars["String"];
};

export type MutationTriviaAnswerOptionDeleteArgs = {
  triviaAnswerOptionId: Scalars["String"];
};

export type MutationTriviaAnswerOptionUpdateArgs = {
  data: TriviaAnswerOptionUpdateInput;
  triviaAnswerOptionId: Scalars["String"];
};

export type MutationTriviaConfigurationUpdateArgs = {
  data: TriviaConfigurationUpdateInput;
  triviaConfigurationId: Scalars["String"];
};

export type MutationTriviaQuestionCreateArgs = {
  data: TriviaQuestionCreateInput;
  triviaConfigurationId: Scalars["String"];
};

export type MutationTriviaQuestionDeleteArgs = {
  triviaQuestionId: Scalars["String"];
};

export type MutationTriviaQuestionUpdateArgs = {
  data: TriviaQuestionUpdateInput;
  triviaQuestionId: Scalars["String"];
};

export type MutationUserDeleteArgs = {
  userId: Scalars["String"];
};

export type MutationUserGrantAdminArgs = {
  userId: Scalars["String"];
};

export type MutationUserInviteArgs = {
  email: Scalars["String"];
};

export type MutationUserRequestPasswordResetArgs = {
  email: Scalars["String"];
  token: Scalars["String"];
};

export type MutationUserResendInvitationArgs = {
  userId: Scalars["String"];
};

export type MutationUserRevokeAdminArgs = {
  userId: Scalars["String"];
};

export type MutationUserUpdateArgs = {
  data: UserUpdateInput;
};

export type MutationUserUpdatePasswordArgs = {
  data: UserUpdatePasswordInput;
};

export type MutationVideoCreateArgs = {
  input: CreateVideoInput;
};

export type MutationVideoDeleteArgs = {
  videoId: Scalars["UUID"];
};

export type MutationWelcomePageUpdateArgs = {
  data: WelcomePageUpdateInput;
  welcomePageId: Scalars["String"];
};

export type MutationWordleConfigurationUpdateArgs = {
  data: WordleConfigurationUpdateInput;
  wordleConfigurationId: Scalars["String"];
};

export type MutationWordopolisConfigurationUpdateArgs = {
  data: WordopolisConfigurationUpdateInput;
  wordopolisConfigurationId: Scalars["String"];
};

export type Organization = {
  __typename?: "Organization";
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  manuallyManagedPlan?: Maybe<PlanName>;
  name: Scalars["String"];
  subscription?: Maybe<Subscription>;
  updatedAt: Scalars["DateTime"];
};

/** The name of the Plan */
export enum PlanName {
  Admin = "ADMIN",
  Basic = "BASIC",
  Core = "CORE",
  Professional = "PROFESSIONAL",
  Teams = "TEAMS",
}

export type PortalSession = {
  __typename?: "PortalSession";
  url: Scalars["String"];
};

export type Project = {
  __typename?: "Project";
  createdAt: Scalars["DateTime"];
  directory: Directory;
  games: Array<Game>;
  hideDatagameBranding: Scalars["Boolean"];
  hideSummaryPage: Scalars["Boolean"];
  id: Scalars["UUID"];
  leaderboardConfiguration: LeaderboardConfiguration;
  name: Scalars["String"];
  organization: Organization;
  public: Scalars["Boolean"];
  showLeaderboard: Scalars["Boolean"];
  status: ProjectStatus;
  updatedAt: Scalars["DateTime"];
};

export type ProjectPublic = {
  __typename?: "ProjectPublic";
  createdAt: Scalars["DateTime"];
  directory: Directory;
  games: Array<Game>;
  hideDatagameBranding: Scalars["Boolean"];
  hideSummaryPage: Scalars["Boolean"];
  id: Scalars["UUID"];
  leaderboardConfiguration: LeaderboardConfiguration;
  name: Scalars["String"];
  public: Scalars["Boolean"];
  showLeaderboard: Scalars["Boolean"];
  status: ProjectStatus;
  updatedAt: Scalars["DateTime"];
};

/** The status of a project */
export enum ProjectStatus {
  Closed = "CLOSED",
  Draft = "DRAFT",
  Live = "LIVE",
}

export type ProjectUpdateInput = {
  directoryId?: InputMaybe<Scalars["UUID"]>;
  hideDatagameBranding?: InputMaybe<Scalars["Boolean"]>;
  hideSummaryPage?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  public?: InputMaybe<Scalars["Boolean"]>;
  showLeaderboard?: InputMaybe<Scalars["Boolean"]>;
  status?: InputMaybe<Scalars["String"]>;
};

export type Query = {
  __typename?: "Query";
  directory: Directory;
  directoryList: Array<Directory>;
  game: Game;
  gameAll: Array<Game>;
  gameIsNicknameTaken: Scalars["Boolean"];
  gameLeaderboard: Array<LeaderboardRow>;
  gameLeaderboardResponseRank?: Maybe<LeaderboardRow>;
  libraryImageAll: Array<LibraryImage>;
  project: Project;
  projectIsNicknameTaken: Scalars["Boolean"];
  projectLeaderboard: Array<LeaderboardRow>;
  projectLeaderboardByNickname: LeaderboardRow;
  projectList: Array<Project>;
  projectPublic: ProjectPublic;
  quickRankGameResults: QuickRankGameResults;
  quickRankReport: QuickRankReport;
  rankifierGameResults: RankifierGameResults;
  rankifierReport: RankifierReport;
  swiperGameResults: SwiperGameResults;
  swiperReport: SwiperReport;
  triviaReport: TriviaReport;
  triviaResponseReport: TriviaResponseReport;
  user: User;
  userList: Array<User>;
  userMe: User;
  wordleReport: WordleReport;
  wordopolisReport: WordopolisReport;
};

export type QueryDirectoryArgs = {
  directoryId: Scalars["UUID"];
};

export type QueryGameArgs = {
  gameId: Scalars["String"];
};

export type QueryGameAllArgs = {
  projectId: Scalars["String"];
};

export type QueryGameIsNicknameTakenArgs = {
  gameId: Scalars["String"];
  nickname: Scalars["String"];
};

export type QueryGameLeaderboardArgs = {
  gameId: Scalars["String"];
};

export type QueryGameLeaderboardResponseRankArgs = {
  responseId?: InputMaybe<Scalars["String"]>;
  responseSlug?: InputMaybe<Scalars["Int"]>;
};

export type QueryProjectArgs = {
  projectId: Scalars["UUID"];
};

export type QueryProjectIsNicknameTakenArgs = {
  nickname: Scalars["String"];
  projectId: Scalars["String"];
};

export type QueryProjectLeaderboardArgs = {
  projectId: Scalars["String"];
};

export type QueryProjectLeaderboardByNicknameArgs = {
  nickname: Scalars["String"];
  projectId: Scalars["String"];
};

export type QueryProjectPublicArgs = {
  projectId: Scalars["UUID"];
};

export type QueryQuickRankGameResultsArgs = {
  responseId: Scalars["String"];
};

export type QueryQuickRankReportArgs = {
  gameId: Scalars["String"];
};

export type QueryRankifierGameResultsArgs = {
  responseId: Scalars["String"];
};

export type QueryRankifierReportArgs = {
  gameId: Scalars["String"];
};

export type QuerySwiperGameResultsArgs = {
  responseId: Scalars["String"];
};

export type QuerySwiperReportArgs = {
  gameId: Scalars["String"];
};

export type QueryTriviaReportArgs = {
  gameId: Scalars["String"];
};

export type QueryTriviaResponseReportArgs = {
  gameId: Scalars["String"];
  responseId: Scalars["String"];
};

export type QueryUserArgs = {
  userId: Scalars["UUID"];
};

export type QueryWordleReportArgs = {
  gameId: Scalars["String"];
};

export type QueryWordopolisReportArgs = {
  gameId: Scalars["String"];
};

export type QuickRankAttributeResult = {
  __typename?: "QuickRankAttributeResult";
  attribute: Attribute;
  avgRank: Scalars["Float"];
  avgRating: Scalars["Float"];
};

export type QuickRankConfiguration = {
  __typename?: "QuickRankConfiguration";
  attributes: Array<Attribute>;
  createdAt: Scalars["DateTime"];
  feedbackPage: FeedbackPage;
  gameResults: GameResultsConfiguration;
  gameVisuals: GameVisuals;
  id: Scalars["UUID"];
  leaderboardConfiguration: LeaderboardConfiguration;
  mode: QuickRankMode;
  question: Scalars["String"];
  questionColor: Scalars["String"];
  ratingLeftLabel: Scalars["String"];
  ratingQuestion: Scalars["String"];
  ratingRightLabel: Scalars["String"];
  ratingScaleSize: Scalars["Int"];
  showFeedbackPage: Scalars["Boolean"];
  showGameResults: Scalars["Boolean"];
  showLeaderboard: Scalars["Boolean"];
  showRatingScale: Scalars["Boolean"];
  showWelcomePage: Scalars["Boolean"];
  shuffleCards: Scalars["Boolean"];
  updatedAt: Scalars["DateTime"];
  welcomePage: WelcomePage;
};

export type QuickRankConfigurationUpdateInput = {
  mode?: InputMaybe<QuickRankMode>;
  question?: InputMaybe<Scalars["String"]>;
  questionColor?: InputMaybe<Scalars["String"]>;
  ratingLeftLabel?: InputMaybe<Scalars["String"]>;
  ratingQuestion?: InputMaybe<Scalars["String"]>;
  ratingRightLabel?: InputMaybe<Scalars["String"]>;
  ratingScaleSize?: InputMaybe<Scalars["Int"]>;
  showFeedbackPage?: InputMaybe<Scalars["Boolean"]>;
  showGameResults?: InputMaybe<Scalars["Boolean"]>;
  showLeaderboard?: InputMaybe<Scalars["Boolean"]>;
  showRatingScale?: InputMaybe<Scalars["Boolean"]>;
  showWelcomePage?: InputMaybe<Scalars["Boolean"]>;
  shuffleCards?: InputMaybe<Scalars["Boolean"]>;
};

export type QuickRankGameResults = {
  __typename?: "QuickRankGameResults";
  match: Scalars["Int"];
};

/** The mode of the quick rank game */
export enum QuickRankMode {
  AllUp = "ALL_UP",
  Optimized = "OPTIMIZED",
  TwoPickN = "TWO_PICK_N",
}

export type QuickRankReport = {
  __typename?: "QuickRankReport";
  attributeResults: Array<QuickRankAttributeResult>;
  responseCount: Scalars["Int"];
  totalAttributes: Scalars["Int"];
};

export type QuickRankScore = {
  __typename?: "QuickRankScore";
  attribute: Attribute;
  count: Scalars["Int"];
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  rank: Scalars["Int"];
  rating: Scalars["Int"];
  updatedAt: Scalars["DateTime"];
};

export type QuickRankScoreInput = {
  attributeId: Scalars["UUID"];
  count: Scalars["Int"];
  rank: Scalars["Int"];
  rating: Scalars["Int"];
};

export type RankifierAttributeResult = {
  __typename?: "RankifierAttributeResult";
  attribute: Attribute;
  minuses: Scalars["Int"];
  pluses: Scalars["Int"];
  score: Scalars["Int"];
};

export type RankifierConfiguration = {
  __typename?: "RankifierConfiguration";
  attributes: Array<Attribute>;
  cardsPerHand: Scalars["Int"];
  createdAt: Scalars["DateTime"];
  feedbackPage: FeedbackPage;
  gameResults: GameResultsConfiguration;
  gameVisuals: GameVisuals;
  id: Scalars["UUID"];
  leastQuestion: Scalars["String"];
  leastQuestionColor: Scalars["String"];
  mostQuestion: Scalars["String"];
  mostQuestionColor: Scalars["String"];
  numberOfRounds: Scalars["Int"];
  showFeedbackPage: Scalars["Boolean"];
  showGameResults: Scalars["Boolean"];
  showWelcomePage: Scalars["Boolean"];
  updatedAt: Scalars["DateTime"];
  welcomePage: WelcomePage;
};

export type RankifierConfigurationUpdateInput = {
  cardsPerHand?: InputMaybe<Scalars["Int"]>;
  leastQuestion?: InputMaybe<Scalars["String"]>;
  leastQuestionColor?: InputMaybe<Scalars["String"]>;
  mostQuestion?: InputMaybe<Scalars["String"]>;
  mostQuestionColor?: InputMaybe<Scalars["String"]>;
  numberOfRounds?: InputMaybe<Scalars["Int"]>;
  showFeedbackPage?: InputMaybe<Scalars["Boolean"]>;
  showGameResults?: InputMaybe<Scalars["Boolean"]>;
  showWelcomePage?: InputMaybe<Scalars["Boolean"]>;
};

export type RankifierGameResults = {
  __typename?: "RankifierGameResults";
  match: Scalars["Int"];
};

export type RankifierReport = {
  __typename?: "RankifierReport";
  attributeResults: Array<RankifierAttributeResult>;
  totalResponses: Scalars["Int"];
};

export type RankifierScore = {
  __typename?: "RankifierScore";
  attribute: Attribute;
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  minuses: Scalars["Int"];
  plusses: Scalars["Int"];
  score: Scalars["Int"];
  updatedAt: Scalars["DateTime"];
  zeroes: Scalars["Int"];
};

export type RankifierScoreInput = {
  attributeId: Scalars["UUID"];
  minuses: Scalars["Int"];
  plusses: Scalars["Int"];
  zeroes: Scalars["Int"];
};

export type Response = {
  __typename?: "Response";
  attributeScores: Array<AttributeScore>;
  createdAt: Scalars["DateTime"];
  endTime: Scalars["DateTime"];
  gameDuration?: Maybe<Scalars["Float"]>;
  id: Scalars["UUID"];
  nickname?: Maybe<Scalars["String"]>;
  quickRankScores: Array<QuickRankScore>;
  rankifierScores: Array<RankifierScore>;
  responseKind: ResponseKind;
  slug: Scalars["Int"];
  startTime: Scalars["DateTime"];
  updatedAt: Scalars["DateTime"];
};

export type ResponseCreateInput = {
  attributeScores?: InputMaybe<Array<AttributeScoreInput>>;
  endTime: Scalars["DateTime"];
  gameDuration?: InputMaybe<Scalars["Float"]>;
  gameId: Scalars["UUID"];
  nickname?: InputMaybe<Scalars["String"]>;
  quickRankScores?: InputMaybe<Array<QuickRankScoreInput>>;
  rankifierScores?: InputMaybe<Array<RankifierScoreInput>>;
  responseKind?: InputMaybe<ResponseKind>;
  startTime: Scalars["DateTime"];
  triviaScores?: InputMaybe<Array<TriviaScoreInput>>;
  wordleScores?: InputMaybe<Array<WordleScoreInput>>;
  wordopolisScores?: InputMaybe<Array<WordopolisScoreInput>>;
};

/** Type of a response */
export enum ResponseKind {
  Alchemer = "ALCHEMER",
  Hosted = "HOSTED",
}

export type ScoreResult = {
  __typename?: "ScoreResult";
  count: Scalars["Int"];
  score: Scalars["Int"];
};

export type SignInResponse = {
  __typename?: "SignInResponse";
  accessToken: Scalars["String"];
};

export type SignUpInput = {
  email: Scalars["String"];
  password: Scalars["String"];
  token: Scalars["String"];
};

export type Subscription = {
  __typename?: "Subscription";
  createdAt: Scalars["DateTime"];
  currency: Scalars["String"];
  customerId: Scalars["String"];
  id: Scalars["UUID"];
  organization: Organization;
  planId: Scalars["String"];
  planName: PlanName;
  price: Scalars["Float"];
  priceInterval: Scalars["String"];
  status: Scalars["String"];
  subscriptionId: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export type SwiperConfiguration = {
  __typename?: "SwiperConfiguration";
  attributes: Array<Attribute>;
  correctSwipeText: Scalars["String"];
  createdAt: Scalars["DateTime"];
  feedbackPage: FeedbackPage;
  gameResults: GameResultsConfiguration;
  gameVisuals: GameVisuals;
  id: Scalars["UUID"];
  incorrectSwipeText: Scalars["String"];
  leaderboardConfiguration: LeaderboardConfiguration;
  likeText: Scalars["String"];
  question: Scalars["String"];
  questionColor: Scalars["String"];
  showButtons: Scalars["Boolean"];
  showFeedbackPage: Scalars["Boolean"];
  showGameResults: Scalars["Boolean"];
  showLeaderboard: Scalars["Boolean"];
  showSwipeCorrectness: Scalars["Boolean"];
  showWelcomePage: Scalars["Boolean"];
  shuffleCards: Scalars["Boolean"];
  unlikeText: Scalars["String"];
  updatedAt: Scalars["DateTime"];
  welcomePage: WelcomePage;
};

export type SwiperConfigurationUpdateInput = {
  correctSwipeText?: InputMaybe<Scalars["String"]>;
  incorrectSwipeText?: InputMaybe<Scalars["String"]>;
  likeText?: InputMaybe<Scalars["String"]>;
  question?: InputMaybe<Scalars["String"]>;
  questionColor?: InputMaybe<Scalars["String"]>;
  showButtons?: InputMaybe<Scalars["Boolean"]>;
  showFeedbackPage?: InputMaybe<Scalars["Boolean"]>;
  showGameResults?: InputMaybe<Scalars["Boolean"]>;
  showLeaderboard?: InputMaybe<Scalars["Boolean"]>;
  showSwipeCorrectness?: InputMaybe<Scalars["Boolean"]>;
  showWelcomePage?: InputMaybe<Scalars["Boolean"]>;
  shuffleCards?: InputMaybe<Scalars["Boolean"]>;
  unlikeText?: InputMaybe<Scalars["String"]>;
};

export type SwiperGameResults = {
  __typename?: "SwiperGameResults";
  match: Scalars["Int"];
};

export type SwiperReport = {
  __typename?: "SwiperReport";
  attributeResults: Array<AttributeResult>;
  totalResponses: Scalars["Int"];
};

export type TriviaAnswerOption = {
  __typename?: "TriviaAnswerOption";
  createdAt: Scalars["DateTime"];
  explanation: Scalars["String"];
  id: Scalars["UUID"];
  isCorrect: Scalars["Boolean"];
  points: Scalars["Int"];
  text: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export type TriviaAnswerOptionCreateInput = {
  explanation?: InputMaybe<Scalars["String"]>;
  isCorrect?: InputMaybe<Scalars["Boolean"]>;
  points: Scalars["Int"];
  text: Scalars["String"];
};

export type TriviaAnswerOptionUpdateInput = {
  explanation?: InputMaybe<Scalars["String"]>;
  isCorrect?: InputMaybe<Scalars["Boolean"]>;
  points?: InputMaybe<Scalars["Int"]>;
  text?: InputMaybe<Scalars["String"]>;
};

export type TriviaConfiguration = {
  __typename?: "TriviaConfiguration";
  createdAt: Scalars["DateTime"];
  feedbackPage: FeedbackPage;
  gameVisuals: GameVisuals;
  id: Scalars["UUID"];
  leaderboardConfiguration: LeaderboardConfiguration;
  questions: Array<TriviaQuestion>;
  showFeedbackPage: Scalars["Boolean"];
  showLeaderboard: Scalars["Boolean"];
  showWelcomePage: Scalars["Boolean"];
  timeToCompleteInSeconds: Scalars["Int"];
  updatedAt: Scalars["DateTime"];
  welcomePage: WelcomePage;
};

export type TriviaConfigurationUpdateInput = {
  showFeedbackPage?: InputMaybe<Scalars["Boolean"]>;
  showLeaderboard?: InputMaybe<Scalars["Boolean"]>;
  showWelcomePage?: InputMaybe<Scalars["Boolean"]>;
  timeToCompleteInSeconds?: InputMaybe<Scalars["Int"]>;
};

export type TriviaQuestion = {
  __typename?: "TriviaQuestion";
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  image?: Maybe<LibraryImage>;
  kind: TriviaQuestionType;
  options: Array<TriviaAnswerOption>;
  question: Scalars["String"];
  timeToCompleteInSeconds: Scalars["Int"];
  updatedAt: Scalars["DateTime"];
  video?: Maybe<Video>;
};

export type TriviaQuestionCreateInput = {
  options?: InputMaybe<Array<TriviaAnswerOptionCreateInput>>;
  question: Scalars["String"];
  timeToCompleteInSeconds?: InputMaybe<Scalars["Int"]>;
};

export type TriviaQuestionScore = {
  __typename?: "TriviaQuestionScore";
  answer: TriviaAnswerOption;
  isCorrect: Scalars["Boolean"];
  question: TriviaQuestion;
};

/** Type of a question */
export enum TriviaQuestionType {
  SingleChoice = "SINGLE_CHOICE",
}

export type TriviaQuestionUpdateInput = {
  imageId?: InputMaybe<Scalars["String"]>;
  question?: InputMaybe<Scalars["String"]>;
  timeToCompleteInSeconds?: InputMaybe<Scalars["Int"]>;
  videoId?: InputMaybe<Scalars["String"]>;
};

export type TriviaReport = {
  __typename?: "TriviaReport";
  questionResults: Array<TriviaReportLine>;
  totalResponses: Scalars["Int"];
};

export type TriviaReportLine = {
  __typename?: "TriviaReportLine";
  question: TriviaQuestion;
  scores: Array<AggregatedTriviaQuestionScore>;
};

export type TriviaResponseReport = {
  __typename?: "TriviaResponseReport";
  numberOfCorrectAnswers: Scalars["Int"];
  numberOfwrongAnswers: Scalars["Int"];
  scores: Array<TriviaQuestionScore>;
};

export type TriviaScoreInput = {
  timeInMs: Scalars["Int"];
  triviaAnswerOptionId: Scalars["UUID"];
  triviaQuestionId: Scalars["UUID"];
};

export type UpdateDirectoryInput = {
  name?: InputMaybe<Scalars["String"]>;
  parentDirectoryId?: InputMaybe<Scalars["UUID"]>;
};

export type User = {
  __typename?: "User";
  createdAt: Scalars["DateTime"];
  email: Scalars["String"];
  emailVerifiedAt?: Maybe<Scalars["DateTime"]>;
  firstName: Scalars["String"];
  id: Scalars["UUID"];
  lastName: Scalars["String"];
  organization: Organization;
  organizationId: Scalars["UUID"];
  role: UserRole;
  updatedAt: Scalars["DateTime"];
};

/** User roles */
export enum UserRole {
  Admin = "ADMIN",
  User = "USER",
}

export type UserUpdateInput = {
  companyName?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
};

export type UserUpdatePasswordInput = {
  newPassword: Scalars["String"];
  oldPassword?: InputMaybe<Scalars["String"]>;
};

export type Video = {
  __typename?: "Video";
  createdAt: Scalars["DateTime"];
  extension: Scalars["String"];
  id: Scalars["UUID"];
  name: Scalars["String"];
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars["UUID"]>;
  updatedAt: Scalars["DateTime"];
  url: Scalars["String"];
};

export type WelcomePage = {
  __typename?: "WelcomePage";
  backgroundColor: Scalars["String"];
  backgroundImage?: Maybe<LibraryImage>;
  button: Scalars["String"];
  buttonColor: Scalars["String"];
  createdAt: Scalars["DateTime"];
  id: Scalars["UUID"];
  logo?: Maybe<LibraryImage>;
  posterImage?: Maybe<LibraryImage>;
  subheading: Scalars["String"];
  subheadingColor: Scalars["String"];
  tagLine: Scalars["String"];
  tagLineColor: Scalars["String"];
  updatedAt: Scalars["DateTime"];
  welcomeLine: Scalars["String"];
  welcomeLineColor: Scalars["String"];
};

export type WelcomePageUpdateInput = {
  backgroundColor?: InputMaybe<Scalars["String"]>;
  backgroundImageId?: InputMaybe<Scalars["UUID"]>;
  button?: InputMaybe<Scalars["String"]>;
  buttonColor?: InputMaybe<Scalars["String"]>;
  logoId?: InputMaybe<Scalars["UUID"]>;
  posterImageId?: InputMaybe<Scalars["UUID"]>;
  subheading?: InputMaybe<Scalars["String"]>;
  subheadingColor?: InputMaybe<Scalars["String"]>;
  tagLine?: InputMaybe<Scalars["String"]>;
  tagLineColor?: InputMaybe<Scalars["String"]>;
  welcomeLine?: InputMaybe<Scalars["String"]>;
  welcomeLineColor?: InputMaybe<Scalars["String"]>;
};

export type WordleConfiguration = {
  __typename?: "WordleConfiguration";
  createdAt: Scalars["DateTime"];
  feedbackPage: FeedbackPage;
  gameVisuals: GameVisuals;
  id: Scalars["UUID"];
  leaderboardConfiguration: LeaderboardConfiguration;
  numberOfTries: Scalars["Int"];
  showFeedbackPage: Scalars["Boolean"];
  showLeaderboard: Scalars["Boolean"];
  showWelcomePage: Scalars["Boolean"];
  updatedAt: Scalars["DateTime"];
  welcomePage: WelcomePage;
  word: Scalars["String"];
};

export type WordleConfigurationUpdateInput = {
  numberOfTries?: InputMaybe<Scalars["Int"]>;
  showFeedbackPage?: InputMaybe<Scalars["Boolean"]>;
  showLeaderboard?: InputMaybe<Scalars["Boolean"]>;
  showWelcomePage?: InputMaybe<Scalars["Boolean"]>;
  word?: InputMaybe<Scalars["String"]>;
};

export type WordleReport = {
  __typename?: "WordleReport";
  scoreResults: Array<ScoreResult>;
  totalResponses: Scalars["Int"];
};

export type WordleScoreInput = {
  score: Scalars["Int"];
};

export type WordopolisAttributeResult = {
  __typename?: "WordopolisAttributeResult";
  attribute: Attribute;
  avgRating: Scalars["Float"];
  avgScore: Scalars["Float"];
};

export type WordopolisConfiguration = {
  __typename?: "WordopolisConfiguration";
  attributes: Array<Attribute>;
  board: Array<Scalars["String"]>;
  boardBackgroundColor: Scalars["String"];
  boardBackgroundImage?: Maybe<LibraryImage>;
  boardColor: Scalars["String"];
  createdAt: Scalars["DateTime"];
  difficulty: WordopolisDifficulty;
  gameVisuals: GameVisuals;
  id: Scalars["UUID"];
  isTimeLimited: Scalars["Boolean"];
  leaderboardConfiguration: LeaderboardConfiguration;
  limitedTime: Scalars["Int"];
  name: Scalars["String"];
  numberOfColumns: Scalars["Int"];
  numberOfRows: Scalars["Int"];
  ratingLeftLabel: Scalars["String"];
  ratingQuestion: Scalars["String"];
  ratingRightLabel: Scalars["String"];
  ratingScaleSize: Scalars["Int"];
  showFeedbackPage: Scalars["Boolean"];
  showLeaderboard: Scalars["Boolean"];
  showRatingScale: Scalars["Boolean"];
  showWelcomePage: Scalars["Boolean"];
  showWords: Scalars["Boolean"];
  startText: Scalars["String"];
  topic: Scalars["String"];
  updatedAt: Scalars["DateTime"];
  welcomePage: WelcomePage;
  words: Array<WordopolisWordDefinition>;
};

export type WordopolisConfigurationUpdateInput = {
  boardBackgroundColor?: InputMaybe<Scalars["String"]>;
  boardBackgroundImageId?: InputMaybe<Scalars["UUID"]>;
  boardColor?: InputMaybe<Scalars["String"]>;
  difficulty?: InputMaybe<WordopolisDifficulty>;
  isTimeLimited?: InputMaybe<Scalars["Boolean"]>;
  limitedTime?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  numberOfColumns?: InputMaybe<Scalars["Int"]>;
  numberOfRows?: InputMaybe<Scalars["Int"]>;
  ratingLeftLabel?: InputMaybe<Scalars["String"]>;
  ratingQuestion?: InputMaybe<Scalars["String"]>;
  ratingRightLabel?: InputMaybe<Scalars["String"]>;
  ratingScaleSize?: InputMaybe<Scalars["Int"]>;
  showFeedbackPage?: InputMaybe<Scalars["Boolean"]>;
  showLeaderboard?: InputMaybe<Scalars["Boolean"]>;
  showRatingScale?: InputMaybe<Scalars["Boolean"]>;
  showWelcomePage?: InputMaybe<Scalars["Boolean"]>;
  showWords?: InputMaybe<Scalars["Boolean"]>;
  startText?: InputMaybe<Scalars["String"]>;
  topic?: InputMaybe<Scalars["String"]>;
};

/** Difficulty of the Wordopolis game */
export enum WordopolisDifficulty {
  Easy = "EASY",
  Hard = "HARD",
  Medium = "MEDIUM",
}

export type WordopolisReport = {
  __typename?: "WordopolisReport";
  attributeResults: Array<WordopolisAttributeResult>;
  totalResponses: Scalars["Int"];
};

export type WordopolisScoreInput = {
  attributeId: Scalars["UUID"];
  rating: Scalars["Int"];
  score: Scalars["Int"];
  time: Scalars["Int"];
};

export type WordopolisWordDefinition = {
  __typename?: "WordopolisWordDefinition";
  direction: Scalars["String"];
  startX: Scalars["Int"];
  startY: Scalars["Int"];
  value: Scalars["String"];
};

export type ProjectFieldsFragment = {
  __typename?: "Project";
  id: string;
  name: string;
  status: ProjectStatus;
  createdAt: string;
  updatedAt: string;
  hideDatagameBranding: boolean;
  hideSummaryPage: boolean;
  showLeaderboard: boolean;
  directory: { __typename?: "Directory"; id: string };
  leaderboardConfiguration: {
    __typename?: "LeaderboardConfiguration";
    id: string;
    nicknameQuestion: string;
    nicknameQuestionColor: string;
    header: string;
    headerColor: string;
    nicknameLabel: string;
    numberOfPlayers: number;
    showTimer: boolean;
    subheader: string;
    subheaderColor: string;
    tableNicknameLabel: string;
    tableScoreLabel: string;
    tableTimeLabel: string;
    backgroundColor: string;
    backgroundImage?: { __typename?: "LibraryImage"; id: string; name: string; url: string } | null;
  };
} & { " $fragmentName"?: "ProjectFieldsFragment" };

export type VideoFieldsFragment = { __typename?: "Video"; id: string; name: string; extension: string; url: string } & {
  " $fragmentName"?: "VideoFieldsFragment";
};

export type Directory_FieldsFragment = {
  __typename?: "Directory";
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  default: boolean;
} & { " $fragmentName"?: "Directory_FieldsFragment" };

export type Project_FieldsFragment = {
  __typename?: "Project";
  id: string;
  name: string;
  status: ProjectStatus;
  createdAt: string;
  updatedAt: string;
  directory: {
    __typename?: "Directory";
    id: string;
    name: string;
    createdAt: string;
    updatedAt: string;
    default: boolean;
  };
} & { " $fragmentName"?: "Project_FieldsFragment" };

export type User_FieldsFragment = {
  __typename?: "User";
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  role: UserRole;
  emailVerifiedAt?: string | null;
  createdAt: string;
  updatedAt: string;
} & { " $fragmentName"?: "User_FieldsFragment" };

export type Library_Image_FieldsFragment = {
  __typename?: "LibraryImage";
  id: string;
  name: string;
  extension: string;
  url: string;
  organizationId?: string | null;
  createdAt: string;
  updatedAt: string;
  kind: LibraryImageKind;
  isGlobal: boolean;
} & { " $fragmentName"?: "Library_Image_FieldsFragment" };

export type GameFieldsFragment = {
  __typename?: "Game";
  id: string;
  name: string;
  kind: GameKind;
  createdAt: string;
  updatedAt: string;
} & { " $fragmentName"?: "GameFieldsFragment" };

export type SwiperConfigurationFieldsFragment = {
  __typename?: "SwiperConfiguration";
  id: string;
  createdAt: string;
  updatedAt: string;
  shuffleCards: boolean;
  showGameResults: boolean;
  showWelcomePage: boolean;
  question: string;
  questionColor: string;
  showFeedbackPage: boolean;
  showButtons: boolean;
  showLeaderboard: boolean;
  showSwipeCorrectness: boolean;
  correctSwipeText: string;
  incorrectSwipeText: string;
  likeText: string;
  unlikeText: string;
  leaderboardConfiguration: {
    __typename?: "LeaderboardConfiguration";
    id: string;
    nicknameQuestion: string;
    nicknameQuestionColor: string;
    header: string;
    headerColor: string;
    nicknameLabel: string;
    numberOfPlayers: number;
    showTimer: boolean;
    subheader: string;
    subheaderColor: string;
    tableNicknameLabel: string;
    tableScoreLabel: string;
    tableTimeLabel: string;
    backgroundColor: string;
    backgroundImage?: { __typename?: "LibraryImage"; id: string; name: string; url: string } | null;
  };
} & { " $fragmentName"?: "SwiperConfigurationFieldsFragment" };

export type TriviaAnswerOptionFieldsFragment = {
  __typename?: "TriviaAnswerOption";
  id: string;
  createdAt: string;
  updatedAt: string;
  text: string;
  isCorrect: boolean;
  points: number;
  explanation: string;
} & { " $fragmentName"?: "TriviaAnswerOptionFieldsFragment" };

export type TriviaQuestionFieldsFragment = {
  __typename?: "TriviaQuestion";
  id: string;
  createdAt: string;
  updatedAt: string;
  kind: TriviaQuestionType;
  question: string;
  timeToCompleteInSeconds: number;
  image?: { __typename?: "LibraryImage"; id: string; name: string; url: string } | null;
  video?: ({ __typename?: "Video" } & { " $fragmentRefs"?: { VideoFieldsFragment: VideoFieldsFragment } }) | null;
  options: Array<
    { __typename?: "TriviaAnswerOption" } & {
      " $fragmentRefs"?: { TriviaAnswerOptionFieldsFragment: TriviaAnswerOptionFieldsFragment };
    }
  >;
} & { " $fragmentName"?: "TriviaQuestionFieldsFragment" };

export type TriviaConfigurationFieldsFragment = {
  __typename?: "TriviaConfiguration";
  id: string;
  timeToCompleteInSeconds: number;
  showFeedbackPage: boolean;
  showWelcomePage: boolean;
  showLeaderboard: boolean;
  questions: Array<
    { __typename?: "TriviaQuestion" } & {
      " $fragmentRefs"?: { TriviaQuestionFieldsFragment: TriviaQuestionFieldsFragment };
    }
  >;
  gameVisuals: {
    __typename?: "GameVisuals";
    id: string;
    themeColor: string;
    showIcons: boolean;
    iconSet: IconSet;
    backgroundColor: string;
    backgroundPositiveColor: string;
    backgroundNegativeColor: string;
    positiveColor: string;
    negativeColor: string;
    cardBackImage?: { __typename?: "LibraryImage"; id: string; name: string; url: string } | null;
    backgroundImage?: { __typename?: "LibraryImage"; id: string; name: string; url: string } | null;
  };
  welcomePage: {
    __typename?: "WelcomePage";
    id: string;
    welcomeLine: string;
    welcomeLineColor: string;
    subheading: string;
    subheadingColor: string;
    tagLine: string;
    tagLineColor: string;
    button: string;
    buttonColor: string;
    backgroundColor: string;
    backgroundImage?: { __typename?: "LibraryImage"; id: string; name: string; url: string } | null;
    logo?: { __typename?: "LibraryImage"; id: string; name: string; url: string } | null;
  };
  feedbackPage: {
    __typename?: "FeedbackPage";
    id: string;
    title: string;
    titleColor: string;
    instructionLine: string;
    instructionLineColor: string;
    textBoxPrompt: string;
    textBoxPromptColor: string;
    submitButton: string;
    submitButtonColor: string;
    backgroundColor: string;
    backgroundImage?: { __typename?: "LibraryImage"; id: string; name: string; url: string } | null;
  };
} & { " $fragmentName"?: "TriviaConfigurationFieldsFragment" };

export type AttributeFieldsFragment = {
  __typename?: "Attribute";
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  customText?: string | null;
  points: number;
  isCorrect: boolean;
  showImageAndText: boolean;
  customRatingQuestion?: {
    __typename?: "CustomRatingQuestion";
    question: string;
    leftLabel: string;
    rightLabel: string;
    scaleSize: number;
  } | null;
  image?: { __typename?: "LibraryImage"; id: string; name: string; url: string } | null;
} & { " $fragmentName"?: "AttributeFieldsFragment" };

export type WordleConfigurationFieldsFragment = {
  __typename?: "WordleConfiguration";
  id: string;
  createdAt: string;
  updatedAt: string;
  word: string;
  numberOfTries: number;
  showWelcomePage: boolean;
  showFeedbackPage: boolean;
  showLeaderboard: boolean;
  leaderboardConfiguration: {
    __typename?: "LeaderboardConfiguration";
    id: string;
    nicknameQuestion: string;
    nicknameQuestionColor: string;
    header: string;
    headerColor: string;
    nicknameLabel: string;
    numberOfPlayers: number;
    showTimer: boolean;
    subheader: string;
    subheaderColor: string;
    tableNicknameLabel: string;
    tableScoreLabel: string;
    tableTimeLabel: string;
    backgroundColor: string;
    backgroundImage?: { __typename?: "LibraryImage"; id: string; name: string; url: string } | null;
  };
  gameVisuals: {
    __typename?: "GameVisuals";
    id: string;
    themeColor: string;
    showIcons: boolean;
    iconSet: IconSet;
    backgroundColor: string;
    backgroundPositiveColor: string;
    backgroundNegativeColor: string;
    positiveColor: string;
    negativeColor: string;
    presentLetter: string;
    correctLetter: string;
    defaultLetter: string;
    absentLetter: string;
    cardBackImage?: { __typename?: "LibraryImage"; id: string; name: string; url: string } | null;
    backgroundImage?: { __typename?: "LibraryImage"; id: string; name: string; url: string } | null;
  };
} & { " $fragmentName"?: "WordleConfigurationFieldsFragment" };

export type AttributeCreateMutationVariables = Exact<{
  data: AttributeCreateInput;
  gameId: Scalars["String"];
}>;

export type AttributeCreateMutation = {
  __typename?: "Mutation";
  attributeCreate: { __typename?: "Attribute" } & {
    " $fragmentRefs"?: { AttributeFieldsFragment: AttributeFieldsFragment };
  };
};

export type AttributeDeleteMutationVariables = Exact<{
  attributeId: Scalars["String"];
}>;

export type AttributeDeleteMutation = {
  __typename?: "Mutation";
  attributeDelete: { __typename?: "Attribute" } & {
    " $fragmentRefs"?: { AttributeFieldsFragment: AttributeFieldsFragment };
  };
};

export type AttributeUpdateMutationVariables = Exact<{
  data: AttributeUpdateInput;
  attributeId: Scalars["String"];
}>;

export type AttributeUpdateMutation = {
  __typename?: "Mutation";
  attributeUpdate: { __typename?: "Attribute" } & {
    " $fragmentRefs"?: { AttributeFieldsFragment: AttributeFieldsFragment };
  };
};

export type DirectoryCreateMutationVariables = Exact<{
  data: CreateDirectoryInput;
}>;

export type DirectoryCreateMutation = {
  __typename?: "Mutation";
  directoryCreate: { __typename?: "Directory"; name: string };
};

export type DirectoryUpdateMutationVariables = Exact<{
  data: UpdateDirectoryInput;
  directoryId: Scalars["String"];
}>;

export type DirectoryUpdateMutation = {
  __typename?: "Mutation";
  directoryUpdate: {
    __typename?: "Directory";
    id: string;
    name: string;
    parentDirectory?: { __typename?: "Directory"; id: string } | null;
  };
};

export type DirectoryDeleteMutationVariables = Exact<{
  directoryId: Scalars["String"];
}>;

export type DirectoryDeleteMutation = {
  __typename?: "Mutation";
  directoryDelete: { __typename?: "Directory"; id: string };
};

export type GameCreateMutationVariables = Exact<{
  data: GameCreateInput;
}>;

export type GameCreateMutation = {
  __typename?: "Mutation";
  gameCreate: {
    __typename?: "Game";
    configuration:
      | { __typename?: "QuickRankConfiguration"; id: string }
      | { __typename?: "RankifierConfiguration"; id: string }
      | { __typename?: "SwiperConfiguration"; id: string }
      | { __typename?: "TriviaConfiguration" }
      | { __typename?: "WordleConfiguration" }
      | { __typename?: "WordopolisConfiguration"; id: string };
  } & { " $fragmentRefs"?: { GameFieldsFragment: GameFieldsFragment } };
};

export type GameDeleteMutationVariables = Exact<{
  gameId: Scalars["String"];
}>;

export type GameDeleteMutation = {
  __typename?: "Mutation";
  gameDelete: { __typename?: "Game" } & { " $fragmentRefs"?: { GameFieldsFragment: GameFieldsFragment } };
};

export type GameUpdateMutationVariables = Exact<{
  gameId: Scalars["String"];
  data: GameUpdateInput;
}>;

export type GameUpdateMutation = {
  __typename?: "Mutation";
  gameUpdate: { __typename?: "Game" } & { " $fragmentRefs"?: { GameFieldsFragment: GameFieldsFragment } };
};

export type SwiperConfigurationUpdateMutationVariables = Exact<{
  swiperConfigurationId: Scalars["String"];
  data: SwiperConfigurationUpdateInput;
}>;

export type SwiperConfigurationUpdateMutation = {
  __typename?: "Mutation";
  swiperConfigurationUpdate: { __typename?: "SwiperConfiguration" } & {
    " $fragmentRefs"?: { SwiperConfigurationFieldsFragment: SwiperConfigurationFieldsFragment };
  };
};

export type LeaderboardConfigurationUpdateMutationVariables = Exact<{
  data: LeaderboardConfigurationUpdateInput;
  leaderboardConfigurationId: Scalars["String"];
}>;

export type LeaderboardConfigurationUpdateMutation = {
  __typename?: "Mutation";
  leaderboardConfigurationUpdate: {
    __typename?: "LeaderboardConfiguration";
    nicknameQuestion: string;
    nicknameQuestionColor: string;
    header: string;
    headerColor: string;
    nicknameLabel: string;
    numberOfPlayers: number;
    showTimer: boolean;
    subheader: string;
    subheaderColor: string;
    tableNicknameLabel: string;
    tableScoreLabel: string;
    tableTimeLabel: string;
    backgroundColor: string;
    backgroundImage?: { __typename?: "LibraryImage"; id: string; name: string; url: string } | null;
  };
};

export type RankifierConfigurationUpdateMutationVariables = Exact<{
  data: RankifierConfigurationUpdateInput;
  rankifierConfigurationId: Scalars["String"];
}>;

export type RankifierConfigurationUpdateMutation = {
  __typename?: "Mutation";
  rankifierConfigurationUpdate: {
    __typename?: "RankifierConfiguration";
    id: string;
    mostQuestion: string;
    leastQuestion: string;
    mostQuestionColor: string;
    leastQuestionColor: string;
    showWelcomePage: boolean;
    showGameResults: boolean;
    showFeedbackPage: boolean;
    cardsPerHand: number;
  };
};

export type QuickRankConfigurationUpdateMutationVariables = Exact<{
  data: QuickRankConfigurationUpdateInput;
  quickRankConfigurationId: Scalars["String"];
}>;

export type QuickRankConfigurationUpdateMutation = {
  __typename?: "Mutation";
  quickRankConfigurationUpdate: {
    __typename?: "QuickRankConfiguration";
    id: string;
    question: string;
    showWelcomePage: boolean;
    showGameResults: boolean;
    showFeedbackPage: boolean;
    shuffleCards: boolean;
  };
};

export type WordopolisConfigurationUpdateMutationVariables = Exact<{
  data: WordopolisConfigurationUpdateInput;
  wordopolisConfigurationId: Scalars["String"];
}>;

export type WordopolisConfigurationUpdateMutation = {
  __typename?: "Mutation";
  wordopolisConfigurationUpdate: {
    __typename?: "WordopolisConfiguration";
    id: string;
    topic: string;
    difficulty: WordopolisDifficulty;
    numberOfRows: number;
    numberOfColumns: number;
    startText: string;
    showWords: boolean;
    isTimeLimited: boolean;
    limitedTime: number;
    showWelcomePage: boolean;
    showRatingScale: boolean;
    ratingQuestion: string;
    ratingLeftLabel: string;
    ratingRightLabel: string;
    ratingScaleSize: number;
    boardBackgroundColor: string;
    boardColor: string;
    showLeaderboard: boolean;
    boardBackgroundImage?: { __typename?: "LibraryImage"; id: string } | null;
  };
};

export type WordopolisConfigurationUpdateWithBoardMutationVariables = Exact<{
  data: WordopolisConfigurationUpdateInput;
  wordopolisConfigurationId: Scalars["String"];
}>;

export type WordopolisConfigurationUpdateWithBoardMutation = {
  __typename?: "Mutation";
  wordopolisConfigurationUpdate: {
    __typename?: "WordopolisConfiguration";
    id: string;
    topic: string;
    difficulty: WordopolisDifficulty;
    numberOfRows: number;
    numberOfColumns: number;
    startText: string;
    showWords: boolean;
    isTimeLimited: boolean;
    limitedTime: number;
    showWelcomePage: boolean;
    showRatingScale: boolean;
    ratingQuestion: string;
    ratingLeftLabel: string;
    ratingRightLabel: string;
    ratingScaleSize: number;
    boardBackgroundColor: string;
    boardColor: string;
    board: Array<string>;
    showLeaderboard: boolean;
    boardBackgroundImage?: { __typename?: "LibraryImage"; id: string } | null;
  };
};

export type TriviaConfigurationUpdateMutationVariables = Exact<{
  data: TriviaConfigurationUpdateInput;
  triviaConfigurationId: Scalars["String"];
}>;

export type TriviaConfigurationUpdateMutation = {
  __typename?: "Mutation";
  triviaConfigurationUpdate: {
    __typename?: "TriviaConfiguration";
    id: string;
    showWelcomePage: boolean;
    showFeedbackPage: boolean;
  };
};

export type GameVisualsUpdateMutationVariables = Exact<{
  data: GameVisualsUpdateInput;
  gameVisualsId: Scalars["String"];
}>;

export type GameVisualsUpdateMutation = {
  __typename?: "Mutation";
  gameVisualsUpdate: {
    __typename?: "GameVisuals";
    themeColor: string;
    showIcons: boolean;
    iconSet: IconSet;
    backgroundColor: string;
    backgroundPositiveColor: string;
    backgroundNegativeColor: string;
    cardBackImage?: { __typename?: "LibraryImage"; id: string } | null;
    backgroundImage?: { __typename?: "LibraryImage"; id: string } | null;
  };
};

export type WelcomePageUpdateMutationVariables = Exact<{
  data: WelcomePageUpdateInput;
  welcomePageId: Scalars["String"];
}>;

export type WelcomePageUpdateMutation = {
  __typename?: "Mutation";
  welcomePageUpdate: {
    __typename?: "WelcomePage";
    welcomeLine: string;
    welcomeLineColor: string;
    subheading: string;
    subheadingColor: string;
    tagLine: string;
    tagLineColor: string;
    button: string;
    buttonColor: string;
    backgroundColor: string;
    backgroundImage?: { __typename?: "LibraryImage"; id: string } | null;
    logo?: { __typename?: "LibraryImage"; id: string } | null;
  };
};

export type FeedbackPageUpdateMutationVariables = Exact<{
  data: FeedbackPageUpdateInput;
  feedbackPageId: Scalars["String"];
}>;

export type FeedbackPageUpdateMutation = {
  __typename?: "Mutation";
  feedbackPageUpdate: {
    __typename?: "FeedbackPage";
    title: string;
    titleColor: string;
    instructionLine: string;
    instructionLineColor: string;
    textBoxPrompt: string;
    textBoxPromptColor: string;
    submitButton: string;
    submitButtonColor: string;
    backgroundColor: string;
    backgroundImage?: { __typename?: "LibraryImage"; id: string } | null;
  };
};

export type GameResultsUpdateMutationVariables = Exact<{
  data: GameResultsConfigurationUpdateInput;
  gameResultsId: Scalars["String"];
}>;

export type GameResultsUpdateMutation = {
  __typename?: "Mutation";
  gameResultsUpdate: {
    __typename?: "GameResultsConfiguration";
    id: string;
    scoreTitle_0_20: string;
    scoreTitle_21_40: string;
    scoreTitle_41_60: string;
    scoreTitle_61_80: string;
    scoreTitle_81_100: string;
    scoreTag_0_20: string;
    scoreTag_21_40: string;
    scoreTag_41_60: string;
    scoreTag_61_80: string;
    scoreTag_81_100: string;
    backgroundColor: string;
    backgroundImage?: { __typename?: "LibraryImage"; id: string } | null;
  };
};

export type WordleConfigurationUpdateMutationVariables = Exact<{
  data: WordleConfigurationUpdateInput;
  wordleConfigurationId: Scalars["String"];
}>;

export type WordleConfigurationUpdateMutation = {
  __typename?: "Mutation";
  wordleConfigurationUpdate: {
    __typename?: "WordleConfiguration";
    id: string;
    showWelcomePage: boolean;
    showFeedbackPage: boolean;
    word: string;
    numberOfTries: number;
  };
};

export type LibraryImageCreateMutationVariables = Exact<{
  input: CreateLibraryImagesInput;
}>;

export type LibraryImageCreateMutation = {
  __typename?: "Mutation";
  libraryImageCreate: Array<
    { __typename?: "LibraryImage" } & {
      " $fragmentRefs"?: { Library_Image_FieldsFragment: Library_Image_FieldsFragment };
    }
  >;
};

export type LibraryImageDeleteMutationVariables = Exact<{
  libraryImageId: Scalars["UUID"];
}>;

export type LibraryImageDeleteMutation = {
  __typename?: "Mutation";
  libraryImageDelete: { __typename?: "LibraryImage"; id: string };
};

export type ProjectCreateMutationVariables = Exact<{
  data: CreateProjectInput;
}>;

export type ProjectCreateMutation = {
  __typename?: "Mutation";
  projectCreate: { __typename?: "Project" } & { " $fragmentRefs"?: { ProjectFieldsFragment: ProjectFieldsFragment } };
};

export type ProjectUpdateMutationVariables = Exact<{
  data: ProjectUpdateInput;
  projectId: Scalars["String"];
}>;

export type ProjectUpdateMutation = {
  __typename?: "Mutation";
  projectUpdate: {
    __typename?: "Project";
    id: string;
    name: string;
    status: ProjectStatus;
    public: boolean;
    directory: { __typename?: "Directory"; id: string };
  };
};

export type ProjectDeleteMutationVariables = Exact<{
  projectId: Scalars["String"];
}>;

export type ProjectDeleteMutation = { __typename?: "Mutation"; projectDelete: { __typename?: "Project"; id: string } };

export type ProjectCopyMutationVariables = Exact<{
  projectId: Scalars["String"];
}>;

export type ProjectCopyMutation = { __typename?: "Mutation"; projectCopy: { __typename?: "Project"; id: string } };

export type ProjectDeleteDataMutationVariables = Exact<{
  projectId: Scalars["String"];
}>;

export type ProjectDeleteDataMutation = {
  __typename?: "Mutation";
  projectDeleteData: { __typename?: "Project"; id: string };
};

export type TriviaAnswerOptionUpdateMutationVariables = Exact<{
  triviaAnswerOptionId: Scalars["String"];
  data: TriviaAnswerOptionUpdateInput;
}>;

export type TriviaAnswerOptionUpdateMutation = {
  __typename?: "Mutation";
  triviaAnswerOptionUpdate: { __typename?: "TriviaAnswerOption" } & {
    " $fragmentRefs"?: { TriviaAnswerOptionFieldsFragment: TriviaAnswerOptionFieldsFragment };
  };
};

export type TriviaQuestionCreateMutationVariables = Exact<{
  triviaConfigurationId: Scalars["String"];
  data: TriviaQuestionCreateInput;
}>;

export type TriviaQuestionCreateMutation = {
  __typename?: "Mutation";
  triviaQuestionCreate: { __typename?: "TriviaQuestion" } & {
    " $fragmentRefs"?: { TriviaQuestionFieldsFragment: TriviaQuestionFieldsFragment };
  };
};

export type TriviaQuestionUpdateMutationVariables = Exact<{
  triviaQuestionId: Scalars["String"];
  data: TriviaQuestionUpdateInput;
}>;

export type TriviaQuestionUpdateMutation = {
  __typename?: "Mutation";
  triviaQuestionUpdate: { __typename?: "TriviaQuestion" } & {
    " $fragmentRefs"?: { TriviaQuestionFieldsFragment: TriviaQuestionFieldsFragment };
  };
};

export type TriviaQuestionDeleteMutationVariables = Exact<{
  triviaQuestionId: Scalars["String"];
}>;

export type TriviaQuestionDeleteMutation = {
  __typename?: "Mutation";
  triviaQuestionDelete: { __typename?: "TriviaQuestion"; id: string };
};

export type SignUpMutationVariables = Exact<{
  data: SignUpInput;
}>;

export type SignUpMutation = { __typename?: "Mutation"; signUp: { __typename?: "User"; id: string } };

export type SignInMutationVariables = Exact<{
  email: Scalars["String"];
  password: Scalars["String"];
}>;

export type SignInMutation = {
  __typename?: "Mutation";
  signIn: { __typename?: "SignInResponse"; accessToken: string };
};

export type SignInWithCodeMutationVariables = Exact<{
  code: Scalars["String"];
}>;

export type SignInWithCodeMutation = {
  __typename?: "Mutation";
  signInWithCode: { __typename?: "SignInResponse"; accessToken: string };
};

export type UserInviteMutationVariables = Exact<{
  email: Scalars["String"];
}>;

export type UserInviteMutation = { __typename?: "Mutation"; userInvite: { __typename?: "User"; email: string } };

export type UserUpdateMutationVariables = Exact<{
  data: UserUpdateInput;
}>;

export type UserUpdateMutation = { __typename?: "Mutation"; userUpdate: { __typename?: "User"; id: string } };

export type UserUpdatePasswordMutationVariables = Exact<{
  data: UserUpdatePasswordInput;
}>;

export type UserUpdatePasswordMutation = {
  __typename?: "Mutation";
  userUpdatePassword: { __typename?: "User"; id: string };
};

export type UserDeleteMutationVariables = Exact<{
  userId: Scalars["String"];
}>;

export type UserDeleteMutation = {
  __typename?: "Mutation";
  userDelete: { __typename?: "User"; id: string; email: string };
};

export type UserDeleteCurrentMutationVariables = Exact<{ [key: string]: never }>;

export type UserDeleteCurrentMutation = {
  __typename?: "Mutation";
  userDeleteCurrent: { __typename?: "User"; id: string };
};

export type UserRequestPasswordResetMutationVariables = Exact<{
  email: Scalars["String"];
  token: Scalars["String"];
}>;

export type UserRequestPasswordResetMutation = { __typename?: "Mutation"; userRequestPasswordReset: boolean };

export type StripeCheckoutCreateMutationVariables = Exact<{
  priceId: Scalars["String"];
}>;

export type StripeCheckoutCreateMutation = {
  __typename?: "Mutation";
  stripeCheckoutCreate: { __typename?: "CheckoutSession"; id: string };
};

export type UserResendInvitationMutationVariables = Exact<{
  userId: Scalars["String"];
}>;

export type UserResendInvitationMutation = {
  __typename?: "Mutation";
  userResendInvitation: { __typename?: "User"; email: string };
};

export type UserGrantAdminMutationVariables = Exact<{
  userId: Scalars["String"];
}>;

export type UserGrantAdminMutation = {
  __typename?: "Mutation";
  userGrantAdmin: { __typename?: "User"; email: string; role: UserRole };
};

export type UserRevokeAdminMutationVariables = Exact<{
  userId: Scalars["String"];
}>;

export type UserRevokeAdminMutation = {
  __typename?: "Mutation";
  userRevokeAdmin: { __typename?: "User"; email: string; role: UserRole };
};

export type StripePortalSessionCreateMutationVariables = Exact<{ [key: string]: never }>;

export type StripePortalSessionCreateMutation = {
  __typename?: "Mutation";
  stripePortalSessionCreate: { __typename?: "PortalSession"; url: string };
};

export type VideoCreateMutationVariables = Exact<{
  input: CreateVideoInput;
}>;

export type VideoCreateMutation = {
  __typename?: "Mutation";
  videoCreate: { __typename?: "Video" } & { " $fragmentRefs"?: { VideoFieldsFragment: VideoFieldsFragment } };
};

export type VideoDeleteMutationVariables = Exact<{
  videoId: Scalars["UUID"];
}>;

export type VideoDeleteMutation = { __typename?: "Mutation"; videoDelete: { __typename?: "Video"; id: string } };

export type GameAllQueryVariables = Exact<{
  projectId: Scalars["String"];
}>;

export type GameAllQuery = {
  __typename?: "Query";
  gameAll: Array<
    {
      __typename?: "Game";
      configuration:
        | {
            __typename?: "QuickRankConfiguration";
            id: string;
            question: string;
            mode: QuickRankMode;
            showRatingScale: boolean;
            ratingQuestion: string;
            ratingScaleSize: number;
            ratingRightLabel: string;
            ratingLeftLabel: string;
            showWelcomePage: boolean;
            showGameResults: boolean;
            showFeedbackPage: boolean;
            shuffleCards: boolean;
            showLeaderboard: boolean;
            attributes: Array<
              { __typename?: "Attribute" } & { " $fragmentRefs"?: { AttributeFieldsFragment: AttributeFieldsFragment } }
            >;
            gameVisuals: {
              __typename?: "GameVisuals";
              id: string;
              themeColor: string;
              showIcons: boolean;
              iconSet: IconSet;
              backgroundColor: string;
              backgroundPositiveColor: string;
              backgroundNegativeColor: string;
              positiveColor: string;
              negativeColor: string;
              cardBackImage?: { __typename?: "LibraryImage"; id: string; name: string; url: string } | null;
              backgroundImage?: { __typename?: "LibraryImage"; id: string; name: string; url: string } | null;
            };
            welcomePage: {
              __typename?: "WelcomePage";
              id: string;
              welcomeLine: string;
              welcomeLineColor: string;
              subheading: string;
              subheadingColor: string;
              tagLine: string;
              tagLineColor: string;
              button: string;
              buttonColor: string;
              backgroundColor: string;
              logo?: { __typename?: "LibraryImage"; id: string; name: string; url: string } | null;
              backgroundImage?: { __typename?: "LibraryImage"; id: string; name: string; url: string } | null;
            };
            feedbackPage: {
              __typename?: "FeedbackPage";
              id: string;
              title: string;
              titleColor: string;
              instructionLine: string;
              instructionLineColor: string;
              textBoxPrompt: string;
              textBoxPromptColor: string;
              submitButton: string;
              submitButtonColor: string;
              backgroundColor: string;
              backgroundImage?: { __typename?: "LibraryImage"; id: string; name: string; url: string } | null;
            };
            gameResults: {
              __typename?: "GameResultsConfiguration";
              id: string;
              scoreTitle_0_20: string;
              scoreTitle_21_40: string;
              scoreTitle_41_60: string;
              scoreTitle_61_80: string;
              scoreTitle_81_100: string;
              scoreTag_0_20: string;
              scoreTag_21_40: string;
              scoreTag_41_60: string;
              scoreTag_61_80: string;
              scoreTag_81_100: string;
              backgroundColor: string;
              backgroundImage?: { __typename?: "LibraryImage"; id: string; name: string; url: string } | null;
            };
            leaderboardConfiguration: {
              __typename?: "LeaderboardConfiguration";
              id: string;
              nicknameQuestion: string;
              nicknameQuestionColor: string;
              header: string;
              headerColor: string;
              nicknameLabel: string;
              numberOfPlayers: number;
              showTimer: boolean;
              subheader: string;
              subheaderColor: string;
              tableNicknameLabel: string;
              tableScoreLabel: string;
              tableTimeLabel: string;
            };
          }
        | {
            __typename?: "RankifierConfiguration";
            id: string;
            mostQuestion: string;
            leastQuestion: string;
            mostQuestionColor: string;
            leastQuestionColor: string;
            cardsPerHand: number;
            numberOfRounds: number;
            showWelcomePage: boolean;
            showGameResults: boolean;
            showFeedbackPage: boolean;
            attributes: Array<
              { __typename?: "Attribute" } & { " $fragmentRefs"?: { AttributeFieldsFragment: AttributeFieldsFragment } }
            >;
            gameVisuals: {
              __typename?: "GameVisuals";
              id: string;
              themeColor: string;
              showIcons: boolean;
              iconSet: IconSet;
              backgroundColor: string;
              backgroundPositiveColor: string;
              backgroundNegativeColor: string;
              positiveColor: string;
              negativeColor: string;
              cardBackImage?: { __typename?: "LibraryImage"; id: string; name: string; url: string } | null;
              backgroundImage?: { __typename?: "LibraryImage"; id: string; name: string; url: string } | null;
            };
            welcomePage: {
              __typename?: "WelcomePage";
              id: string;
              welcomeLine: string;
              welcomeLineColor: string;
              subheading: string;
              subheadingColor: string;
              tagLine: string;
              tagLineColor: string;
              button: string;
              buttonColor: string;
              backgroundColor: string;
              logo?: { __typename?: "LibraryImage"; id: string; name: string; url: string } | null;
              backgroundImage?: { __typename?: "LibraryImage"; id: string; name: string; url: string } | null;
            };
            feedbackPage: {
              __typename?: "FeedbackPage";
              id: string;
              title: string;
              titleColor: string;
              instructionLine: string;
              instructionLineColor: string;
              textBoxPrompt: string;
              textBoxPromptColor: string;
              submitButton: string;
              submitButtonColor: string;
              backgroundColor: string;
              backgroundImage?: { __typename?: "LibraryImage"; id: string; name: string; url: string } | null;
            };
            gameResults: {
              __typename?: "GameResultsConfiguration";
              id: string;
              scoreTitle_0_20: string;
              scoreTitle_21_40: string;
              scoreTitle_41_60: string;
              scoreTitle_61_80: string;
              scoreTitle_81_100: string;
              scoreTag_0_20: string;
              scoreTag_21_40: string;
              scoreTag_41_60: string;
              scoreTag_61_80: string;
              scoreTag_81_100: string;
              backgroundColor: string;
              backgroundImage?: { __typename?: "LibraryImage"; id: string; name: string; url: string } | null;
            };
          }
        | ({
            __typename?: "SwiperConfiguration";
            attributes: Array<
              { __typename?: "Attribute" } & { " $fragmentRefs"?: { AttributeFieldsFragment: AttributeFieldsFragment } }
            >;
            gameVisuals: {
              __typename?: "GameVisuals";
              id: string;
              themeColor: string;
              showIcons: boolean;
              iconSet: IconSet;
              backgroundColor: string;
              backgroundPositiveColor: string;
              backgroundNegativeColor: string;
              positiveColor: string;
              negativeColor: string;
              cardBackImage?: { __typename?: "LibraryImage"; id: string; name: string; url: string } | null;
              backgroundImage?: { __typename?: "LibraryImage"; id: string; name: string; url: string } | null;
            };
            welcomePage: {
              __typename?: "WelcomePage";
              id: string;
              welcomeLine: string;
              welcomeLineColor: string;
              subheading: string;
              subheadingColor: string;
              tagLine: string;
              tagLineColor: string;
              button: string;
              buttonColor: string;
              backgroundColor: string;
              backgroundImage?: { __typename?: "LibraryImage"; id: string; name: string; url: string } | null;
              logo?: { __typename?: "LibraryImage"; id: string; name: string; url: string } | null;
            };
            feedbackPage: {
              __typename?: "FeedbackPage";
              id: string;
              title: string;
              titleColor: string;
              instructionLine: string;
              instructionLineColor: string;
              textBoxPrompt: string;
              textBoxPromptColor: string;
              submitButton: string;
              submitButtonColor: string;
              backgroundColor: string;
              backgroundImage?: { __typename?: "LibraryImage"; id: string; name: string; url: string } | null;
            };
            gameResults: {
              __typename?: "GameResultsConfiguration";
              id: string;
              scoreTitle_0_20: string;
              scoreTitle_21_40: string;
              scoreTitle_41_60: string;
              scoreTitle_61_80: string;
              scoreTitle_81_100: string;
              scoreTag_0_20: string;
              scoreTag_21_40: string;
              scoreTag_41_60: string;
              scoreTag_61_80: string;
              scoreTag_81_100: string;
              backgroundColor: string;
              backgroundImage?: { __typename?: "LibraryImage"; id: string; name: string; url: string } | null;
            };
          } & { " $fragmentRefs"?: { SwiperConfigurationFieldsFragment: SwiperConfigurationFieldsFragment } })
        | ({
            __typename?: "TriviaConfiguration";
            showLeaderboard: boolean;
            leaderboardConfiguration: {
              __typename?: "LeaderboardConfiguration";
              id: string;
              nicknameQuestion: string;
              nicknameQuestionColor: string;
              header: string;
              headerColor: string;
              nicknameLabel: string;
              numberOfPlayers: number;
              showTimer: boolean;
              subheader: string;
              subheaderColor: string;
              tableNicknameLabel: string;
              tableScoreLabel: string;
              tableTimeLabel: string;
              backgroundColor: string;
              backgroundImage?: { __typename?: "LibraryImage"; id: string; name: string; url: string } | null;
            };
          } & { " $fragmentRefs"?: { TriviaConfigurationFieldsFragment: TriviaConfigurationFieldsFragment } })
        | ({
            __typename?: "WordleConfiguration";
            gameVisuals: {
              __typename?: "GameVisuals";
              id: string;
              themeColor: string;
              showIcons: boolean;
              iconSet: IconSet;
              backgroundColor: string;
              backgroundPositiveColor: string;
              backgroundNegativeColor: string;
              positiveColor: string;
              negativeColor: string;
              cardBackImage?: { __typename?: "LibraryImage"; id: string; name: string; url: string } | null;
              backgroundImage?: { __typename?: "LibraryImage"; id: string; name: string; url: string } | null;
            };
            welcomePage: {
              __typename?: "WelcomePage";
              id: string;
              welcomeLine: string;
              welcomeLineColor: string;
              subheading: string;
              subheadingColor: string;
              tagLine: string;
              tagLineColor: string;
              button: string;
              buttonColor: string;
              backgroundColor: string;
              logo?: { __typename?: "LibraryImage"; id: string; name: string; url: string } | null;
              backgroundImage?: { __typename?: "LibraryImage"; id: string; name: string; url: string } | null;
            };
            feedbackPage: {
              __typename?: "FeedbackPage";
              id: string;
              title: string;
              titleColor: string;
              instructionLine: string;
              instructionLineColor: string;
              textBoxPrompt: string;
              textBoxPromptColor: string;
              submitButton: string;
              submitButtonColor: string;
              backgroundColor: string;
              backgroundImage?: { __typename?: "LibraryImage"; id: string; name: string; url: string } | null;
            };
          } & { " $fragmentRefs"?: { WordleConfigurationFieldsFragment: WordleConfigurationFieldsFragment } })
        | {
            __typename?: "WordopolisConfiguration";
            id: string;
            topic: string;
            difficulty: WordopolisDifficulty;
            numberOfRows: number;
            numberOfColumns: number;
            isTimeLimited: boolean;
            limitedTime: number;
            showWords: boolean;
            startText: string;
            showWelcomePage: boolean;
            showRatingScale: boolean;
            ratingQuestion: string;
            ratingLeftLabel: string;
            ratingRightLabel: string;
            ratingScaleSize: number;
            boardBackgroundColor: string;
            boardColor: string;
            board: Array<string>;
            showLeaderboard: boolean;
            boardBackgroundImage?: { __typename?: "LibraryImage"; id: string; name: string; url: string } | null;
            attributes: Array<
              { __typename?: "Attribute" } & { " $fragmentRefs"?: { AttributeFieldsFragment: AttributeFieldsFragment } }
            >;
            gameVisuals: {
              __typename?: "GameVisuals";
              id: string;
              themeColor: string;
              showIcons: boolean;
              iconSet: IconSet;
              backgroundColor: string;
              backgroundPositiveColor: string;
              backgroundNegativeColor: string;
              positiveColor: string;
              negativeColor: string;
              backgroundImage?: { __typename?: "LibraryImage"; id: string; name: string; url: string } | null;
            };
            welcomePage: {
              __typename?: "WelcomePage";
              id: string;
              welcomeLine: string;
              welcomeLineColor: string;
              subheading: string;
              subheadingColor: string;
              tagLine: string;
              tagLineColor: string;
              button: string;
              buttonColor: string;
              backgroundColor: string;
              logo?: { __typename?: "LibraryImage"; id: string; name: string; url: string } | null;
              backgroundImage?: { __typename?: "LibraryImage"; id: string; name: string; url: string } | null;
            };
            leaderboardConfiguration: {
              __typename?: "LeaderboardConfiguration";
              id: string;
              nicknameQuestion: string;
              nicknameQuestionColor: string;
              header: string;
              headerColor: string;
              nicknameLabel: string;
              numberOfPlayers: number;
              showTimer: boolean;
              subheader: string;
              subheaderColor: string;
              tableNicknameLabel: string;
              tableScoreLabel: string;
              tableTimeLabel: string;
              backgroundColor: string;
              backgroundImage?: { __typename?: "LibraryImage"; id: string; name: string; url: string } | null;
            };
          };
    } & { " $fragmentRefs"?: { GameFieldsFragment: GameFieldsFragment } }
  >;
};

export type GameAllIdsQueryVariables = Exact<{
  projectId: Scalars["String"];
}>;

export type GameAllIdsQuery = {
  __typename?: "Query";
  gameAll: Array<{
    __typename?: "Game";
    id: string;
    name: string;
    kind: GameKind;
    configuration:
      | { __typename?: "QuickRankConfiguration"; id: string; mode: QuickRankMode }
      | { __typename?: "RankifierConfiguration" }
      | { __typename?: "SwiperConfiguration" }
      | { __typename?: "TriviaConfiguration" }
      | { __typename?: "WordleConfiguration" }
      | { __typename?: "WordopolisConfiguration" };
  }>;
};

export type LibraryImageAllQueryVariables = Exact<{ [key: string]: never }>;

export type LibraryImageAllQuery = {
  __typename?: "Query";
  libraryImageAll: Array<
    { __typename?: "LibraryImage" } & {
      " $fragmentRefs"?: { Library_Image_FieldsFragment: Library_Image_FieldsFragment };
    }
  >;
};

export type ProjectQueryVariables = Exact<{
  projectId: Scalars["UUID"];
}>;

export type ProjectQuery = {
  __typename?: "Query";
  project: { __typename?: "Project" } & { " $fragmentRefs"?: { ProjectFieldsFragment: ProjectFieldsFragment } };
};

export type ProjectListQueryVariables = Exact<{ [key: string]: never }>;

export type ProjectListQuery = {
  __typename?: "Query";
  projectList: Array<
    { __typename?: "Project"; games: Array<{ __typename?: "Game"; responseCount: number }> } & {
      " $fragmentRefs"?: { ProjectFieldsFragment: ProjectFieldsFragment };
    }
  >;
};

export type DirectoryListQueryVariables = Exact<{ [key: string]: never }>;

export type DirectoryListQuery = {
  __typename?: "Query";
  directoryList: Array<
    {
      __typename?: "Directory";
      parentDirectory?:
        | ({ __typename?: "Directory" } & { " $fragmentRefs"?: { Directory_FieldsFragment: Directory_FieldsFragment } })
        | null;
    } & { " $fragmentRefs"?: { Directory_FieldsFragment: Directory_FieldsFragment } }
  >;
};

export type ProjectPublicQueryVariables = Exact<{
  projectId: Scalars["UUID"];
}>;

export type ProjectPublicQuery = {
  __typename?: "Query";
  projectPublic: {
    __typename?: "ProjectPublic";
    id: string;
    public: boolean;
    name: string;
    games: Array<{
      __typename?: "Game";
      id: string;
      kind: GameKind;
      name: string;
      responseCount: number;
      configuration:
        | { __typename?: "QuickRankConfiguration"; id: string; showRatingScale: boolean }
        | { __typename?: "RankifierConfiguration"; id: string }
        | { __typename?: "SwiperConfiguration"; id: string }
        | { __typename?: "TriviaConfiguration"; id: string }
        | { __typename?: "WordleConfiguration"; id: string }
        | { __typename?: "WordopolisConfiguration"; id: string; showRatingScale: boolean };
    }>;
  };
};

export type SwiperReportQueryVariables = Exact<{
  gameId: Scalars["String"];
}>;

export type SwiperReportQuery = {
  __typename?: "Query";
  swiperReport: {
    __typename?: "SwiperReport";
    totalResponses: number;
    attributeResults: Array<{
      __typename?: "AttributeResult";
      totalScore: number;
      attribute: { __typename?: "Attribute"; id: string; name: string };
    }>;
  };
};

export type RankifierReportQueryVariables = Exact<{
  gameId: Scalars["String"];
}>;

export type RankifierReportQuery = {
  __typename?: "Query";
  rankifierReport: {
    __typename?: "RankifierReport";
    totalResponses: number;
    attributeResults: Array<{
      __typename?: "RankifierAttributeResult";
      minuses: number;
      pluses: number;
      score: number;
      attribute: { __typename?: "Attribute"; id: string; name: string };
    }>;
  };
};

export type QuickRankReportQueryVariables = Exact<{
  gameId: Scalars["String"];
}>;

export type QuickRankReportQuery = {
  __typename?: "Query";
  quickRankReport: {
    __typename?: "QuickRankReport";
    responseCount: number;
    totalAttributes: number;
    attributeResults: Array<{
      __typename?: "QuickRankAttributeResult";
      avgRank: number;
      avgRating: number;
      attribute: { __typename?: "Attribute"; id: string; name: string };
    }>;
  };
};

export type WordopolisReportQueryVariables = Exact<{
  gameId: Scalars["String"];
}>;

export type WordopolisReportQuery = {
  __typename?: "Query";
  wordopolisReport: {
    __typename?: "WordopolisReport";
    totalResponses: number;
    attributeResults: Array<{
      __typename?: "WordopolisAttributeResult";
      avgScore: number;
      avgRating: number;
      attribute: { __typename?: "Attribute"; id: string; name: string };
    }>;
  };
};

export type TriviaReportQueryVariables = Exact<{
  gameId: Scalars["String"];
}>;

export type TriviaReportQuery = {
  __typename?: "Query";
  triviaReport: {
    __typename?: "TriviaReport";
    totalResponses: number;
    questionResults: Array<{
      __typename?: "TriviaReportLine";
      question: { __typename?: "TriviaQuestion"; id: string; question: string };
      scores: Array<{
        __typename?: "AggregatedTriviaQuestionScore";
        count: number;
        option: { __typename?: "TriviaAnswerOption"; text: string; id: string };
      }>;
    }>;
  };
};

export type GameResponsesQueryVariables = Exact<{
  gameId: Scalars["String"];
}>;

export type GameResponsesQuery = {
  __typename?: "Query";
  game: { __typename?: "Game"; responses: Array<{ __typename?: "Response"; id: string }> };
};

export type TriviaResponseReportQueryVariables = Exact<{
  gameId: Scalars["String"];
  responseId: Scalars["String"];
}>;

export type TriviaResponseReportQuery = {
  __typename?: "Query";
  triviaResponseReport: {
    __typename?: "TriviaResponseReport";
    numberOfCorrectAnswers: number;
    numberOfwrongAnswers: number;
    scores: Array<{
      __typename?: "TriviaQuestionScore";
      isCorrect: boolean;
      answer: { __typename?: "TriviaAnswerOption"; id: string; text: string };
      question: {
        __typename?: "TriviaQuestion";
        id: string;
        question: string;
        options: Array<{ __typename?: "TriviaAnswerOption"; id: string; text: string }>;
      };
    }>;
  };
};

export type WordleReportQueryVariables = Exact<{
  gameId: Scalars["String"];
}>;

export type WordleReportQuery = {
  __typename?: "Query";
  wordleReport: {
    __typename?: "WordleReport";
    totalResponses: number;
    scoreResults: Array<{ __typename?: "ScoreResult"; score: number; count: number }>;
  };
};

export type UserListQueryVariables = Exact<{ [key: string]: never }>;

export type UserListQuery = {
  __typename?: "Query";
  userList: Array<{ __typename?: "User" } & { " $fragmentRefs"?: { User_FieldsFragment: User_FieldsFragment } }>;
};

export type UserMeQueryVariables = Exact<{ [key: string]: never }>;

export type UserMeQuery = {
  __typename?: "Query";
  userMe: {
    __typename?: "User";
    email: string;
    createdAt: string;
    emailVerifiedAt?: string | null;
    firstName: string;
    id: string;
    lastName: string;
    updatedAt: string;
    role: UserRole;
    organization: {
      __typename?: "Organization";
      name: string;
      manuallyManagedPlan?: PlanName | null;
      subscription?: { __typename?: "Subscription"; status: string; planName: PlanName } | null;
    };
  };
};

export const ProjectFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProjectFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Project" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "hideDatagameBranding" } },
          { kind: "Field", name: { kind: "Name", value: "hideSummaryPage" } },
          { kind: "Field", name: { kind: "Name", value: "showLeaderboard" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "directory" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "leaderboardConfiguration" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "nicknameQuestion" } },
                { kind: "Field", name: { kind: "Name", value: "nicknameQuestionColor" } },
                { kind: "Field", name: { kind: "Name", value: "header" } },
                { kind: "Field", name: { kind: "Name", value: "headerColor" } },
                { kind: "Field", name: { kind: "Name", value: "nicknameLabel" } },
                { kind: "Field", name: { kind: "Name", value: "numberOfPlayers" } },
                { kind: "Field", name: { kind: "Name", value: "showTimer" } },
                { kind: "Field", name: { kind: "Name", value: "subheader" } },
                { kind: "Field", name: { kind: "Name", value: "subheaderColor" } },
                { kind: "Field", name: { kind: "Name", value: "tableNicknameLabel" } },
                { kind: "Field", name: { kind: "Name", value: "tableScoreLabel" } },
                { kind: "Field", name: { kind: "Name", value: "tableTimeLabel" } },
                { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "backgroundImage" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectFieldsFragment, unknown>;
export const Directory_FieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DIRECTORY_FIELDS" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Directory" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "default" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Directory_FieldsFragment, unknown>;
export const Project_FieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PROJECT_FIELDS" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Project" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "directory" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                { kind: "Field", name: { kind: "Name", value: "default" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Project_FieldsFragment, unknown>;
export const User_FieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "USER_FIELDS" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "User" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          { kind: "Field", name: { kind: "Name", value: "role" } },
          { kind: "Field", name: { kind: "Name", value: "emailVerifiedAt" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<User_FieldsFragment, unknown>;
export const Library_Image_FieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LIBRARY_IMAGE_FIELDS" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LibraryImage" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "extension" } },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "organizationId" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "kind" } },
          { kind: "Field", name: { kind: "Name", value: "isGlobal" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Library_Image_FieldsFragment, unknown>;
export const GameFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "GameFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Game" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "kind" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GameFieldsFragment, unknown>;
export const SwiperConfigurationFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SwiperConfigurationFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "SwiperConfiguration" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "shuffleCards" } },
          { kind: "Field", name: { kind: "Name", value: "showGameResults" } },
          { kind: "Field", name: { kind: "Name", value: "showWelcomePage" } },
          { kind: "Field", name: { kind: "Name", value: "question" } },
          { kind: "Field", name: { kind: "Name", value: "questionColor" } },
          { kind: "Field", name: { kind: "Name", value: "showFeedbackPage" } },
          { kind: "Field", name: { kind: "Name", value: "showButtons" } },
          { kind: "Field", name: { kind: "Name", value: "showLeaderboard" } },
          { kind: "Field", name: { kind: "Name", value: "showSwipeCorrectness" } },
          { kind: "Field", name: { kind: "Name", value: "correctSwipeText" } },
          { kind: "Field", name: { kind: "Name", value: "incorrectSwipeText" } },
          { kind: "Field", name: { kind: "Name", value: "likeText" } },
          { kind: "Field", name: { kind: "Name", value: "unlikeText" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "leaderboardConfiguration" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "nicknameQuestion" } },
                { kind: "Field", name: { kind: "Name", value: "nicknameQuestionColor" } },
                { kind: "Field", name: { kind: "Name", value: "header" } },
                { kind: "Field", name: { kind: "Name", value: "headerColor" } },
                { kind: "Field", name: { kind: "Name", value: "nicknameLabel" } },
                { kind: "Field", name: { kind: "Name", value: "numberOfPlayers" } },
                { kind: "Field", name: { kind: "Name", value: "showTimer" } },
                { kind: "Field", name: { kind: "Name", value: "subheader" } },
                { kind: "Field", name: { kind: "Name", value: "subheaderColor" } },
                { kind: "Field", name: { kind: "Name", value: "tableNicknameLabel" } },
                { kind: "Field", name: { kind: "Name", value: "tableScoreLabel" } },
                { kind: "Field", name: { kind: "Name", value: "tableTimeLabel" } },
                { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "backgroundImage" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SwiperConfigurationFieldsFragment, unknown>;
export const VideoFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "VideoFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Video" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "extension" } },
          { kind: "Field", name: { kind: "Name", value: "url" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VideoFieldsFragment, unknown>;
export const TriviaAnswerOptionFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "TriviaAnswerOptionFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "TriviaAnswerOption" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "text" } },
          { kind: "Field", name: { kind: "Name", value: "isCorrect" } },
          { kind: "Field", name: { kind: "Name", value: "points" } },
          { kind: "Field", name: { kind: "Name", value: "explanation" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TriviaAnswerOptionFieldsFragment, unknown>;
export const TriviaQuestionFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "TriviaQuestionFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "TriviaQuestion" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "kind" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "url" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "video" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "VideoFields" } }],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "question" } },
          { kind: "Field", name: { kind: "Name", value: "timeToCompleteInSeconds" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "options" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "TriviaAnswerOptionFields" } }],
            },
          },
        ],
      },
    },
    ...VideoFieldsFragmentDoc.definitions,
    ...TriviaAnswerOptionFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<TriviaQuestionFieldsFragment, unknown>;
export const TriviaConfigurationFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "TriviaConfigurationFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "TriviaConfiguration" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "timeToCompleteInSeconds" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "questions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "TriviaQuestionFields" } }],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "gameVisuals" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "themeColor" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cardBackImage" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "showIcons" } },
                { kind: "Field", name: { kind: "Name", value: "iconSet" } },
                { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                { kind: "Field", name: { kind: "Name", value: "backgroundPositiveColor" } },
                { kind: "Field", name: { kind: "Name", value: "backgroundNegativeColor" } },
                { kind: "Field", name: { kind: "Name", value: "positiveColor" } },
                { kind: "Field", name: { kind: "Name", value: "negativeColor" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "backgroundImage" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "showFeedbackPage" } },
          { kind: "Field", name: { kind: "Name", value: "showWelcomePage" } },
          { kind: "Field", name: { kind: "Name", value: "showLeaderboard" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "welcomePage" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "welcomeLine" } },
                { kind: "Field", name: { kind: "Name", value: "welcomeLineColor" } },
                { kind: "Field", name: { kind: "Name", value: "subheading" } },
                { kind: "Field", name: { kind: "Name", value: "subheadingColor" } },
                { kind: "Field", name: { kind: "Name", value: "tagLine" } },
                { kind: "Field", name: { kind: "Name", value: "tagLineColor" } },
                { kind: "Field", name: { kind: "Name", value: "button" } },
                { kind: "Field", name: { kind: "Name", value: "buttonColor" } },
                { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "backgroundImage" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "logo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "feedbackPage" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "titleColor" } },
                { kind: "Field", name: { kind: "Name", value: "instructionLine" } },
                { kind: "Field", name: { kind: "Name", value: "instructionLineColor" } },
                { kind: "Field", name: { kind: "Name", value: "textBoxPrompt" } },
                { kind: "Field", name: { kind: "Name", value: "textBoxPromptColor" } },
                { kind: "Field", name: { kind: "Name", value: "submitButton" } },
                { kind: "Field", name: { kind: "Name", value: "submitButtonColor" } },
                { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "backgroundImage" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...TriviaQuestionFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<TriviaConfigurationFieldsFragment, unknown>;
export const AttributeFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AttributeFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Attribute" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "customText" } },
          { kind: "Field", name: { kind: "Name", value: "points" } },
          { kind: "Field", name: { kind: "Name", value: "isCorrect" } },
          { kind: "Field", name: { kind: "Name", value: "showImageAndText" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "customRatingQuestion" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "question" } },
                { kind: "Field", name: { kind: "Name", value: "leftLabel" } },
                { kind: "Field", name: { kind: "Name", value: "rightLabel" } },
                { kind: "Field", name: { kind: "Name", value: "scaleSize" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "image" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "url" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AttributeFieldsFragment, unknown>;
export const WordleConfigurationFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "WordleConfigurationFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "WordleConfiguration" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "word" } },
          { kind: "Field", name: { kind: "Name", value: "numberOfTries" } },
          { kind: "Field", name: { kind: "Name", value: "showWelcomePage" } },
          { kind: "Field", name: { kind: "Name", value: "showFeedbackPage" } },
          { kind: "Field", name: { kind: "Name", value: "showLeaderboard" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "leaderboardConfiguration" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "nicknameQuestion" } },
                { kind: "Field", name: { kind: "Name", value: "nicknameQuestionColor" } },
                { kind: "Field", name: { kind: "Name", value: "header" } },
                { kind: "Field", name: { kind: "Name", value: "headerColor" } },
                { kind: "Field", name: { kind: "Name", value: "nicknameLabel" } },
                { kind: "Field", name: { kind: "Name", value: "numberOfPlayers" } },
                { kind: "Field", name: { kind: "Name", value: "showTimer" } },
                { kind: "Field", name: { kind: "Name", value: "subheader" } },
                { kind: "Field", name: { kind: "Name", value: "subheaderColor" } },
                { kind: "Field", name: { kind: "Name", value: "tableNicknameLabel" } },
                { kind: "Field", name: { kind: "Name", value: "tableScoreLabel" } },
                { kind: "Field", name: { kind: "Name", value: "tableTimeLabel" } },
                { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "backgroundImage" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "gameVisuals" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "themeColor" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cardBackImage" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "showIcons" } },
                { kind: "Field", name: { kind: "Name", value: "iconSet" } },
                { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                { kind: "Field", name: { kind: "Name", value: "backgroundPositiveColor" } },
                { kind: "Field", name: { kind: "Name", value: "backgroundNegativeColor" } },
                { kind: "Field", name: { kind: "Name", value: "positiveColor" } },
                { kind: "Field", name: { kind: "Name", value: "negativeColor" } },
                { kind: "Field", name: { kind: "Name", value: "presentLetter" } },
                { kind: "Field", name: { kind: "Name", value: "correctLetter" } },
                { kind: "Field", name: { kind: "Name", value: "defaultLetter" } },
                { kind: "Field", name: { kind: "Name", value: "absentLetter" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "backgroundImage" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WordleConfigurationFieldsFragment, unknown>;
export const AttributeCreateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AttributeCreate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "AttributeCreateInput" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "gameId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "attributeCreate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "gameId" },
                value: { kind: "Variable", name: { kind: "Name", value: "gameId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "AttributeFields" } }],
            },
          },
        ],
      },
    },
    ...AttributeFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<AttributeCreateMutation, AttributeCreateMutationVariables>;
export const AttributeDeleteDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AttributeDelete" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "attributeId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "attributeDelete" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "attributeId" },
                value: { kind: "Variable", name: { kind: "Name", value: "attributeId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "AttributeFields" } }],
            },
          },
        ],
      },
    },
    ...AttributeFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<AttributeDeleteMutation, AttributeDeleteMutationVariables>;
export const AttributeUpdateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AttributeUpdate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "AttributeUpdateInput" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "attributeId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "attributeUpdate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "attributeId" },
                value: { kind: "Variable", name: { kind: "Name", value: "attributeId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "AttributeFields" } }],
            },
          },
        ],
      },
    },
    ...AttributeFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<AttributeUpdateMutation, AttributeUpdateMutationVariables>;
export const DirectoryCreateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DirectoryCreate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "CreateDirectoryInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "directoryCreate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DirectoryCreateMutation, DirectoryCreateMutationVariables>;
export const DirectoryUpdateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DirectoryUpdate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "UpdateDirectoryInput" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "directoryId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "directoryUpdate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "directoryId" },
                value: { kind: "Variable", name: { kind: "Name", value: "directoryId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parentDirectory" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DirectoryUpdateMutation, DirectoryUpdateMutationVariables>;
export const DirectoryDeleteDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DirectoryDelete" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "directoryId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "directoryDelete" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "directoryId" },
                value: { kind: "Variable", name: { kind: "Name", value: "directoryId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DirectoryDeleteMutation, DirectoryDeleteMutationVariables>;
export const GameCreateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "GameCreate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "GameCreateInput" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "gameCreate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "FragmentSpread", name: { kind: "Name", value: "GameFields" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "configuration" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "InlineFragment",
                        typeCondition: { kind: "NamedType", name: { kind: "Name", value: "SwiperConfiguration" } },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: { kind: "NamedType", name: { kind: "Name", value: "RankifierConfiguration" } },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: { kind: "NamedType", name: { kind: "Name", value: "QuickRankConfiguration" } },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: { kind: "NamedType", name: { kind: "Name", value: "WordopolisConfiguration" } },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...GameFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GameCreateMutation, GameCreateMutationVariables>;
export const GameDeleteDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "GameDelete" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "gameId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "gameDelete" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "gameId" },
                value: { kind: "Variable", name: { kind: "Name", value: "gameId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "GameFields" } }],
            },
          },
        ],
      },
    },
    ...GameFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GameDeleteMutation, GameDeleteMutationVariables>;
export const GameUpdateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "GameUpdate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "gameId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "GameUpdateInput" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "gameUpdate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "gameId" },
                value: { kind: "Variable", name: { kind: "Name", value: "gameId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "GameFields" } }],
            },
          },
        ],
      },
    },
    ...GameFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GameUpdateMutation, GameUpdateMutationVariables>;
export const SwiperConfigurationUpdateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SwiperConfigurationUpdate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "swiperConfigurationId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "SwiperConfigurationUpdateInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "swiperConfigurationUpdate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "swiperConfigurationId" },
                value: { kind: "Variable", name: { kind: "Name", value: "swiperConfigurationId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "SwiperConfigurationFields" } }],
            },
          },
        ],
      },
    },
    ...SwiperConfigurationFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<SwiperConfigurationUpdateMutation, SwiperConfigurationUpdateMutationVariables>;
export const LeaderboardConfigurationUpdateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "LeaderboardConfigurationUpdate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "LeaderboardConfigurationUpdateInput" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "leaderboardConfigurationId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "leaderboardConfigurationUpdate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "leaderboardConfigurationId" },
                value: { kind: "Variable", name: { kind: "Name", value: "leaderboardConfigurationId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "nicknameQuestion" } },
                { kind: "Field", name: { kind: "Name", value: "nicknameQuestionColor" } },
                { kind: "Field", name: { kind: "Name", value: "header" } },
                { kind: "Field", name: { kind: "Name", value: "headerColor" } },
                { kind: "Field", name: { kind: "Name", value: "nicknameLabel" } },
                { kind: "Field", name: { kind: "Name", value: "numberOfPlayers" } },
                { kind: "Field", name: { kind: "Name", value: "showTimer" } },
                { kind: "Field", name: { kind: "Name", value: "subheader" } },
                { kind: "Field", name: { kind: "Name", value: "subheaderColor" } },
                { kind: "Field", name: { kind: "Name", value: "tableNicknameLabel" } },
                { kind: "Field", name: { kind: "Name", value: "tableScoreLabel" } },
                { kind: "Field", name: { kind: "Name", value: "tableTimeLabel" } },
                { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "backgroundImage" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LeaderboardConfigurationUpdateMutation, LeaderboardConfigurationUpdateMutationVariables>;
export const RankifierConfigurationUpdateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "RankifierConfigurationUpdate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "RankifierConfigurationUpdateInput" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "rankifierConfigurationId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "rankifierConfigurationUpdate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "rankifierConfigurationId" },
                value: { kind: "Variable", name: { kind: "Name", value: "rankifierConfigurationId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "mostQuestion" } },
                { kind: "Field", name: { kind: "Name", value: "leastQuestion" } },
                { kind: "Field", name: { kind: "Name", value: "mostQuestionColor" } },
                { kind: "Field", name: { kind: "Name", value: "leastQuestionColor" } },
                { kind: "Field", name: { kind: "Name", value: "showWelcomePage" } },
                { kind: "Field", name: { kind: "Name", value: "showGameResults" } },
                { kind: "Field", name: { kind: "Name", value: "showFeedbackPage" } },
                { kind: "Field", name: { kind: "Name", value: "cardsPerHand" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RankifierConfigurationUpdateMutation, RankifierConfigurationUpdateMutationVariables>;
export const QuickRankConfigurationUpdateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "QuickRankConfigurationUpdate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "QuickRankConfigurationUpdateInput" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "quickRankConfigurationId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "quickRankConfigurationUpdate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "quickRankConfigurationId" },
                value: { kind: "Variable", name: { kind: "Name", value: "quickRankConfigurationId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "question" } },
                { kind: "Field", name: { kind: "Name", value: "showWelcomePage" } },
                { kind: "Field", name: { kind: "Name", value: "showGameResults" } },
                { kind: "Field", name: { kind: "Name", value: "showFeedbackPage" } },
                { kind: "Field", name: { kind: "Name", value: "shuffleCards" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QuickRankConfigurationUpdateMutation, QuickRankConfigurationUpdateMutationVariables>;
export const WordopolisConfigurationUpdateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "WordopolisConfigurationUpdate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "WordopolisConfigurationUpdateInput" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "wordopolisConfigurationId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "wordopolisConfigurationUpdate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "wordopolisConfigurationId" },
                value: { kind: "Variable", name: { kind: "Name", value: "wordopolisConfigurationId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "topic" } },
                { kind: "Field", name: { kind: "Name", value: "difficulty" } },
                { kind: "Field", name: { kind: "Name", value: "numberOfRows" } },
                { kind: "Field", name: { kind: "Name", value: "numberOfColumns" } },
                { kind: "Field", name: { kind: "Name", value: "startText" } },
                { kind: "Field", name: { kind: "Name", value: "showWords" } },
                { kind: "Field", name: { kind: "Name", value: "isTimeLimited" } },
                { kind: "Field", name: { kind: "Name", value: "limitedTime" } },
                { kind: "Field", name: { kind: "Name", value: "showWelcomePage" } },
                { kind: "Field", name: { kind: "Name", value: "showRatingScale" } },
                { kind: "Field", name: { kind: "Name", value: "ratingQuestion" } },
                { kind: "Field", name: { kind: "Name", value: "ratingLeftLabel" } },
                { kind: "Field", name: { kind: "Name", value: "ratingRightLabel" } },
                { kind: "Field", name: { kind: "Name", value: "ratingScaleSize" } },
                { kind: "Field", name: { kind: "Name", value: "boardBackgroundColor" } },
                { kind: "Field", name: { kind: "Name", value: "boardColor" } },
                { kind: "Field", name: { kind: "Name", value: "showLeaderboard" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "boardBackgroundImage" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WordopolisConfigurationUpdateMutation, WordopolisConfigurationUpdateMutationVariables>;
export const WordopolisConfigurationUpdateWithBoardDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "WordopolisConfigurationUpdateWithBoard" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "WordopolisConfigurationUpdateInput" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "wordopolisConfigurationId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "wordopolisConfigurationUpdate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "wordopolisConfigurationId" },
                value: { kind: "Variable", name: { kind: "Name", value: "wordopolisConfigurationId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "topic" } },
                { kind: "Field", name: { kind: "Name", value: "difficulty" } },
                { kind: "Field", name: { kind: "Name", value: "numberOfRows" } },
                { kind: "Field", name: { kind: "Name", value: "numberOfColumns" } },
                { kind: "Field", name: { kind: "Name", value: "startText" } },
                { kind: "Field", name: { kind: "Name", value: "showWords" } },
                { kind: "Field", name: { kind: "Name", value: "isTimeLimited" } },
                { kind: "Field", name: { kind: "Name", value: "limitedTime" } },
                { kind: "Field", name: { kind: "Name", value: "showWelcomePage" } },
                { kind: "Field", name: { kind: "Name", value: "showRatingScale" } },
                { kind: "Field", name: { kind: "Name", value: "ratingQuestion" } },
                { kind: "Field", name: { kind: "Name", value: "ratingLeftLabel" } },
                { kind: "Field", name: { kind: "Name", value: "ratingRightLabel" } },
                { kind: "Field", name: { kind: "Name", value: "ratingScaleSize" } },
                { kind: "Field", name: { kind: "Name", value: "boardBackgroundColor" } },
                { kind: "Field", name: { kind: "Name", value: "boardColor" } },
                { kind: "Field", name: { kind: "Name", value: "board" } },
                { kind: "Field", name: { kind: "Name", value: "showLeaderboard" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "boardBackgroundImage" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WordopolisConfigurationUpdateWithBoardMutation,
  WordopolisConfigurationUpdateWithBoardMutationVariables
>;
export const TriviaConfigurationUpdateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "TriviaConfigurationUpdate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "TriviaConfigurationUpdateInput" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "triviaConfigurationId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "triviaConfigurationUpdate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "triviaConfigurationId" },
                value: { kind: "Variable", name: { kind: "Name", value: "triviaConfigurationId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "showWelcomePage" } },
                { kind: "Field", name: { kind: "Name", value: "showFeedbackPage" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TriviaConfigurationUpdateMutation, TriviaConfigurationUpdateMutationVariables>;
export const GameVisualsUpdateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "GameVisualsUpdate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "GameVisualsUpdateInput" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "gameVisualsId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "gameVisualsUpdate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "gameVisualsId" },
                value: { kind: "Variable", name: { kind: "Name", value: "gameVisualsId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "themeColor" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cardBackImage" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "showIcons" } },
                { kind: "Field", name: { kind: "Name", value: "iconSet" } },
                { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                { kind: "Field", name: { kind: "Name", value: "backgroundPositiveColor" } },
                { kind: "Field", name: { kind: "Name", value: "backgroundNegativeColor" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "backgroundImage" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GameVisualsUpdateMutation, GameVisualsUpdateMutationVariables>;
export const WelcomePageUpdateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "WelcomePageUpdate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "WelcomePageUpdateInput" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "welcomePageId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "welcomePageUpdate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "welcomePageId" },
                value: { kind: "Variable", name: { kind: "Name", value: "welcomePageId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "welcomeLine" } },
                { kind: "Field", name: { kind: "Name", value: "welcomeLineColor" } },
                { kind: "Field", name: { kind: "Name", value: "subheading" } },
                { kind: "Field", name: { kind: "Name", value: "subheadingColor" } },
                { kind: "Field", name: { kind: "Name", value: "tagLine" } },
                { kind: "Field", name: { kind: "Name", value: "tagLineColor" } },
                { kind: "Field", name: { kind: "Name", value: "button" } },
                { kind: "Field", name: { kind: "Name", value: "buttonColor" } },
                { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "backgroundImage" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "logo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WelcomePageUpdateMutation, WelcomePageUpdateMutationVariables>;
export const FeedbackPageUpdateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "FeedbackPageUpdate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "FeedbackPageUpdateInput" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "feedbackPageId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "feedbackPageUpdate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "feedbackPageId" },
                value: { kind: "Variable", name: { kind: "Name", value: "feedbackPageId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "titleColor" } },
                { kind: "Field", name: { kind: "Name", value: "instructionLine" } },
                { kind: "Field", name: { kind: "Name", value: "instructionLineColor" } },
                { kind: "Field", name: { kind: "Name", value: "textBoxPrompt" } },
                { kind: "Field", name: { kind: "Name", value: "textBoxPromptColor" } },
                { kind: "Field", name: { kind: "Name", value: "submitButton" } },
                { kind: "Field", name: { kind: "Name", value: "submitButtonColor" } },
                { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "backgroundImage" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FeedbackPageUpdateMutation, FeedbackPageUpdateMutationVariables>;
export const GameResultsUpdateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "GameResultsUpdate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "GameResultsConfigurationUpdateInput" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "gameResultsId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "gameResultsUpdate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "gameResultsId" },
                value: { kind: "Variable", name: { kind: "Name", value: "gameResultsId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "scoreTitle_0_20" } },
                { kind: "Field", name: { kind: "Name", value: "scoreTitle_21_40" } },
                { kind: "Field", name: { kind: "Name", value: "scoreTitle_41_60" } },
                { kind: "Field", name: { kind: "Name", value: "scoreTitle_61_80" } },
                { kind: "Field", name: { kind: "Name", value: "scoreTitle_81_100" } },
                { kind: "Field", name: { kind: "Name", value: "scoreTag_0_20" } },
                { kind: "Field", name: { kind: "Name", value: "scoreTag_21_40" } },
                { kind: "Field", name: { kind: "Name", value: "scoreTag_41_60" } },
                { kind: "Field", name: { kind: "Name", value: "scoreTag_61_80" } },
                { kind: "Field", name: { kind: "Name", value: "scoreTag_81_100" } },
                { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "backgroundImage" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GameResultsUpdateMutation, GameResultsUpdateMutationVariables>;
export const WordleConfigurationUpdateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "WordleConfigurationUpdate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "WordleConfigurationUpdateInput" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "wordleConfigurationId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "wordleConfigurationUpdate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "wordleConfigurationId" },
                value: { kind: "Variable", name: { kind: "Name", value: "wordleConfigurationId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "showWelcomePage" } },
                { kind: "Field", name: { kind: "Name", value: "showFeedbackPage" } },
                { kind: "Field", name: { kind: "Name", value: "word" } },
                { kind: "Field", name: { kind: "Name", value: "numberOfTries" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WordleConfigurationUpdateMutation, WordleConfigurationUpdateMutationVariables>;
export const LibraryImageCreateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "LibraryImageCreate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "CreateLibraryImagesInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "libraryImageCreate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "LIBRARY_IMAGE_FIELDS" } }],
            },
          },
        ],
      },
    },
    ...Library_Image_FieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<LibraryImageCreateMutation, LibraryImageCreateMutationVariables>;
export const LibraryImageDeleteDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "LibraryImageDelete" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "libraryImageId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "libraryImageDelete" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "libraryImageId" },
                value: { kind: "Variable", name: { kind: "Name", value: "libraryImageId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LibraryImageDeleteMutation, LibraryImageDeleteMutationVariables>;
export const ProjectCreateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ProjectCreate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "CreateProjectInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "projectCreate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "ProjectFields" } }],
            },
          },
        ],
      },
    },
    ...ProjectFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ProjectCreateMutation, ProjectCreateMutationVariables>;
export const ProjectUpdateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ProjectUpdate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ProjectUpdateInput" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "projectId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "projectUpdate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "projectId" },
                value: { kind: "Variable", name: { kind: "Name", value: "projectId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "public" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "directory" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectUpdateMutation, ProjectUpdateMutationVariables>;
export const ProjectDeleteDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ProjectDelete" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "projectId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "projectDelete" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "projectId" },
                value: { kind: "Variable", name: { kind: "Name", value: "projectId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectDeleteMutation, ProjectDeleteMutationVariables>;
export const ProjectCopyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ProjectCopy" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "projectId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "projectCopy" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "projectId" },
                value: { kind: "Variable", name: { kind: "Name", value: "projectId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectCopyMutation, ProjectCopyMutationVariables>;
export const ProjectDeleteDataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ProjectDeleteData" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "projectId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "projectDeleteData" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "projectId" },
                value: { kind: "Variable", name: { kind: "Name", value: "projectId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectDeleteDataMutation, ProjectDeleteDataMutationVariables>;
export const TriviaAnswerOptionUpdateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "TriviaAnswerOptionUpdate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "triviaAnswerOptionId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "TriviaAnswerOptionUpdateInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "triviaAnswerOptionUpdate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "triviaAnswerOptionId" },
                value: { kind: "Variable", name: { kind: "Name", value: "triviaAnswerOptionId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "TriviaAnswerOptionFields" } }],
            },
          },
        ],
      },
    },
    ...TriviaAnswerOptionFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<TriviaAnswerOptionUpdateMutation, TriviaAnswerOptionUpdateMutationVariables>;
export const TriviaQuestionCreateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "TriviaQuestionCreate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "triviaConfigurationId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "TriviaQuestionCreateInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "triviaQuestionCreate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "triviaConfigurationId" },
                value: { kind: "Variable", name: { kind: "Name", value: "triviaConfigurationId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "TriviaQuestionFields" } }],
            },
          },
        ],
      },
    },
    ...TriviaQuestionFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<TriviaQuestionCreateMutation, TriviaQuestionCreateMutationVariables>;
export const TriviaQuestionUpdateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "TriviaQuestionUpdate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "triviaQuestionId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "TriviaQuestionUpdateInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "triviaQuestionUpdate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "triviaQuestionId" },
                value: { kind: "Variable", name: { kind: "Name", value: "triviaQuestionId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "TriviaQuestionFields" } }],
            },
          },
        ],
      },
    },
    ...TriviaQuestionFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<TriviaQuestionUpdateMutation, TriviaQuestionUpdateMutationVariables>;
export const TriviaQuestionDeleteDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "TriviaQuestionDelete" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "triviaQuestionId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "triviaQuestionDelete" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "triviaQuestionId" },
                value: { kind: "Variable", name: { kind: "Name", value: "triviaQuestionId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TriviaQuestionDeleteMutation, TriviaQuestionDeleteMutationVariables>;
export const SignUpDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SignUp" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "SignUpInput" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "signUp" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SignUpMutation, SignUpMutationVariables>;
export const SignInDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SignIn" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "email" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "password" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "signIn" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: { kind: "Variable", name: { kind: "Name", value: "email" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "password" },
                value: { kind: "Variable", name: { kind: "Name", value: "password" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "accessToken" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SignInMutation, SignInMutationVariables>;
export const SignInWithCodeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SignInWithCode" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "code" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "signInWithCode" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "code" },
                value: { kind: "Variable", name: { kind: "Name", value: "code" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "accessToken" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SignInWithCodeMutation, SignInWithCodeMutationVariables>;
export const UserInviteDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UserInvite" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "email" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userInvite" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: { kind: "Variable", name: { kind: "Name", value: "email" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "email" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserInviteMutation, UserInviteMutationVariables>;
export const UserUpdateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UserUpdate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UserUpdateInput" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userUpdate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserUpdateMutation, UserUpdateMutationVariables>;
export const UserUpdatePasswordDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UserUpdatePassword" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "UserUpdatePasswordInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userUpdatePassword" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserUpdatePasswordMutation, UserUpdatePasswordMutationVariables>;
export const UserDeleteDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UserDelete" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "userId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userDelete" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "userId" },
                value: { kind: "Variable", name: { kind: "Name", value: "userId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserDeleteMutation, UserDeleteMutationVariables>;
export const UserDeleteCurrentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UserDeleteCurrent" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userDeleteCurrent" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserDeleteCurrentMutation, UserDeleteCurrentMutationVariables>;
export const UserRequestPasswordResetDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "userRequestPasswordReset" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "email" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "token" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userRequestPasswordReset" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: { kind: "Variable", name: { kind: "Name", value: "email" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "token" },
                value: { kind: "Variable", name: { kind: "Name", value: "token" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserRequestPasswordResetMutation, UserRequestPasswordResetMutationVariables>;
export const StripeCheckoutCreateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "StripeCheckoutCreate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "priceId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "stripeCheckoutCreate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "priceId" },
                value: { kind: "Variable", name: { kind: "Name", value: "priceId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StripeCheckoutCreateMutation, StripeCheckoutCreateMutationVariables>;
export const UserResendInvitationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UserResendInvitation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "userId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userResendInvitation" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "userId" },
                value: { kind: "Variable", name: { kind: "Name", value: "userId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "email" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserResendInvitationMutation, UserResendInvitationMutationVariables>;
export const UserGrantAdminDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UserGrantAdmin" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "userId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userGrantAdmin" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "userId" },
                value: { kind: "Variable", name: { kind: "Name", value: "userId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "role" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserGrantAdminMutation, UserGrantAdminMutationVariables>;
export const UserRevokeAdminDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UserRevokeAdmin" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "userId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userRevokeAdmin" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "userId" },
                value: { kind: "Variable", name: { kind: "Name", value: "userId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "role" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserRevokeAdminMutation, UserRevokeAdminMutationVariables>;
export const StripePortalSessionCreateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "StripePortalSessionCreate" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "stripePortalSessionCreate" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "url" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StripePortalSessionCreateMutation, StripePortalSessionCreateMutationVariables>;
export const VideoCreateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "VideoCreate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "CreateVideoInput" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "videoCreate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "VideoFields" } }],
            },
          },
        ],
      },
    },
    ...VideoFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<VideoCreateMutation, VideoCreateMutationVariables>;
export const VideoDeleteDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "VideoDelete" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "videoId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "videoDelete" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "videoId" },
                value: { kind: "Variable", name: { kind: "Name", value: "videoId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VideoDeleteMutation, VideoDeleteMutationVariables>;
export const GameAllDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GameAll" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "projectId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "gameAll" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "projectId" },
                value: { kind: "Variable", name: { kind: "Name", value: "projectId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "FragmentSpread", name: { kind: "Name", value: "GameFields" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "configuration" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "InlineFragment",
                        typeCondition: { kind: "NamedType", name: { kind: "Name", value: "SwiperConfiguration" } },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "FragmentSpread", name: { kind: "Name", value: "SwiperConfigurationFields" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "attributes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "FragmentSpread", name: { kind: "Name", value: "AttributeFields" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "gameVisuals" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "themeColor" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cardBackImage" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "showIcons" } },
                                  { kind: "Field", name: { kind: "Name", value: "iconSet" } },
                                  { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "backgroundPositiveColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "backgroundNegativeColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "positiveColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "negativeColor" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "backgroundImage" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "welcomePage" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "welcomeLine" } },
                                  { kind: "Field", name: { kind: "Name", value: "welcomeLineColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "subheading" } },
                                  { kind: "Field", name: { kind: "Name", value: "subheadingColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "tagLine" } },
                                  { kind: "Field", name: { kind: "Name", value: "tagLineColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "button" } },
                                  { kind: "Field", name: { kind: "Name", value: "buttonColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "backgroundImage" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "logo" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "feedbackPage" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "title" } },
                                  { kind: "Field", name: { kind: "Name", value: "titleColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "instructionLine" } },
                                  { kind: "Field", name: { kind: "Name", value: "instructionLineColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "textBoxPrompt" } },
                                  { kind: "Field", name: { kind: "Name", value: "textBoxPromptColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "submitButton" } },
                                  { kind: "Field", name: { kind: "Name", value: "submitButtonColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "backgroundImage" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "gameResults" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "scoreTitle_0_20" } },
                                  { kind: "Field", name: { kind: "Name", value: "scoreTitle_21_40" } },
                                  { kind: "Field", name: { kind: "Name", value: "scoreTitle_41_60" } },
                                  { kind: "Field", name: { kind: "Name", value: "scoreTitle_61_80" } },
                                  { kind: "Field", name: { kind: "Name", value: "scoreTitle_81_100" } },
                                  { kind: "Field", name: { kind: "Name", value: "scoreTag_0_20" } },
                                  { kind: "Field", name: { kind: "Name", value: "scoreTag_21_40" } },
                                  { kind: "Field", name: { kind: "Name", value: "scoreTag_41_60" } },
                                  { kind: "Field", name: { kind: "Name", value: "scoreTag_61_80" } },
                                  { kind: "Field", name: { kind: "Name", value: "scoreTag_81_100" } },
                                  { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "backgroundImage" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: { kind: "NamedType", name: { kind: "Name", value: "RankifierConfiguration" } },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "mostQuestion" } },
                            { kind: "Field", name: { kind: "Name", value: "leastQuestion" } },
                            { kind: "Field", name: { kind: "Name", value: "mostQuestionColor" } },
                            { kind: "Field", name: { kind: "Name", value: "leastQuestionColor" } },
                            { kind: "Field", name: { kind: "Name", value: "cardsPerHand" } },
                            { kind: "Field", name: { kind: "Name", value: "numberOfRounds" } },
                            { kind: "Field", name: { kind: "Name", value: "showWelcomePage" } },
                            { kind: "Field", name: { kind: "Name", value: "showGameResults" } },
                            { kind: "Field", name: { kind: "Name", value: "showFeedbackPage" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "attributes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "FragmentSpread", name: { kind: "Name", value: "AttributeFields" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "gameVisuals" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "themeColor" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cardBackImage" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "showIcons" } },
                                  { kind: "Field", name: { kind: "Name", value: "iconSet" } },
                                  { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "backgroundPositiveColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "backgroundNegativeColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "positiveColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "negativeColor" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "backgroundImage" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "welcomePage" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "welcomeLine" } },
                                  { kind: "Field", name: { kind: "Name", value: "welcomeLineColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "subheading" } },
                                  { kind: "Field", name: { kind: "Name", value: "subheadingColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "tagLine" } },
                                  { kind: "Field", name: { kind: "Name", value: "tagLineColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "button" } },
                                  { kind: "Field", name: { kind: "Name", value: "buttonColor" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "logo" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "backgroundImage" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "feedbackPage" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "title" } },
                                  { kind: "Field", name: { kind: "Name", value: "titleColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "instructionLine" } },
                                  { kind: "Field", name: { kind: "Name", value: "instructionLineColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "textBoxPrompt" } },
                                  { kind: "Field", name: { kind: "Name", value: "textBoxPromptColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "submitButton" } },
                                  { kind: "Field", name: { kind: "Name", value: "submitButtonColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "backgroundImage" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "gameResults" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "scoreTitle_0_20" } },
                                  { kind: "Field", name: { kind: "Name", value: "scoreTitle_21_40" } },
                                  { kind: "Field", name: { kind: "Name", value: "scoreTitle_41_60" } },
                                  { kind: "Field", name: { kind: "Name", value: "scoreTitle_61_80" } },
                                  { kind: "Field", name: { kind: "Name", value: "scoreTitle_81_100" } },
                                  { kind: "Field", name: { kind: "Name", value: "scoreTag_0_20" } },
                                  { kind: "Field", name: { kind: "Name", value: "scoreTag_21_40" } },
                                  { kind: "Field", name: { kind: "Name", value: "scoreTag_41_60" } },
                                  { kind: "Field", name: { kind: "Name", value: "scoreTag_61_80" } },
                                  { kind: "Field", name: { kind: "Name", value: "scoreTag_81_100" } },
                                  { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "backgroundImage" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: { kind: "NamedType", name: { kind: "Name", value: "WordopolisConfiguration" } },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "topic" } },
                            { kind: "Field", name: { kind: "Name", value: "difficulty" } },
                            { kind: "Field", name: { kind: "Name", value: "numberOfRows" } },
                            { kind: "Field", name: { kind: "Name", value: "numberOfColumns" } },
                            { kind: "Field", name: { kind: "Name", value: "isTimeLimited" } },
                            { kind: "Field", name: { kind: "Name", value: "limitedTime" } },
                            { kind: "Field", name: { kind: "Name", value: "showWords" } },
                            { kind: "Field", name: { kind: "Name", value: "startText" } },
                            { kind: "Field", name: { kind: "Name", value: "showWelcomePage" } },
                            { kind: "Field", name: { kind: "Name", value: "showRatingScale" } },
                            { kind: "Field", name: { kind: "Name", value: "ratingQuestion" } },
                            { kind: "Field", name: { kind: "Name", value: "ratingLeftLabel" } },
                            { kind: "Field", name: { kind: "Name", value: "ratingRightLabel" } },
                            { kind: "Field", name: { kind: "Name", value: "ratingScaleSize" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "boardBackgroundImage" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "name" } },
                                  { kind: "Field", name: { kind: "Name", value: "url" } },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "boardBackgroundColor" } },
                            { kind: "Field", name: { kind: "Name", value: "boardColor" } },
                            { kind: "Field", name: { kind: "Name", value: "board" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "attributes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "FragmentSpread", name: { kind: "Name", value: "AttributeFields" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "gameVisuals" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "themeColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "showIcons" } },
                                  { kind: "Field", name: { kind: "Name", value: "iconSet" } },
                                  { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "backgroundPositiveColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "backgroundNegativeColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "positiveColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "negativeColor" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "backgroundImage" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "welcomePage" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "welcomeLine" } },
                                  { kind: "Field", name: { kind: "Name", value: "welcomeLineColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "subheading" } },
                                  { kind: "Field", name: { kind: "Name", value: "subheadingColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "tagLine" } },
                                  { kind: "Field", name: { kind: "Name", value: "tagLineColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "button" } },
                                  { kind: "Field", name: { kind: "Name", value: "buttonColor" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "logo" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "backgroundImage" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "showLeaderboard" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "leaderboardConfiguration" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "nicknameQuestion" } },
                                  { kind: "Field", name: { kind: "Name", value: "nicknameQuestionColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "header" } },
                                  { kind: "Field", name: { kind: "Name", value: "headerColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "nicknameLabel" } },
                                  { kind: "Field", name: { kind: "Name", value: "numberOfPlayers" } },
                                  { kind: "Field", name: { kind: "Name", value: "showTimer" } },
                                  { kind: "Field", name: { kind: "Name", value: "subheader" } },
                                  { kind: "Field", name: { kind: "Name", value: "subheaderColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "tableNicknameLabel" } },
                                  { kind: "Field", name: { kind: "Name", value: "tableScoreLabel" } },
                                  { kind: "Field", name: { kind: "Name", value: "tableTimeLabel" } },
                                  { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "backgroundImage" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: { kind: "NamedType", name: { kind: "Name", value: "QuickRankConfiguration" } },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "question" } },
                            { kind: "Field", name: { kind: "Name", value: "mode" } },
                            { kind: "Field", name: { kind: "Name", value: "showRatingScale" } },
                            { kind: "Field", name: { kind: "Name", value: "ratingQuestion" } },
                            { kind: "Field", name: { kind: "Name", value: "ratingScaleSize" } },
                            { kind: "Field", name: { kind: "Name", value: "ratingRightLabel" } },
                            { kind: "Field", name: { kind: "Name", value: "ratingLeftLabel" } },
                            { kind: "Field", name: { kind: "Name", value: "showWelcomePage" } },
                            { kind: "Field", name: { kind: "Name", value: "showGameResults" } },
                            { kind: "Field", name: { kind: "Name", value: "showFeedbackPage" } },
                            { kind: "Field", name: { kind: "Name", value: "shuffleCards" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "attributes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "FragmentSpread", name: { kind: "Name", value: "AttributeFields" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "gameVisuals" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "themeColor" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cardBackImage" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "showIcons" } },
                                  { kind: "Field", name: { kind: "Name", value: "iconSet" } },
                                  { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "backgroundPositiveColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "backgroundNegativeColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "positiveColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "negativeColor" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "backgroundImage" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "welcomePage" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "welcomeLine" } },
                                  { kind: "Field", name: { kind: "Name", value: "welcomeLineColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "subheading" } },
                                  { kind: "Field", name: { kind: "Name", value: "subheadingColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "tagLine" } },
                                  { kind: "Field", name: { kind: "Name", value: "tagLineColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "button" } },
                                  { kind: "Field", name: { kind: "Name", value: "buttonColor" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "logo" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "backgroundImage" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "feedbackPage" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "title" } },
                                  { kind: "Field", name: { kind: "Name", value: "titleColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "instructionLine" } },
                                  { kind: "Field", name: { kind: "Name", value: "instructionLineColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "textBoxPrompt" } },
                                  { kind: "Field", name: { kind: "Name", value: "textBoxPromptColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "submitButton" } },
                                  { kind: "Field", name: { kind: "Name", value: "submitButtonColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "backgroundImage" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "gameResults" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "scoreTitle_0_20" } },
                                  { kind: "Field", name: { kind: "Name", value: "scoreTitle_21_40" } },
                                  { kind: "Field", name: { kind: "Name", value: "scoreTitle_41_60" } },
                                  { kind: "Field", name: { kind: "Name", value: "scoreTitle_61_80" } },
                                  { kind: "Field", name: { kind: "Name", value: "scoreTitle_81_100" } },
                                  { kind: "Field", name: { kind: "Name", value: "scoreTag_0_20" } },
                                  { kind: "Field", name: { kind: "Name", value: "scoreTag_21_40" } },
                                  { kind: "Field", name: { kind: "Name", value: "scoreTag_41_60" } },
                                  { kind: "Field", name: { kind: "Name", value: "scoreTag_61_80" } },
                                  { kind: "Field", name: { kind: "Name", value: "scoreTag_81_100" } },
                                  { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "backgroundImage" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "showLeaderboard" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "leaderboardConfiguration" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "nicknameQuestion" } },
                                  { kind: "Field", name: { kind: "Name", value: "nicknameQuestionColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "header" } },
                                  { kind: "Field", name: { kind: "Name", value: "headerColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "nicknameLabel" } },
                                  { kind: "Field", name: { kind: "Name", value: "numberOfPlayers" } },
                                  { kind: "Field", name: { kind: "Name", value: "showTimer" } },
                                  { kind: "Field", name: { kind: "Name", value: "subheader" } },
                                  { kind: "Field", name: { kind: "Name", value: "subheaderColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "tableNicknameLabel" } },
                                  { kind: "Field", name: { kind: "Name", value: "tableScoreLabel" } },
                                  { kind: "Field", name: { kind: "Name", value: "tableTimeLabel" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: { kind: "NamedType", name: { kind: "Name", value: "TriviaConfiguration" } },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "FragmentSpread", name: { kind: "Name", value: "TriviaConfigurationFields" } },
                            { kind: "Field", name: { kind: "Name", value: "showLeaderboard" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "leaderboardConfiguration" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "nicknameQuestion" } },
                                  { kind: "Field", name: { kind: "Name", value: "nicknameQuestionColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "header" } },
                                  { kind: "Field", name: { kind: "Name", value: "headerColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "nicknameLabel" } },
                                  { kind: "Field", name: { kind: "Name", value: "numberOfPlayers" } },
                                  { kind: "Field", name: { kind: "Name", value: "showTimer" } },
                                  { kind: "Field", name: { kind: "Name", value: "subheader" } },
                                  { kind: "Field", name: { kind: "Name", value: "subheaderColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "tableNicknameLabel" } },
                                  { kind: "Field", name: { kind: "Name", value: "tableScoreLabel" } },
                                  { kind: "Field", name: { kind: "Name", value: "tableTimeLabel" } },
                                  { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "backgroundImage" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: { kind: "NamedType", name: { kind: "Name", value: "WordleConfiguration" } },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "FragmentSpread", name: { kind: "Name", value: "WordleConfigurationFields" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "gameVisuals" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "themeColor" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cardBackImage" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "showIcons" } },
                                  { kind: "Field", name: { kind: "Name", value: "iconSet" } },
                                  { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "backgroundPositiveColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "backgroundNegativeColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "positiveColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "negativeColor" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "backgroundImage" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "welcomePage" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "welcomeLine" } },
                                  { kind: "Field", name: { kind: "Name", value: "welcomeLineColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "subheading" } },
                                  { kind: "Field", name: { kind: "Name", value: "subheadingColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "tagLine" } },
                                  { kind: "Field", name: { kind: "Name", value: "tagLineColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "button" } },
                                  { kind: "Field", name: { kind: "Name", value: "buttonColor" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "logo" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "backgroundImage" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "feedbackPage" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "title" } },
                                  { kind: "Field", name: { kind: "Name", value: "titleColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "instructionLine" } },
                                  { kind: "Field", name: { kind: "Name", value: "instructionLineColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "textBoxPrompt" } },
                                  { kind: "Field", name: { kind: "Name", value: "textBoxPromptColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "submitButton" } },
                                  { kind: "Field", name: { kind: "Name", value: "submitButtonColor" } },
                                  { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "backgroundImage" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...GameFieldsFragmentDoc.definitions,
    ...SwiperConfigurationFieldsFragmentDoc.definitions,
    ...AttributeFieldsFragmentDoc.definitions,
    ...TriviaConfigurationFieldsFragmentDoc.definitions,
    ...WordleConfigurationFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GameAllQuery, GameAllQueryVariables>;
export const GameAllIdsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GameAllIds" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "projectId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "gameAll" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "projectId" },
                value: { kind: "Variable", name: { kind: "Name", value: "projectId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "kind" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "configuration" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "InlineFragment",
                        typeCondition: { kind: "NamedType", name: { kind: "Name", value: "QuickRankConfiguration" } },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "mode" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GameAllIdsQuery, GameAllIdsQueryVariables>;
export const LibraryImageAllDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "LibraryImageAll" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "libraryImageAll" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "LIBRARY_IMAGE_FIELDS" } }],
            },
          },
        ],
      },
    },
    ...Library_Image_FieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<LibraryImageAllQuery, LibraryImageAllQueryVariables>;
export const ProjectDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Project" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "projectId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "project" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "projectId" },
                value: { kind: "Variable", name: { kind: "Name", value: "projectId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "ProjectFields" } }],
            },
          },
        ],
      },
    },
    ...ProjectFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ProjectQuery, ProjectQueryVariables>;
export const ProjectListDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ProjectList" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "projectList" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "FragmentSpread", name: { kind: "Name", value: "ProjectFields" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "games" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "responseCount" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ProjectFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ProjectListQuery, ProjectListQueryVariables>;
export const DirectoryListDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "DirectoryList" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "directoryList" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "FragmentSpread", name: { kind: "Name", value: "DIRECTORY_FIELDS" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parentDirectory" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "DIRECTORY_FIELDS" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...Directory_FieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<DirectoryListQuery, DirectoryListQueryVariables>;
export const ProjectPublicDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ProjectPublic" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "projectId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "projectPublic" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "projectId" },
                value: { kind: "Variable", name: { kind: "Name", value: "projectId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "public" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "games" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "kind" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "responseCount" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "configuration" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "QuickRankConfiguration" },
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "showRatingScale" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "configuration" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "RankifierConfiguration" },
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "configuration" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "SwiperConfiguration" },
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "configuration" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "WordopolisConfiguration" },
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "showRatingScale" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "configuration" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "TriviaConfiguration" },
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "configuration" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "WordleConfiguration" },
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectPublicQuery, ProjectPublicQueryVariables>;
export const SwiperReportDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SwiperReport" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "gameId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "swiperReport" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "gameId" },
                value: { kind: "Variable", name: { kind: "Name", value: "gameId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalResponses" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "attributeResults" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attribute" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "totalScore" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SwiperReportQuery, SwiperReportQueryVariables>;
export const RankifierReportDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "RankifierReport" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "gameId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "rankifierReport" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "gameId" },
                value: { kind: "Variable", name: { kind: "Name", value: "gameId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalResponses" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "attributeResults" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attribute" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "minuses" } },
                      { kind: "Field", name: { kind: "Name", value: "pluses" } },
                      { kind: "Field", name: { kind: "Name", value: "score" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RankifierReportQuery, RankifierReportQueryVariables>;
export const QuickRankReportDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "QuickRankReport" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "gameId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "quickRankReport" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "gameId" },
                value: { kind: "Variable", name: { kind: "Name", value: "gameId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "responseCount" } },
                { kind: "Field", name: { kind: "Name", value: "totalAttributes" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "attributeResults" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attribute" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "avgRank" } },
                      { kind: "Field", name: { kind: "Name", value: "avgRating" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QuickRankReportQuery, QuickRankReportQueryVariables>;
export const WordopolisReportDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "WordopolisReport" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "gameId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "wordopolisReport" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "gameId" },
                value: { kind: "Variable", name: { kind: "Name", value: "gameId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalResponses" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "attributeResults" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attribute" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "avgScore" } },
                      { kind: "Field", name: { kind: "Name", value: "avgRating" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WordopolisReportQuery, WordopolisReportQueryVariables>;
export const TriviaReportDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "TriviaReport" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "gameId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "triviaReport" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "gameId" },
                value: { kind: "Variable", name: { kind: "Name", value: "gameId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "questionResults" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "question" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "question" } },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "scores" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "count" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "option" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "text" } },
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "totalResponses" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TriviaReportQuery, TriviaReportQueryVariables>;
export const GameResponsesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GameResponses" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "gameId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "game" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "gameId" },
                value: { kind: "Variable", name: { kind: "Name", value: "gameId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "responses" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GameResponsesQuery, GameResponsesQueryVariables>;
export const TriviaResponseReportDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "TriviaResponseReport" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "gameId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "responseId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "triviaResponseReport" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "gameId" },
                value: { kind: "Variable", name: { kind: "Name", value: "gameId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "responseId" },
                value: { kind: "Variable", name: { kind: "Name", value: "responseId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "numberOfCorrectAnswers" } },
                { kind: "Field", name: { kind: "Name", value: "numberOfwrongAnswers" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "scores" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "answer" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "text" } },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "isCorrect" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "question" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "question" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "options" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "text" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TriviaResponseReportQuery, TriviaResponseReportQueryVariables>;
export const WordleReportDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "WordleReport" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "gameId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "wordleReport" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "gameId" },
                value: { kind: "Variable", name: { kind: "Name", value: "gameId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalResponses" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "scoreResults" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "score" } },
                      { kind: "Field", name: { kind: "Name", value: "count" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WordleReportQuery, WordleReportQueryVariables>;
export const UserListDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "UserList" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userList" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "USER_FIELDS" } }],
            },
          },
        ],
      },
    },
    ...User_FieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UserListQuery, UserListQueryVariables>;
export const UserMeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "UserMe" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userMe" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "emailVerifiedAt" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                { kind: "Field", name: { kind: "Name", value: "role" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organization" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subscription" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "status" } },
                            { kind: "Field", name: { kind: "Name", value: "planName" } },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "manuallyManagedPlan" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserMeQuery, UserMeQueryVariables>;
