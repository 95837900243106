import { HTMLProps, ReactElement } from "react";
import classNames from "classnames/bind";

import { Header } from "../Header";

import styles from "./IconButton.module.scss";

const cx = classNames.bind(styles);

export type IconButtonProps = Omit<HTMLProps<HTMLButtonElement>, "size"> & {
  text?: string | null;
  type?: "button" | "submit";
  size?: "small" | "medium" | "large";
  icon: ReactElement;
  className?: string;
  style?: "primary" | "outline";
  onClick?: () => void;
};

export const IconButton = ({
  className,
  text,
  type = "button",
  size = "medium",
  style,
  icon,
  onClick,
  ...restProps
}: IconButtonProps) => {
  return (
    // eslint-disable-next-line
    <button {...restProps} type={type} className={cx("backButtonWrapper", className, size, style)} onClick={onClick}>
      <div className={styles.backButton}>{icon}</div>
      {text && (
        <Header variant="h6" className={styles.backText}>
          {text}
        </Header>
      )}
    </button>
  );
};
