import classNames from "classnames/bind";

import { Button, ButtonProps } from "../Button";

import styles from "./ResponsiveButton.module.scss";

const cx = classNames.bind(styles);

export const ResponsiveButton = ({ children, className, size, ...restProps }: ButtonProps) => {
  return (
    <Button {...restProps} className={cx("responsiveButton", size, className)}>
      {children}
    </Button>
  );
};
