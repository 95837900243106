import classNames from "classnames/bind";
import { CSSProperties, type PropsWithChildren } from "react";

import styles from "./Header.module.scss";

const cx = classNames.bind(styles);

export type HeaderVariants = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

export type HeaderProps = PropsWithChildren<{
  className?: string;
  style?: CSSProperties;
  color?: "primary" | "secondary" | "neutrals-600" | "orange" | "white";
  variant: HeaderVariants;
}>;

export const Header = ({ children, className, style, variant, color = "primary" }: HeaderProps) => {
  const headerClassName = cx(variant, className, "header", color);

  const ElementComponent = variant;

  return (
    <ElementComponent className={headerClassName} style={style}>
      {children}
    </ElementComponent>
  );
};
