import { useState } from "react";
import classNames from "classnames/bind";

import { Button } from "../Button";
import { Header } from "../Header";
import { Paragraph } from "../Paragraph";
import { Rating, RatingItem } from "./RatingItem";
import { SendIcon } from "../../assets/icons";
import Awesome from "../../assets/images/feedback/awesome.png";
import FeedbackPageImage from "../../assets/images/feedback/feedbackPageImage.png";
import Good from "../../assets/images/feedback/good.png";
import NotGood from "../../assets/images/feedback/notGood.png";
import Terrible from "../../assets/images/feedback/terrible.png";
import VeryGood from "../../assets/images/feedback/veryGood.png";

import styles from "./FeedbackPageForm.module.scss";

const cx = classNames.bind(styles);

export interface FeedbackPageFormProps {
  config: {
    title: string;
    titleColor: string;
    instructionLine: string;
    instructionLineColor: string;
    textBoxPrompt: string;
    textBoxPromptColor: string;
    submitButton: string;
    submitButtonColor: string;
  };
  theme: string;
  onSubmit?: (rating: number) => void;
  className?: string;
}

export const FeedbackPageForm = ({ config, theme, onSubmit, className }: FeedbackPageFormProps) => {
  const [selectedRating, setSelectedRating] = useState<number>(0);
  const ratingOptions: Rating[] = [
    {
      id: "1",
      value: 1,
      image: Terrible,
      label: "Terrible",
    },
    {
      id: "2",
      value: 2,
      image: NotGood,
      label: "Not good",
    },
    {
      id: "3",
      value: 3,
      image: Good,
      label: "Good",
    },
    {
      id: "4",
      value: 4,
      image: VeryGood,
      label: "Very Good",
    },
    {
      id: "5",
      value: 5,
      image: Awesome,
      label: "Awesome",
    },
  ];

  const handleSubmit = () => {
    onSubmit?.(selectedRating);
  };

  return (
    <div className={cx("form", className)}>
      <img className={styles.image} src={FeedbackPageImage} alt="feedback-page" />

      <div className={styles.textContent}>
        <div>
          {config.title && (
            <Header className={styles.header} variant="h6" style={{ color: config.titleColor }}>
              {config.title}
            </Header>
          )}

          {config.instructionLine && (
            <Header className={styles.instruction} variant="h5" style={{ color: config.instructionLineColor }}>
              {config.instructionLine}
            </Header>
          )}

          {config.textBoxPrompt && (
            <Paragraph className={styles.question} size="small" style={{ color: config.textBoxPromptColor }}>
              {config.textBoxPrompt}
            </Paragraph>
          )}

          <div className={styles.ratings}>
            {ratingOptions.map((option) => {
              return (
                <RatingItem
                  key={option.id}
                  rating={option}
                  isActive={selectedRating === option.value}
                  onClick={setSelectedRating}
                />
              );
            })}
          </div>
        </div>

        <Button
          size="large"
          className={styles.button}
          key={config.submitButtonColor}
          icon={<SendIcon />}
          iconSide="left"
          disabled={selectedRating === 0}
          style={{ color: config.submitButtonColor, background: theme }}
          onClick={handleSubmit}
        >
          {config.submitButton || "Send"}
        </Button>
      </div>
    </div>
  );
};
