import classNames from "classnames/bind";
import { HTMLProps, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  COLOR_BRAND_ORANGE_BASE,
  MINUTE,
  SECOND,
  TIMER_ANIMATION,
  TIMER_CIRCLE_ATTRIBUTE,
  TIMER_CIRCLE_PERCENTAGE,
  TIMER_CIRCLE_ROTATION,
} from "../../../constants";
import { useTime } from "../../../hooks/useTime";

import styles from "./Timer.module.scss";

const cx = classNames.bind(styles);

export enum TimerVariant {
  DEFAULT = "default",
  CIRCLE = "circle",
}

export enum TimerSize {
  SMALL = "small",
  MEDIUM = "medium",
}

export function radialAnimate(currentTime: number, duration: number, variant: TimerVariant, transition = true) {
  const percentLeft = (currentTime / duration) * 100;
  const animation = transition ? TIMER_ANIMATION : { ...TIMER_ANIMATION, duration: 0 };

  const radialProgress: NodeListOf<HTMLElement> = document.querySelectorAll(`.${styles.radialProgress}`);
  radialProgress.forEach((value) => {
    document.querySelectorAll(`.${styles.radialProgress}`).forEach((element) => {
      element.querySelector(`.${styles.barAnimated}`)?.removeAttribute("style");
    });

    const percent = percentLeft * TIMER_CIRCLE_PERCENTAGE[variant];
    const radius = Number(value.querySelector(`.${styles.barAnimated}`)?.getAttribute("r")) ?? 0;
    const circumference = 2 * Math.PI * radius;
    const strokeDashOffset = circumference - (percent * circumference) / 100;
    const barAnimated = value.querySelector(`.${styles.barAnimated}`) as SVGPathElement;
    const barDot = value.querySelector(`.${styles.barDot}`) as SVGPathElement;
    const barDotRotation =
      (percentLeft * (360 * TIMER_CIRCLE_PERCENTAGE[variant])) / 100 + TIMER_CIRCLE_ROTATION[variant];

    barAnimated?.animate({ strokeDashoffset: strokeDashOffset }, animation);
    barDot?.animate({ transform: `rotate(${barDotRotation}deg)` }, animation);

    if (currentTime < MINUTE) {
      barAnimated?.animate({ stroke: COLOR_BRAND_ORANGE_BASE }, { ...animation, duration: SECOND });
    }

    if (percentLeft === 0) {
      [barAnimated, barDot].forEach((element) => {
        element?.animate({ opacity: 0 }, animation);
      });
    }
  });
}

export interface TimerProps extends Omit<HTMLProps<HTMLDivElement>, "size"> {
  variant?: TimerVariant;
  size?: TimerSize;
}

export const Timer = ({ className, variant = TimerVariant.DEFAULT, size = TimerSize.MEDIUM, ...props }: TimerProps) => {
  const { t: translate } = useTranslation("common");
  const t = (key: string) => translate(`timer.${key}`);
  const { uiTime, totalTime } = useTime();
  const [isOutOfTime, setIsOutOfTime] = useState(false);
  const displayTimeLeft = Math.floor(uiTime / SECOND) * SECOND;
  const timeToDisplay = displayTimeLeft;

  useEffect(() => {
    if (uiTime === 0) {
      setIsOutOfTime(true);
    }

    radialAnimate(uiTime, totalTime, variant, uiTime !== totalTime);
  }, [uiTime]);

  // MM:SS format
  const minutes = Math.floor(timeToDisplay / SECOND / 60);
  const minutesString = minutes < 10 ? `0${minutes}` : minutes;
  const seconds = Math.floor((timeToDisplay / SECOND) % 60);
  const secondsString = seconds < 10 ? `0${seconds}` : seconds;
  const timeLeftString = `${minutesString}:${secondsString}`;
  const isBelowMinute = timeToDisplay < SECOND * 60;

  let timerMessage = t("loadsOfTime");

  if (isBelowMinute) {
    timerMessage = t("lowTime");
  }
  if (isOutOfTime) {
    timerMessage = t("noTime.part1");
  }

  return (
    <div
      {...props}
      className={cx("timerWrapper", className, {
        small: size === TimerSize.SMALL,
        circle: variant === TimerVariant.CIRCLE,
      })}
    >
      <svg className={styles.radialProgress} viewBox="0 0 80 80">
        <circle className={styles.barStatic} {...TIMER_CIRCLE_ATTRIBUTE} />
        <circle className={styles.barAnimated} {...TIMER_CIRCLE_ATTRIBUTE} />
        <circle className={styles.barDot} {...TIMER_CIRCLE_ATTRIBUTE} />
      </svg>
      <div className={styles.timerText}>
        <span className={cx("timerMessage", { lowTime: isBelowMinute })}>{timerMessage}</span>
        <span className={styles.timeLeft}>{isOutOfTime ? t("noTime.part2") : timeLeftString}</span>
      </div>
    </div>
  );
};
