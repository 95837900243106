import { CSSProperties, ReactElement, SVGProps, cloneElement, useEffect, useMemo } from "react";

export interface IconWithCustomColorProps extends Omit<SVGProps<SVGElement>, "size"> {
  icon: ReactElement;
  innerFill?: string;
}

export const IconWithCustomColor = ({ icon, innerFill, ...rest }: IconWithCustomColorProps) => {
  // generate long random id for icon
  const iconId = useMemo(() => {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  }, []);

  useEffect(() => {
    if (!innerFill) {
      return;
    }

    const svg: HTMLElement | null = document.getElementById(iconId);

    if (!svg) {
      return;
    }

    for (const child of svg.children) {
      if ("style" in child) {
        (child.style as CSSProperties).fill = innerFill;
        (child.style as CSSProperties).stroke = innerFill;
      }
    }
  }, [innerFill]);

  return cloneElement(icon, {
    ...rest,
    id: iconId,
  });
};
