import { ChangeEventHandler, useMemo } from "react";
import classNames from "classnames/bind";

import { CheckIcon, CloseIcon } from "../../assets/icons";
import { IconWithCustomColor } from "../IconWithCustomColor";

import styles from "./ToggleInput.module.scss";

const cx = classNames.bind(styles);

export type ToggleInputProps = {
  children?: React.ReactNode;
  className?: string;
  label?: string;
  name: string;
  customColor?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  value: boolean;
  tooltip?: React.ReactNode;
};

export const ToggleInput = (props: ToggleInputProps) => {
  const { value, name, customColor, children, onChange, className, tooltip } = props;

  const id = useMemo(() => `toggle-input-${name}`, [name]);

  const checkIcon = useMemo(() => <IconWithCustomColor icon={<CheckIcon />} innerFill={customColor} />, [customColor]);

  return (
    <div className={cx("toggleInput", className)}>
      <div className={styles.inputWrapper}>
        <input
          style={value ? { backgroundColor: customColor } : {}}
          id={id}
          checked={value}
          onChange={onChange}
          className={styles.input}
          type="checkbox"
        />
        <div className={styles.toggle}>{value ? checkIcon : <CloseIcon />}</div>
      </div>
      <label htmlFor={id} className={styles.label}>
        {props.label}
      </label>
      {children}
      {tooltip}
    </div>
  );
};
