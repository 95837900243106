import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";

import "@datagame/ui/styles/application.scss";
import "./initializers/i18n";
import UserContextProvider from "./contexts/user/UserContext";
import { Loader } from "@datagame/ui";
import { App } from "./App";

const IS_PRODUCTION = import.meta.env.PROD;
const LOGROCKET_APP_ID = import.meta.env.VITE_LOGROCKET_APP_ID;
const API_HOST = import.meta.env.VITE_API_HOST;

const fileUploadLink = createUploadLink({
  uri: `${API_HOST}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const logout = () => {
  localStorage.removeItem("token");
  location.reload();
};

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    });
  }
  if (networkError && "statusCode" in networkError && networkError.statusCode === 401) {
    logout();
  }
});

const client = new ApolloClient({
  link: authLink.concat(errorLink.concat(fileUploadLink)),
  cache: new InMemoryCache(),
});

if (IS_PRODUCTION) {
  LogRocket.init(LOGROCKET_APP_ID);
  setupLogRocketReact(LogRocket);
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <ApolloProvider client={client}>
    <React.StrictMode>
      <BrowserRouter>
        <Suspense fallback={<Loader />}>
          <UserContextProvider>
            <App />
          </UserContextProvider>
        </Suspense>
      </BrowserRouter>
    </React.StrictMode>
  </ApolloProvider>,
);
