import { use } from "i18next";
import { initReactI18next } from "react-i18next";
import en from "../locales/en";

const resources = {
  en,
};

use(initReactI18next).init({
  lng: "en",
  resources: resources,
  interpolation: {
    escapeValue: false,
  },
  ns: ["common", "account", "user"],
});
