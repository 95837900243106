import classNames from "classnames/bind";

import { LeaderboardRow, Row } from "./Row";
import { LeaderboardConfiguration } from "../Leaderboard";

import styles from "./Table.module.scss";

const cx = classNames.bind(styles);

export interface TableProps {
  rows: LeaderboardRow[];
  currentRow?: LeaderboardRow;
  configuration: LeaderboardConfiguration;
  themeColor?: string;
}

export const Table = (props: TableProps) => {
  const { rows, configuration, currentRow, themeColor } = props;
  const { tableNicknameLabel, tableTimeLabel, tableScoreLabel, showTimer } = configuration;

  const sortedRows = [...rows].sort((a, b) => a.rank - b.rank);
  const isCurrentRowOnList = currentRow && rows.find((row) => row.nickname === currentRow.nickname);

  return (
    <div className={styles.table}>
      <div className={styles.header}>
        <div className={styles.rank}>Rank</div>
        <div className={styles.username}>{tableNicknameLabel}</div>
        <div className={cx("time", { hideTime: !showTimer })}>{tableTimeLabel}</div>
        <div className={styles.points}>{tableScoreLabel}</div>
      </div>
      <div className={styles.rows}>
        {!isCurrentRowOnList && currentRow && (
          <Row
            isActive={true}
            themeColor={themeColor}
            showTimer={showTimer}
            record={currentRow}
            className={cx("active", "detached")}
          />
        )}
        {sortedRows.map((record) => (
          <Row
            key={record.nickname}
            themeColor={themeColor}
            showTimer={showTimer}
            record={record}
            isActive={record.nickname === currentRow?.nickname}
            className={cx({ active: record.nickname === currentRow?.nickname })}
          />
        ))}
      </div>
    </div>
  );
};
