import { CardNeutral400, CardNeutral500, CardNeutral600 } from "../../assets/images/cards";

import styles from "./Loader.module.scss";

export const Loader = () => {
  return (
    <div className={styles.loader}>
      <div className={styles.cards}>
        <CardNeutral600 className={styles.card} />
        <CardNeutral500 className={styles.card} />
        <CardNeutral400 className={styles.card} />
      </div>
      <span className={styles.label}>Shuffling...</span>
    </div>
  );
};
