import { ReactNode, RefObject, useEffect, useState } from "react";
import { Stage as KonvaStage, Layer } from "react-konva";
import classNames from "classnames/bind";
import Konva from "konva";
import useResizeObserver from "use-resize-observer";

import { useKonvaStageScale } from "../../../hooks/useKonvaStageScale";

import { WORDOPOLIS_CELL_STROKE, WORDOPOLIS_STAGE_SIZE } from "../../../../../apps/responder/src/constants";

import styles from "./Stage.module.scss";

const cx = classNames.bind(styles);

export interface StageProps {
  parentRef: RefObject<HTMLDivElement>;
  children: ReactNode;
  className?: string;
  size: {
    width: number;
    height: number;
  };
}

export const Stage = ({ parentRef, size, className, children }: StageProps) => {
  const { width = 0 } = useResizeObserver({ ref: parentRef });
  const [stage, setStage] = useState<Konva.Stage | null>(null);

  const touchZoomEvents = useKonvaStageScale(stage, size.width);

  useEffect(() => {
    const container = parentRef.current;
    if (!container || !stage) {
      return;
    }

    let scale = width / (WORDOPOLIS_STAGE_SIZE + 2);

    if (scale > 1) {
      scale = 1;
    }

    stage.width(size.width * scale);
    stage.height(size.height * scale);
    stage.scale({ x: scale, y: scale });
  }, [width, stage, parentRef.current]);

  return (
    <>
      <KonvaStage
        ref={(ref) => setStage(ref)}
        width={size.width}
        height={size.height}
        borderWidth={WORDOPOLIS_CELL_STROKE}
        className={cx("stage", className)}
        {...touchZoomEvents}
      >
        <Layer>{children}</Layer>
      </KonvaStage>
    </>
  );
};
