import { Button } from "../Button";
import { Header } from "../Header";
import { Paragraph } from "../Paragraph";
import QuickrankLogo from "../../assets/images/quickrank.png";
import QuickPickLogo from "../../assets/images/quickpick.png";
import RankifierLogo from "../../assets/images/rankifier.png";
import SwiperLogo from "../../assets/images/swiper.png";
import WordopolisLogo from "../../assets/images/wordopolis.png";
import BrainBlitzLogo from "../../assets/images/brainblitz.png";
import WordUpLogo from "../../assets/images/wordup.png";
import classNames from "classnames/bind";

import styles from "./WelcomePageForm.module.scss";
const cx = classNames.bind(styles);

export type LibraryImage = {
  url: string;
  name?: string;
};

export enum GameKind {
  QuickRank = "QUICK_RANK",
  Rankifier = "RANKIFIER",
  Swiper = "SWIPER",
  Wordopolis = "WORDOPOLIS",
  Trivia = "TRIVIA",
  Wordle = "WORDLE",
}

export interface WelcomePageFormProps {
  config: {
    button: string;
    buttonColor: string;
    logo?: LibraryImage | null;
    subheading: string;
    subheadingColor: string;
    welcomeLine: string;
    welcomeLineColor: string;
    tagLine: string;
    tagLineColor: string;
  };
  gameKind: GameKind;
  onClick?: () => void;
  isQuickPickGame?: boolean;
  theme: string;
  hideDatagameBranding?: boolean;
}

export const WelcomePageForm = (props: WelcomePageFormProps) => {
  const { config, gameKind, onClick, theme, hideDatagameBranding, isQuickPickGame = false } = props;
  const gameLogos = {
    [GameKind.Swiper]: SwiperLogo,
    [GameKind.QuickRank]: isQuickPickGame ? QuickPickLogo : QuickrankLogo,
    [GameKind.Rankifier]: RankifierLogo,
    [GameKind.Wordopolis]: WordopolisLogo,
    [GameKind.Trivia]: BrainBlitzLogo,
    [GameKind.Wordle]: WordUpLogo,
  };

  const renderGameLogo = () => {
    if (hideDatagameBranding) return null;

    const src = gameLogos[gameKind];

    return <img src={src} alt="Game Logo" className={cx("gameLogo", { wordle: gameKind === GameKind.Wordle })} />;
  };

  const gameName = {
    [GameKind.Swiper]: "Swiper",
    [GameKind.QuickRank]: isQuickPickGame ? "QuickPick" : "QuickRank",
    [GameKind.Rankifier]: "Rankifier",
    [GameKind.Wordopolis]: "Wordopolis",
    [GameKind.Trivia]: "Brain Blitz",
    [GameKind.Wordle]: "WordUp",
  }[gameKind];

  return (
    <>
      {config.logo && <img src={config.logo.url} alt="client-logo" className={styles.clientLogo} />}

      {renderGameLogo()}
      <div className={styles.contentText}>
        {config.subheading && (
          <Header variant="h5" style={{ color: config.subheadingColor }}>
            {config.subheading}
          </Header>
        )}
        <div>
          {config.welcomeLine && (
            <Header variant="h1" style={{ color: config.welcomeLineColor }}>
              {config.welcomeLine}
            </Header>
          )}
          {!hideDatagameBranding && (
            <Header style={{ color: theme }} className={styles.orange} variant="h1">
              {gameName}
            </Header>
          )}
        </div>
        {config.tagLine && <Paragraph style={{ color: config.tagLineColor }}>{config.tagLine}</Paragraph>}
      </div>

      <Button
        size="large"
        className={styles.button}
        key={config.buttonColor}
        style={{ color: config.buttonColor, background: theme }}
        onClick={onClick}
      >
        {config.button || "Start"}
      </Button>
    </>
  );
};
